import { get, put } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'

export default {
  async createJobPosting ({ positionId, active, description }) {
    const response = await put(`/positions/${positionId}/job_postings`, { active, description })

    return JSONAPIClient.parse(response.data)
  },
  async getJobPosting ({ positionId }) {
    const response = await get(`/positions/${positionId}/job_postings`)

    return JSONAPIClient.parse(response.data)
  },
  async getJobPostingsSummary () {
    const response = await get('/companies/job_postings/summary')

    return JSONAPIClient.parse(response.data)
  }
}
