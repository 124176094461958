import inviteTemplates from '@/repository/companies-app/invite-templates'
import ats from '@/repository/companies-app/ats'

const getLastTemplateFromList = (inviteTemplatesList) => {
  if (!inviteTemplatesList.meta?.lastUsedTemplate) return null

  return inviteTemplatesList.find((template) => (
    template.id === inviteTemplatesList.meta.lastUsedTemplate
  ))
}

const getLastTemplateFromAts = (atsList) => {
  if (!atsList.meta?.lastUsedAts) return null

  return atsList
    .map((ats) => (ats.atsOpenings))
    .reduce((arrOpenings, ats) => arrOpenings.concat(ats), [])
    .find((template) => (
      template.id === atsList.meta.lastUsedAts
    ))
}

export default {
  async getInviteTemplates ({ commit }) {
    commit('SET_IS_FETCHING_INVITE_TEMPLATES', true)
    const inviteTemplatesList = await inviteTemplates.getInviteTemplates()
    commit('SET_INVITE_TEMPLATES', inviteTemplatesList)
    commit('SET_IS_FETCHING_INVITE_TEMPLATES', false)

    const lastTemplate = getLastTemplateFromList(inviteTemplatesList)

    if (lastTemplate) {
      commit('SET_LAST_TEMPLATE', lastTemplate)
    }
  },

  async getAts ({ commit }) {
    commit('SET_IS_FETCHING_ATS', true)
    const atsList = await ats.getAts()
    commit('SET_ATS', atsList)
    commit('SET_IS_FETCHING_ATS', false)

    const lastTemplate = getLastTemplateFromAts(atsList)

    if (lastTemplate) {
      commit('SET_LAST_TEMPLATE', lastTemplate)
    }
  },

  async updateAts ({ commit, state }, id) {
    commit('SET_IS_FETCHING_ATS', true)
    const updatedAts = await ats.updateAts(id)
    const atsList = JSON.parse(JSON.stringify(state.ats))

    atsList.find((ats) => ats.id === id).atsOpenings = updatedAts.atsOpenings
    commit('SET_ATS', atsList)
    commit('SET_IS_FETCHING_ATS', false)
  },

  async updateAtsOpening ({ commit, state }, { atsId, openingId }) {
    commit('SET_IS_FETCHING_ATS_OPENING', true)
    const updatedOpening = await ats.updateAtsOpening(atsId, openingId)
    const atsList = JSON.parse(JSON.stringify(state.ats))

    Object.assign(
      atsList.find((ats) => ats.id === atsId)
        .atsOpenings
        .find((opening) => opening.id === openingId),
      updatedOpening
    )

    commit('SET_ATS', atsList)
    commit('SET_IS_FETCHING_ATS_OPENING', false)
  }
}
