import { get, post } from '@/helpers/request'
import jsonApiClient from '@/helpers/jsonapi-client'
import { companiesUrl } from '@/helpers/constants/external-urls'
import { toSnakeCase } from '@/helpers/case-style'
import deepObjectLopper from '@/helpers/deep-object-mapper'

const baseUrl = `${companiesUrl}/api/v2/companies`

export default {
  async createCompany (args) {
    const payload = deepObjectLopper({
      data: {
        attributes: {
          employerName: args.employerName,
          name: args.name,
          companyPhone: args.companyPhone,
          signupSource: args.signupSource,
          planChoice: args.planChoice,
          hasCnpj: args.hasCnpj
        }
      }
    }, toSnakeCase)

    const response = await post(`${baseUrl}/signup/create_company`, payload)

    return jsonApiClient.parse(response.data)
  },

  async getTrial () {
    const response = await get(`${baseUrl}/trial/stats`)

    return jsonApiClient.parse(response.data)
  }
}
