export const isUnauthorizedError = (error) => {
  return error.request instanceof XMLHttpRequest &&
    error.response &&
    error.response.status === 401
}

export const isForbiddenError = (error) => {
  return error.request instanceof XMLHttpRequest &&
    error.response &&
    error.response.status === 403
}

export const isTimeoutError = (error) => {
  return error.request instanceof XMLHttpRequest &&
    error.message &&
    error.message.indexOf('timeout of') === 0
}

export const isNetworkError = (error) => {
  return error.request instanceof XMLHttpRequest &&
    error.message &&
    error.message === 'Network Error'
}
