export default [
  {
    path: 'positions/create/',
    name: 'PositionsCreate',
    component: () => import('@/pages/PositionsCreate/PositionsCreate'),
  },
  {
    path: 'positions/edit/:positionId/',
    name: 'PositionsEdit',
    component: () => import('@/pages/PositionsCreate/PositionsCreate')
  },
]
