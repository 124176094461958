import { get, post } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import { companiesUrl } from '@/helpers/constants/external-urls'
import deepObjectLopper from '@/helpers/deep-object-mapper'
import { toSnakeCase } from '@/helpers/case-style'

const baseUrl = `${companiesUrl}/api/v2/companies/shared_candidates`

export default {
  async createSharedCandidate ({ candidateId, message, emails }) {
    await post(baseUrl, {
      data: {
        attributes: {
          candidate_id: candidateId,
          message,
          emails
        }
      }
    })
  },

  async getSharedCandidate (token) {
    const response = await get(`${baseUrl}/${token}`)

    return JSONAPIClient.parse(response.data)
  },

  async getSharedCandidateSubjects (token) {
    const endpointUrl = `${baseUrl}/${token}/subjects`

    const response = await get(endpointUrl)
    return JSONAPIClient.parse(response.data)
  },

  async createExternalFeedback (feedback) {
    const token = feedback.token
    const url = `${baseUrl}/${token}/external_feedbacks`

    const parsedFeedback = deepObjectLopper(feedback, toSnakeCase)

    await post(url, { data: { attributes: parsedFeedback } })
  },

  async getEvaluationStatus (token) {
    const url = `${baseUrl}/${token}/evaluation_status`

    const response = await get(url)
    return response.data.data !== null
  }
}
