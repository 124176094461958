const experienceTimeOpts = {
  zero_years: '0',
  between_zero_and_three_years: '0 - 3',
  between_three_and_six_years: '3 - 6',
  more_than_six_years: '6+'
}

export default {
  brMoneyMask:
    (value) => `R$ ${parseFloat(value).toFixed(2).replace('.', ',')}`,

  experienceTime:
    (value) => experienceTimeOpts[value],

  currency (number) {
    const val = (number / 1).toFixed(2).replace('.', ',')

    return `R$ ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
  },

  // Used by everestV1/AppAutocomplete
  initials: (name) => {
    const array = name.trim().split(' ')
    const first = array[0]
    const last = array[array.length - 1]

    return (first[0] + last[0]).toUpperCase()
  }
}
