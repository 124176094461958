export default {
  ADD_COMMENT (state, comment) {
    state.comments.unshift(comment)
  },
  SET_COMMENTS (state, comments) {
    state.comments = comments
  },
  REMOVE_COMMENT (state, commentId) {
    state.comments = state.comments.filter(item => item.id !== commentId)
  }
}
