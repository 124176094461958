export default {
  candidates: [],
  evaluations: [],
  favoriteModal: false,
  isFetchingUnavailabilities: false,
  questions: [],
  isFetchingQuestions: false,
  recommendedCandidates: [],
  unavailabilities: {
    unavailabilitiesSchedule: []
  }
}
