<template>
  <section
    class="messages-container"
    :class="showInIframe"
    data-test="messages-container"
  >
    <header class="messages-container__header">
      {{ $t(`components.Chat.messageBox.title`) }}
    </header>
    <div
      v-if="!hasMessages"
      class="messages-container__list messages-container__list--empty"
    >
      <ev-icon
        color="blue"
        name="comment-dots"
        size="medium"
        data-test="empty-state-icon"
      />
      <h4 class="messages-container__list--empty-title">
        {{ $t(`components.Chat.messageBox.emptyState.title`) }}
      </h4>
      <p
        class="messages-container__list--empty-text"
        data-test="empty-state-text"
      >
        {{ $t(`components.Chat.messageBox.emptyState.text`) }}
      </p>
    </div>
    <section
      v-else
      class="messages-container__body"
      :class="showInIframe"
    >
      <ul
        class="messages-container__list"
        data-test="messages-list"
      >
        <li
          v-for="message in messages"
          :key="message.id"
        >
          <header
            v-if="!isFromToday(message.date) && isDifferentThanPreviousDate(message)"
            class="messages-container__list-date"
            data-test="message-date"
          >
            {{ formatDate(message) }}
          </header>
          <article
            class="message-item"
            :class="readByEmployer(message)"
            data-test="message-item"
            @click="seeInvite(message.inviteUrl)"
          >
            <img
              class="message-item__avatar"
              :src="message.candidateAvatarUrl"
            >
            <div class="message-item__body">
              <span class="message-item__name">
                {{ message.candidateName }}
              </span>
              <span class="message-item__position">
                {{ message.positionTitle }}
              </span>
              <span class="message-item__text" data-test="message-text">
                {{ formatMessage(message) }}
              </span>
            </div>
            <span class="message-item__timestamp">
              {{ formatTime(message.date) }}
            </span>
          </article>
        </li>
      </ul>
      <div
        v-if="!isLastPage"
        class="messages-container__actions"
      >
        <a
          class="messages-container__actions-link"
          data-test="view-more-button"
          @click="loadNextPage"
        >
          {{ $t(`components.Chat.messageBox.loadMoreMessages`) }}
        </a>
      </div>
    </section>
  </section>
</template>

<script>
import EvIcon from '@revelotech/everestV2/EvIcon'
import { getMessages } from '@/repository/messages'

export default {
  name: 'MessagesContainer',
  components: { EvIcon },
  data () {
    return {
      messages: [],
      page: 1,
      totalPages: 1
    }
  },
  computed: {
    showInIframe () {
      return this.$route.name === 'ListMessages' ? 'messages-container--iframe' : ''
    },
    hasMessages () {
      return this.messages.length > 0
    },
    isLastPage () {
      return this.totalPages === this.page
    },
    today () {
      return new Date()
    },
    yesterday () {
      const yesterday = new Date()
      yesterday.setDate(this.today.getDate() - 1)

      return yesterday
    }
  },
  async created () {
    const messages = await getMessages(this.page)

    this.messages = messages
    this.totalPages = messages.meta.totalPages
    this.page = messages.meta.page
  },
  methods: {
    readByEmployer (message) {
      return message.readByEmployer ? '' : 'message-item--unread'
    },
    isFromToday (messageDate) {
      return messageDate.toDateString() === this.today.toDateString()
    },
    isFromYesterday (messageDate) {
      return messageDate.toDateString() === this.yesterday.toDateString()
    },
    isDifferentThanPreviousDate (message) {
      const messageIndex = this.messages.indexOf(message)
      if (messageIndex === 0) { return true }
      const previousMessage = this.messages[messageIndex - 1]

      return message.date.toDateString() !== previousMessage.date.toDateString()
    },
    formatDate (message) {
      if (this.isFromYesterday(message.date)) {
        return this.$t('components.Chat.messageBox.yesterday')
      }

      return this.$d(message.date, 'dayMonthYear')
    },
    formatMessage (message) {
      if (message.sentByCurrentUser) {
        return `${this.$t('components.Chat.messageBox.sentByCurrentUser')} ${message.body}`
      } else if (message.senderName !== message.candidateName) {
        return `${message.senderName}: ${message.body}`
      }

      return message.body
    },
    formatTime (messageDate) {
      const timeStamp = this.$d(messageDate, 'hoursMinutes')

      return timeStamp.replace(':', 'H')
    },
    seeInvite (inviteUrl) {
      window.open(inviteUrl)
    },
    async loadNextPage () {
      const messages = await getMessages(this.page + 1)

      this.messages.push(...messages)
      this.page = messages.meta.page
    }
  }
}
</script>

<style lang="scss">
.messages-container {
  background: var(--bg-white);
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;

  @include breakpoint(md) {
    border-radius: var(--b-radius);
    height: auto;
    overflow-y: unset;
    width: 360px;
  }

  &--iframe {
    overflow-y: unset;
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 0 6*$base;

    &-date {
      color: var(--tx-gray80);
      font: var(--tx-overline);
      margin-bottom: 4*$base;
      text-transform: uppercase;
    }

    &--empty {
      align-items: center;
      height: 100vh;
      justify-content: center;
      padding: 0 16*$base 10*$base 16*$base;
      text-align: center;

      @include breakpoint(md) {
        height: 528px;
      }

      &-title {
        font: var(--tx-title-5);
        margin: 6*$base 0 2*$base 0;
      }

      &-text {
        font: var(--tx-body-small);
      }
    }
  }

  &__header {
    align-items: center;
    font: var(--tx-title-4);
    padding: 6*$base;
  }

  &__body {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 8*$base;

    @include breakpoint(md) {
      max-height: 528px;
    }
  }

  &--iframe &__body {
    height: 528px;
  }

  &__actions {
    margin-top: 6*$base;
    padding: 0 10*$base;

    &-link,
    &-link:hover {
      color: var(--tx-blue);
      cursor: pointer;
      font: var(--tx-button-small);
    }
  }
}

.message-item {
  align-items: center;
  border-radius: var(--b-radius);
  color: var(--tx-gray60);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4*$base;
  padding: 2*$base;
  width: 100%;

  &:hover {
    background-color: var(--b-gray5);
  }

  &--unread::after {
    background-color: var(--bg-red);
    border-radius: 50%;
    content: '';
    flex-shrink: 0;
    height: 2*$base;
    position: relative;
    right: 2*$base;
    top: 7*$base;
    width: 2*$base;
  }

  &__avatar {
    background: var(--bg-gray40);
    border-radius: 50%;
    flex-shrink: 0;
    height: 40px;
    object-fit: cover;
    width: 40px;
  }

  &__name {
    color: var(--tx-gray100);
    font: var(--tx-body);
  }

  &__position {
    font: var(--tx-caption);
    margin: $base 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    margin: 0 2*$base 0 4*$base;
    width: 100%;
  }

  &__text {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    height: 20px;
    line-height: 20px;
    max-width: 188px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__timestamp {
    color: var(--tx-gray80);
    font: var(--tx-overline);
    text-align: right;
  }
}
</style>
