import applicants from '@/repository/applicants'
import positions from '@/repository/positions'

export default [
  {
    path: '/external/position/:externalId',
    beforeEnter: (to, _from, next) => {
      const externalId = to.params.externalId

      positions.getPositionIdFromExternalId(externalId)
        .then((response) => { next({name: 'WorkflowBoard', params: {positionId: response.id}}) })
        .catch(() => next({name: 'Positions'}))
    }
  },
  {
    path: '/external/publish/position/:externalId',
    beforeEnter: (to, _from, next) => {
      const externalId = to.params.externalId

      positions.getPositionIdFromExternalId(externalId)
        .then((response) => {
          next({
            name: 'JobPosting',
            params: {
              positionId: response.id,
            },
            query: { ...to.query }
          })
        })
        .catch(() => next({name: 'Positions'}))
    }
  },
  {
    path: '/invites/:inviteId/proposal',
    beforeEnter: async (to, _from, next) => {
      const inviteId = to.params.inviteId

      try {
        const applicant = await applicants.getApplicantIdFromInviteId(inviteId)
        const positionId = applicant.position_id

        next({
          name: 'ApplicantProposal',
          params: {
            positionId,
            applicantId: applicant.id
          },
          query: { ...to.query, referral: 'external' }
        })
      } catch {
        next({name: 'Positions'})
      }
    }
  },
  {
    path: '/invites/:inviteId/refused',
    beforeEnter: async (to, _from, next) => {
      const inviteId = to.params.inviteId

      try {
        const applicant = await applicants.getApplicantIdFromInviteId(inviteId)
        const positionId = applicant.position_id

        next({
          name: 'ApplicantRejected',
          params: {
            positionId,
            applicantId: applicant.id
          },
          query: { ...to.query, referral: 'external' }
        })
      } catch {
        next({name: 'Positions'})
      }
    }
  },
  {
    path: '/workflow',
    name: 'Workflow',
    component: () => import('@/pages/Workflow/Workflow'),
    children: [
      {
        path: ':positionId',
        name: 'WorkflowBoard',
        component: () => import('@/pages/WorkflowBoard/WorkflowBoard'),
        children: [
          {
            path: 'candidate/add',
            name: 'CandidateAdd',
            component: () => import('@/pages/CandidateAdd/CandidateAdd')
          },
          {
            path: 'applicant/:applicantId',
            component: () => import('@/pages/Applicant/Applicant'),
            children: [
              {
                path: '',
                name: 'Applicant',
                redirect: { name: 'ApplicantInterview' },
              },
              {
                path: 'edit',
                name: 'ApplicantEdit',
                component:
                  () => import('@/pages/ApplicantEdit/ApplicantEdit')
              },
              {
                path: 'comments',
                name: 'ApplicantComments',
                component:
                  () => import('@/pages/ApplicantComments/ApplicantComments')
              },
              {
                path: 'interview',
                name: 'ApplicantInterview',
                component:
                  () => import('@/pages/ApplicantInterview/ApplicantInterview')
              },
              {
                path: 'proposal',
                name: 'ApplicantProposal',
                component:
                  () => import('@/pages/ApplicantProposal/ApplicantProposal')
              },
              {
                path: 'refused',
                name: 'ApplicantRejected',
                component:
                  () => import('@/pages/ApplicantRejected/ApplicantRejected')
              }
            ]
          }
        ]
      }
    ]
  }
]
