import Vue from 'vue'
import Offscreen from '@/components/Offscreen'
import router from './vue-router'

Vue.component('Offscreen', Offscreen)

Vue.prototype.$a11y = {
  speak (message) {
    if (this.data) {
      this.data.text = ''
    }
  },

  data: null
}

if (router) {
  router.afterEach(to => {
    Vue.prototype.$a11y.speak(`${to.meta.title || document.title.trim()}`)
  })
}
