import { styleTokens } from '@revelotech/everest'

const TIME_GRID_LEFT_WIDTH = '48px'

export default {
  // Common
  'common.dayname.color': styleTokens.gray60,

  // Week
  'week.currentTime.color': styleTokens.gray100,
  'week.currentTime.fontWeight': 'bold',

  'week.currentTimeLineBullet.backgroundColor': `${styleTokens.blue}`,
  'week.currentTimeLineFuture.border': `1px solid ${styleTokens.blue}`,
  'week.currentTimeLinePast.border': `1px dashed ${styleTokens.blue}`,
  'week.currentTimeLineToday.border': `1px solid ${styleTokens.blue}`,

  'week.dayname.borderBottom': 'none',
  'week.dayname.borderLeft': 'none',
  'week.dayname.borderTop': 'none',
  'week.dayname.fontSize': '16px',
  'week.dayname.height': '67px',
  'week.dayname.textAlign': 'center',

  'week.daygridLeft.width': TIME_GRID_LEFT_WIDTH,

  'week.futureTime.color': styleTokens.gray60,
  'week.futureTime.fontWeight': 'normal',

  'week.pastDay.color': styleTokens.gray40,

  'week.pastTime.color': styleTokens.gray60,
  'week.pastTime.fontWeight': 'normal',

  'week.timegrid.borderRight': `1px solid ${styleTokens.borderColor}`,

  'week.timegridSchedule.borderRadius': '4px',
  'week.timegridSchedule.paddingLeft': '4px',

  'week.timegridLeft.width': TIME_GRID_LEFT_WIDTH,

  'week.timegridHorizontalLine.borderBottom': `1px solid ${styleTokens.borderColor}`,

  'week.timegridLeft.borderRight': `1px solid ${styleTokens.borderColor}`,

  'week.timegridOneHour.height': '48px',

  // Today
  'week.today.backgroundColor': 'transparent',
  'week.today.color': styleTokens.gray80
}
