import Rollbar from 'rollbar'
import eventBus from '@/support/event-bus'

const mockedDevelopmentRollbar = {
  error: console.error,
  info: console.info,
  warn: console.warn,
  debug: console.debug
}

const isDevelopment = process.env.NODE_ENV === 'development'

export default {
  install (Vue, options) {
    Vue.prototype.$rollbar = isDevelopment
      ? mockedDevelopmentRollbar
      : new Rollbar({
        accessToken: process.env.VUE_APP_ROLLBAR_CLIENT_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        ignoredMessages: [
          'Request failed with status code 404'
        ],
        source_map_enabled: true,
        payload: {
          environment: process.env.VUE_APP_ROLLBAR_ENV
        }
      })

    function handler (err, vm) {
      eventBus.emit('error', err)
      vm.$rollbar.error(err)

      if (options.onUnhandledError) {
        options.onUnhandledError(err)
      }
    }

    Vue.config.errorHandler = handler

    if (options.router) {
      options.router.onError(handler)
    }
  }
}
