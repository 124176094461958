import { get, put } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import { toSnakeCase } from '@/helpers/case-style'
import deepObjectLopper from '@/helpers/deep-object-mapper'
import mountFormData from '@/helpers/mount-form-data'

export default {
  async getApplicant (applicantId) {
    const response = await get(`/applicants/${applicantId}`)

    return JSONAPIClient.parse(response.data)
  },
  async getApplicantsWithUnscheduledInterviews (page = 1) {
    const { data } = await get(`/employers/applicants/unscheduled_interview?page=${page}`)
    const meta = data.meta

    return {
      data: await JSONAPIClient.parse(data),
      meta: { totalPages: meta.total_pages, page: meta.page, totalEntries: meta.total_entries }
    }
  },
  async getApplicantsWithConductedInterviews (page = 1) {
    const { data } = await get(`/employers/applicants/interview_conducted?page=${page}`)
    const meta = data.meta

    return {
      data: await JSONAPIClient.parse(data),
      meta: { totalPages: meta.total_pages, page: meta.page, totalEntries: meta.total_entries }
    }
  },
  async getApplicantsWithSentProposals (page = 1) {
    const { data } = await get(`/employers/applicants/proposal_sent?page=${page}`)
    const meta = data.meta

    return {
      data: await JSONAPIClient.parse(data),
      meta: { totalPages: meta.total_pages, page: meta.page, totalEntries: meta.total_entries }
    }
  },
  moveApplicant ({ applicantId, stageId }) {
    return put(`/applicants/${applicantId}/move_candidate?stage_id=${stageId}`)
  },
  moveApplicantKind ({ applicantId, stageKind }) {
    return put(`/applicants/${applicantId}/move_candidate_kind?kind=${stageKind}`)
  },
  async rejectCandidate ({ applicantId, rejectionReason, rejectionMessage }) {
    const response = await put(
      `/applicants/${applicantId}/reject`,
      {
        rejection_message: rejectionMessage,
        rejection_reason: rejectionReason
      }
    )

    return JSONAPIClient.parse(response.data)
  },
  updateOrder ({ stageId, applicantsOrder }) {
    put(`/stages/${stageId}/applicants/update_order`, {
      update_applicants: deepObjectLopper(applicantsOrder, toSnakeCase)
    })
  },
  async getApplicantIdFromInviteId (inviteId) {
    const response = await get(`/applicants/id/${inviteId}/`)

    return response.data
  },
  async updateCandidate ({ applicantId, candidateId, ...candidateParams }) {
    const params = mountFormData(candidateParams)

    const response = await put(
      `applicants/${applicantId}/candidates/${candidateId}`, params
    )

    return JSONAPIClient.parse(response.data)
  },
  clearNotifications (applicantId) {
    return put(`/applicants/${applicantId}/clear_notifications`)
  }
}
