export const userGtmEvents = ({ gtmEvents, user }) => {
  if (!gtmEvents || !user?.id || !window.dataLayer) return

  gtmEvents.forEach((event) =>
    window.dataLayer.push({
      event,
      emailValue: user.email,
      employerValue: true,
      uidValue: user.id
    })
  )
}

export const signInGtmEvent = (user) => {
  if (!user?.id || !window.dataLayer) return

  // Default GTM event after Sign In/Up
  window.dataLayer.push({
    employerValue: true,
    uidValue: user.id,
    emailValue: user.email,
    planChoice: user.company?.planChoice
  })
}
