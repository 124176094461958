<template>
  <ev-modal
    size="md"
    @close="$emit('close')"
    data-test="shortlist-card-resume-modal"
  >
    <div class="shortlist-card-resume-modal__heading">
      <figure v-if="candidate.photoUrl" class="shortlist-card-resume-modal__avatar">
        <img
          class="shortlist-card-resume-modal__avatar-image"
          :src="candidate.photoUrl"
          :alt="$t('pages.ShortlistCardResumeModal.avatarUrlAlt')"
          data-test="avatar-image"
        >
      </figure>

      <div>
        <h3
          class="shortlist-card-resume-modal__name"
          data-test="candidate-name"
        >
          {{ candidate.name }}
        </h3>

        <h4
          class="shortlist-card-resume-modal__career"
          data-test="career-name"
        >
          {{ candidate.careerName }}
        </h4>
      </div>
    </div>

    <div class="shortlist-card-resume-modal__description">
      <h4
        class="shortlist-card-resume-modal__section-title
        shortlist-card-resume-modal__description-title"
      >
        {{ $t('pages.ShortlistCardResumeModal.descriptionTitle') }}
      </h4>

      <p
        class="shortlist-card-resume-modal__description-text"
        data-test="description"
      >
        {{ candidate.applications &&  candidate.applications[0].comment }}
      </p>
    </div>
  </ev-modal>
</template>

<script>
import EvModal from '@revelotech/everestV2/EvModal'

export default {
  name: 'ShortlistCardResumeModal',

  components: {
    EvModal
  },

  props: {
    candidate: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.shortlist-card-resume-modal {
  &__heading {
    align-items: center;
    display: flex;
    flex-flow: wrap;
    margin-bottom: $base * 8;
  }

  &__avatar {
    margin-right: $base * 6;

    &-image {
      border-radius: 50%;
      height: 48px;
      width: 48px;
    }
  }

  &__name {
    font: $tx-title-3;
  }

  &__career {
    @extend %tx-overline;

    margin-top: $base;
  }

  &__description {
    margin-top: $base * 8;

    &-title {
      @extend %tx-overline;

      margin-bottom: $base * 2;
    }

    &-text {
      font: $tx-body-small;
    }
  }
}
</style>
