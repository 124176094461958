import { get, post, del } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'

export default {
  async createComment (applicantId, body) {
    const response = await post(
      `/applicants/${applicantId}/comments`,
      { body }
    )
    return JSONAPIClient.parse(response.data)
  },
  async getComments (applicantId) {
    const response = await get(`/applicants/${applicantId}/comments`)
    return JSONAPIClient.parse(response.data)
  },
  async deleteComment (applicantId, commentId) {
    const response =
      await del(`/applicants/${applicantId}/comments/${commentId}`)
    return response
  }
}
