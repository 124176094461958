export default {
  invite: {},
  isFetchingInvite: false,
  isCreatingInvite: false,
  isUpdatingInvite: false,
  source: false,
  inviteSummary: {},
  similarCandidates: [],
  isFetchingSimilarCandidates: false,
  isFetchingInviteSummary: false,
  errorFetchingSimilarCandidates: false,
  inviteCreated: false
}
