import jobPostings from '@/repository/job-postings'
import positionsRepo from '@/repository/positions'
import companiesPositionsRepo from '@/repository/companies-app/positions'
import uniq from 'lodash/uniq'

const reduceArrayinObject = function (position, key) {
  return position.reduce((object, item) => {
    object[item[key]] = { ...item }
    return object
  }, {})
}

export default {
  async getPosition ({ commit }, { positionId }) {
    const response = await positionsRepo.getPosition({ positionId })
    commit('SET_POSITION', response)
  },
  async getShortPositions ({ commit }, params = {}) {
    const response = await positionsRepo.getShortPositions(params)
    commit('SET_POSITIONS', response)
  },
  async getPositionsSummary ({ commit }) {
    commit('SET_IS_FETCHING_POSITIONS_SUMMARY', true)

    const positions = await positionsRepo.getPositionsSummary()

    commit('SET_POSITIONS_SUMMARY', positions)
    commit('SET_IS_FETCHING_POSITIONS_SUMMARY', false)
  },
  async getArchivedPositions ({ state, commit }, { employerId, currentPage } = {}) {
    const nextPage = (currentPage || 0) + 1
    const employerIdArchivedFilter = employerId || null

    if (nextPage === 1) {
      commit('SET_IS_FETCHING_ARCHIVED_POSITIONS', true)
      commit('SET_ARCHIVED_POSITIONS', [])
      commit('SET_EMPLOYER_ID_ARCHIVED_FILTER', employerIdArchivedFilter)
    }

    const archivedPositionsResponse = await positionsRepo.getEmployersPositions(
      nextPage, 6, employerIdArchivedFilter, false
    )
    commit('SET_ARCHIVED_POSITIONS',
      [...state.archivedPositions, ...archivedPositionsResponse.positions]
    )
    commit('SET_ARCHIVED_POSITIONS_PAGE', nextPage)
    commit('SET_ARCHIVED_POSITIONS_TOTAL_PAGES', archivedPositionsResponse.totalPages)
    commit('SET_IS_FETCHING_ARCHIVED_POSITIONS', false)
  },
  createJobPosting ({ commit }, { positionId, active, description }) {
    return jobPostings.createJobPosting({ positionId, active, description }).then(response => {
      commit('SET_POSITION', response)
    })
  },
  async getPositionsTotals ({ commit }, employerId) {
    commit('SET_IS_FETCHING_POSITIONS', true)
    const positionsResponse = await positionsRepo.getEmployersPositions(1, 6)
    const employerPositionsResponse = await positionsRepo.getEmployersPositions(
      1, 6, employerId
    )

    const companiesPositions = await companiesPositionsRepo.getPositions(
      uniq([
        ...positionsResponse.positions.map((position) => position.externalId),
        ...employerPositionsResponse.positions.map((position) => position.externalId)
      ])
    ).then((positions) => {
      return reduceArrayinObject(positions, 'id')
    })

    const positionsWithSearchInfo = positionsResponse.positions.map((position) => ({
      ...position,
      candidatesCount: companiesPositions[position.externalId]?.candidatesCount,
      searchPageHref: companiesPositions[position.externalId]?.searchPageHref
    }))

    const employerPositionsWithSearchInfo = employerPositionsResponse.positions
      .map((position) => ({
        ...position,
        candidatesCount: companiesPositions[position.externalId]?.candidatesCount,
        searchPageHref: companiesPositions[position.externalId]?.searchPageHref
      }))

    commit('SET_POSITIONS_TOTAL', positionsResponse.count)
    commit('SET_PUBLISHED_POSITIONS_TOTAL', positionsResponse.publishedCount)
    commit('SET_EMPLOYER_POSITIONS_TOTAL', employerPositionsResponse.count)
    commit('SET_ACTIVE_POSITIONS', positionsWithSearchInfo)
    commit('SET_ACTIVE_POSITIONS_PAGE', 1)
    commit('SET_ACTIVE_POSITIONS_TOTAL_PAGES', positionsResponse.totalPages)
    commit('SET_EMPLOYER_POSITIONS', employerPositionsWithSearchInfo)
    commit('SET_EMPLOYER_POSITIONS_PAGE', 1)
    commit('SET_EMPLOYER_POSITIONS_TOTAL_PAGES', employerPositionsResponse.totalPages)
    commit('SET_IS_FETCHING_POSITIONS', false)
  },
  async getEmployerPositionsNextPage ({ state, commit }, employerId) {
    const nextPage = state.employerPositionsPage + 1
    const sortByCriteria = state.sortBy
    const employerPositionsResponse = await positionsRepo.getEmployersPositions(
      nextPage, 6, employerId, true, sortByCriteria
    )
    const companiesPositions = await companiesPositionsRepo.getPositions(
      uniq([
        ...employerPositionsResponse.positions.map((position) => position.externalId)
      ])
    ).then((positions) => {
      return reduceArrayinObject(positions, 'id')
    })

    const employerPositionsWithSearchInfo = employerPositionsResponse.positions
      .map((position) => ({
        ...position,
        candidatesCount: companiesPositions[position.externalId]?.candidatesCount,
        searchPageHref: companiesPositions[position.externalId]?.searchPageHref
      }))

    commit(
      'SET_EMPLOYER_POSITIONS',
      [...state.employerPositions, ...employerPositionsWithSearchInfo]
    )
    commit('SET_EMPLOYER_POSITIONS_PAGE', nextPage)
  },
  async getActivePositions ({ state, commit }, { employerId, currentPage, sortBy } = {}) {
    const nextPage = (currentPage || 0) + 1
    const employerIdFilter = employerId || null
    const sortByCriteria = sortBy
    const activePositionsResponse = await positionsRepo.getEmployersPositions(
      nextPage, 6, employerIdFilter, true, sortByCriteria
    )

    const companiesPositions = await companiesPositionsRepo.getPositions(
      uniq([
        ...activePositionsResponse.positions.map((position) => position.externalId)
      ])
    ).then((positions) => {
      return reduceArrayinObject(positions, 'id')
    })

    const positionsWithSearchInfo = activePositionsResponse.positions
      .map((position) => ({
        ...position,
        candidatesCount: companiesPositions[position.externalId]?.candidatesCount,
        searchPageHref: companiesPositions[position.externalId]?.searchPageHref
      }))

    if (nextPage === 1) {
      commit('SET_ACTIVE_POSITIONS', positionsWithSearchInfo)
      commit('SET_ACTIVE_POSITIONS_TOTAL_PAGES', activePositionsResponse.totalPages)
      commit('SET_EMPLOYER_ID_FILTER', employerIdFilter)
    } else {
      commit(
        'SET_ACTIVE_POSITIONS',
        [...state.activePositions, ...positionsWithSearchInfo]
      )
    }
    commit('SET_ACTIVE_POSITIONS_PAGE', nextPage)
  },
  async getShortlistPositions ({ state, commit }, { employerId, currentPage } = {}) {
    const nextPage = (currentPage || 0) + 1
    const employerIdFilter = employerId || null

    commit('SET_IS_FETCHING_SHORTLIST_POSITIONS', true)

    const shortlistPositionsResponse = await positionsRepo.getShortlistPositions(
      { page: nextPage, perPage: 6, employerId: employerIdFilter }
    )
    const { positions, totalPages } = shortlistPositionsResponse

    if (nextPage === 1) {
      commit('SET_SHORTLIST_POSITIONS', positions)
      commit('SET_SHORTLIST_POSITIONS_TOTAL_PAGES', totalPages)
    } else {
      commit(
        'SET_SHORTLIST_POSITIONS',
        [...state.shortlistPositions, ...positions]
      )
    }
    commit('SET_SHORTLIST_POSITIONS_PAGE', nextPage)
    commit('SET_IS_FETCHING_SHORTLIST_POSITIONS', false)
  },
  async archivePosition ({ commit, state }, positionId) {
    try {
      await positionsRepo.updatePosition(positionId, { visible: false })

      commit(
        'SET_ACTIVE_POSITIONS',
        state.activePositions.filter(position => position.id !== positionId)
      )
      commit(
        'SET_EMPLOYER_POSITIONS',
        state.employerPositions.filter(position => position.id !== positionId)
      )
      commit(
        'SET_SHORTLIST_POSITIONS',
        state.shortlistPositions.filter(position => position.atsPositionId !== positionId)
      )
      return true
    } catch (e) {
      return false
    }
  },
  async unarchivePosition ({ commit, state }, positionId) {
    try {
      await positionsRepo.updatePosition(positionId, { visible: true })

      commit(
        'SET_ARCHIVED_POSITIONS',
        state.archivedPositions.filter(position => position.id !== positionId)
      )
      return true
    } catch (e) {
      return false
    }
  },
  updateSortByCriteria ({ commit }, sortBy) {
    commit('SET_SORT_BY', sortBy)
  },
  startSorting ({ commit }) {
    commit('SET_EMPLOYER_POSITIONS_PAGE', 0)
    commit('SET_ACTIVE_POSITIONS_PAGE', 0)
    commit('SET_ACTIVE_POSITIONS', [])
    commit('SET_EMPLOYER_POSITIONS', [])
    commit('SET_IS_SORTING', true)
  },
  sortingFinished ({ commit }) {
    commit('SET_IS_SORTING', false)
  }
}
