import skills from '@/repository/companies-app/skills'

export default {
  async getSkillsByIds ({ commit }, skillIds) {
    commit('SET_IS_FETCHING_SKILLS', true)
    const response = await skills.getSkillsByIds(skillIds)
    commit('SET_SKILLS', response)
    commit('SET_IS_FETCHING_SKILLS', false)
  }
}
