<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    ref="alertbell"
    class="notifications-box"
    :class="[bellOpen]"
    tabindex="-1"
    data-test="notifications-box"
    @keyup.space="handleClick"
    @click="handleClick"
    @blur="hideMenu"
    @mousedown.prevent
  >
    <span class="notifications-box__button">
      <ev-icon
        name="bell"
      />
      <span
        v-if="hasNotifications"
        class="notifications-box__badge"
        data-test="notifications-count"
      >
        {{ notificationsCounter }}
      </span>
    </span>
    <section
      v-show="!notificationsHidden"
      class="notification"
      data-test="notification-container"
      :class="notificationsHidden"
    >
      <header class="notification__header-mobile">
        <p class="notification__header-mobile--border-space">
          {{ $t('components.App.NotificationsBox.notifications') }}
          <button
            class="notification__close-button"
            data-test="close-button"
            @click="closeClick"
          >
            <ev-icon
              class="notification__icon-close"
              name="times"
            />
          </button>
        </p>
        <span
          class="notification__read-all-mobile notification__read-all-icon"
          alt="read all"
          role="button"
          @click="$emit('readAll')"
        >
          {{ $t('components.App.NotificationsBox.tooltip') }}
        </span>
      </header>
      <header class="notification__header">
        {{ $t('components.App.NotificationsBox.notifications') }}
        <p
          v-if="hasNotifications"
          class="notification__read-all-button"
          data-test="read-all"
          role="button"
          name="read-all"
          @click="$emit('readAll')"
        >
          {{ $t('components.App.NotificationsBox.callToAction') }}
        </p>
      </header>
      <ul class="notification__list">
        <li
          v-if="!hasNotifications"
          class="notification__item"
        >
          <span class="notification__link notification__link--none">
            {{ $t('components.App.NotificationsBox.emptyState') }}
          </span>
        </li>
        <li
          v-for="notification in notifications"
          v-else
          :key="notification.id"
          class="notification__item"
        >
          <a
            class="notification__link"
            :href="notification.href || 'javascript:void(0)'"
            :target="target(notification)"
            @click="$emit('read', notification)"
          >
            <span
              class="notification__title"
              v-html="notificationTitle(notification)"
            />
            <span
              class="notification__message"
              v-html="notificationBody(notification)"
            />
            <span class="notification__created">
              {{ $moment(notification.createdAt).fromNow() }}
            </span>
          </a>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import EvIcon from '@revelotech/everestV2/EvIcon'

export default {
  name: 'NotificationsBox',
  components: { EvIcon },
  props: {
    notifications: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    notificationsHidden () {
      return this.show ? '' : 'dropdown__menu--hidden'
    },
    bellOpen () {
      return this.show ? 'notifications-box--open' : ''
    },
    hasNotifications () {
      return this.notifications.length > 0
    },
    notificationsCounter () {
      if (this.notifications.length >= 100) { return '99+' }
      return this.notifications.length
    }
  },
  methods: {
    handleClick () {
      if (!this.show) {
        this.openMenu()
      } else {
        this.hideMenu()
      }
    },
    openMenu () {
      this.show = true
      this.$refs.alertbell.focus()
    },
    hideMenu () {
      this.show = false
    },
    notificationTitle (notification) {
      if (notification.type === 'new_message') {
        return this.$t('components.App.NotificationsBox.newMessage',
          { name: notification.candidateName })
      } else if (notification.type === 'waiting_to_schedule') {
        return this.$t('components.App.NotificationsBox.accepted',
          { name: notification.candidateName })
      } else if (notification.type === 'abandonment') {
        return this.$t('components.App.NotificationsBox.canceled',
          { name: notification.candidateName })
      } else {
        return notification.title
      }
    },
    notificationBody (notification) {
      if (notification.type === 'waiting_to_schedule') {
        return this.$t('components.App.NotificationsBox.waitingForSchedule')
      } else {
        return notification.message
      }
    },
    closeClick () {
      this.$emit('close')
    },
    target (notification) {
      if (notification.link &&
          notification.link.includes('https://') &&
          !notification.link.includes('.revelo.')
      ) {
        return '_blank'
      }
      return '_self'
    }
  }
}
</script>

<style lang="scss">
.notifications-box {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-width: 16*$base;
  position: relative;
  vertical-align: middle;

  &--open {
    background-color: $gray40;
  }

  &__button {
    @extend %h4;

    display: inline-flex;
    height: 6*$base;
    overflow: hidden;
    user-select: none;
  }

  &__badge {
    @extend %caption;

    background-color: $red;
    border-radius: 10px;
    color: $white;
    height: 16px;
    min-width: 20px;
    padding: 0 $base;
    text-align: center;
    vertical-align: middle;
  }
}

.notification {
  background: $white;
  border: 1px solid $gray5;
  border-bottom-left-radius: 4px;
  cursor: default;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;

  @include breakpoint(md) {
    height: auto;
    position: absolute;
    right: 0;
    top: 100%;
  }

  &__close-icon {
    height: 5*$base;
    width: 5*$base;
  }

  &__close-button {
    background-color: $white-0;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    cursor: pointer;
    display: block;
    position: absolute;
    right: 4*$base;
    top: 3*$base;

    &:active,
    &:hover {
      outline: 0;
    }

    & .fill-path {
      fill: $gray100;
      stroke: $gray100;
    }

    &--white {
      & .fill-path {
        fill: $white;
        stroke: $white;
      }
    }
  }

  &--hidden {
    display: none;
  }

  &__header {
    @extend %subtitle1;

    display: none;

    @include breakpoint(md) {
      align-items: center;
      background-color: $gray5;
      display: flex;
      height: 18*$base;
      justify-content: space-between;
      padding: 0 4*$base;
    }
  }

  &__header-mobile {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 23*$base;
    justify-content: space-between;
    padding-top: 3*$base;

    @include breakpoint(md) {
      display: none;
    }

    &--border-space {
      margin-top: $base;
      padding: 0 4*$base;
    }
  }

  &__read-all-mobile {
    @extend %body-text2;

    align-items: center;
    background-color: $gray5;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    padding: 3*$base 2*$base 3*$base 0;
    width: 100vw;
  }

  &__read-all-button {
    @extend %caption;

    cursor: pointer;
    margin-right: 2*$base;
    text-decoration: underline;

    @include breakpoint(md) {
      margin-right: 0;
    }
  }

  &__read-all {
    cursor: pointer;
  }

  &__list {
    max-height: 420px;
    max-width: 360px;
    min-width: 360px;
    overflow-y: auto;
    scrollbar-width: thin;
  }

  &__item {
    background: $white;
    padding: 4*$base 4*$base 0;
    text-align: left;

    &:hover {
      background-color: $gray5;
    }
  }

  &__link {
    @include truncate;

    cursor: pointer;
    display: block;
    padding-bottom: 4*$base;
    text-transform: none;
    white-space: nowrap;

    &:focus {
      @extend %input-focus;
    }

    &--none {
      @extend %body-text1;

      cursor: default;
      padding: 5*$base 0 9*$base;
    }
  }

  &__item:not(:last-of-type) &__link {
    border-bottom: 1px solid $gray40;
  }

  &__title {
    @extend %body-text1;
  }

  &__message {
    @extend %caption;

    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__created {
    @extend %caption;

    color: $gray100;
  }
}
</style>
