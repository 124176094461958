import i18n from '@/plugins/vue-i18n'
import Vue from 'vue'

const ValidationErrorMessages = {}

ValidationErrorMessages.install = function (Vue) {
  Vue.prototype.errorMessages = function (field) {
    if (!field.$error) return []

    const key = Object.keys(field.$params).find(key => !field[key])
    const params = field.$params[key]

    return [i18n.t(`validation.messages.${key}`, params)]
  }
}

Vue.use(ValidationErrorMessages)
