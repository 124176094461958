export default {
  stageOfApplicant (state, _getters, rootState) {
    return rootState.stages.stages.find((stage) => {
      return !!stage.applicants?.find((applicant) =>
        applicant.id === state.applicantId
      )
    })
  },

  applicant (state, getters) {
    return getters.stageOfApplicant?.applicants.find((applicant) =>
      applicant.id === state.applicantId
    )
  },

  nextApplicant (state, getters) {
    const applicantIndex = getters.stageOfApplicant?.applicants
      .indexOf(getters.applicant)
    return getters.stageOfApplicant?.applicants[applicantIndex + 1]
  },

  previousApplicant (state, getters) {
    const applicantIndex = getters.stageOfApplicant?.applicants
      .indexOf(getters.applicant)
    return getters.stageOfApplicant?.applicants[applicantIndex - 1]
  },

  closestInterview (state) {
    const interviews = state
      .interviews
      .sort((dateA, dateB) =>
        (new Date(dateA.scheduledStartAt)) - (new Date(dateB.scheduledStartAt))
      )

    return interviews
      .filter((interview) =>
        (new Date(interview.scheduledEndAt)) > (new Date())
      )[0] || interviews[interviews.length - 1]
  }
}
