<template>
  <section class="calendar">
    <header class="calendar__row-center">
      <ev-button
        class="calendar__button"
        variant="tertiary"
        color="blue"
        size="small"
        data-test="button-prev"
        @click="changeCalendarDate('prev')"
      >
        <icn-prev class="calendar__button-image" />
      </ev-button>

      <ev-button
        class="calendar__button"
        variant="tertiary"
        color="blue"
        size="small"
        data-test="button-next"
        @click="changeCalendarDate('next')"
      >
        <icn-next class="calendar__button-image" />
      </ev-button>

      <time class="calendar__current-time" :datetime="displayDatetime">
        {{ displayDate }}
      </time>
    </header>

    <calendar
      ref="tuiCalendar"
      :key="calendarId"
      class="calendar-component"
      view="week"
      timezones-collapsed
      toggle-scheduleview
      :calendars="calendars"
      :schedules="sanitizedEvents"
      :schedule-view="['time']"
      :task-view="false"
      :use-creation-popup="false"
      :use-detail-popup="false"
      :theme="theme"
      :week="week"
      :template="template"
      :timezones="timezones"
      @beforeCreateSchedule="handleCreate"
      @beforeUpdateSchedule="handleUpdate"
      @clickSchedule="handleClick"
    />

    <ul class="calendar__legend calendar__row-center">
      <li
        v-for="calendar in calendars"
        :key="calendar.id"
        class="calendar__legend__item calendar__row-center"
      >
        <div
          class="square"
          :style="`background-color: ${calendar.bgColor}`"
        />
        <span>{{ calendar.name }}</span>
      </li>
    </ul>
  </section>
</template>

<script>
import { IcnNext, IcnPrev } from '@revelotech/everest'
import { Calendar } from '@toast-ui/vue-calendar'
import dateFormatter from '@/helpers/date-formatter.js'
import toastUiCalendarTheme from '@/helpers/toast-ui-calendar-theme'
import 'tui-calendar/dist/tui-calendar.css'
import EvButton from '@revelotech/everestV2/EvButton'

const BRAZILIAN_TIME_ZONE = -180

export default {
  name: 'AppCalendar',
  components: {
    calendar: Calendar,
    EvButton,
    IcnNext,
    IcnPrev
  },
  props: {
    calendars: {
      type: Array,
      required: true
    },
    events: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      default: () => dateFormatter.todayISOStr()
    },
    theme: {
      type: Object,
      default: () => toastUiCalendarTheme
    },
    timezones: {
      type: Array,
      default () {
        const timezones = [{
          timezoneOffset: this.$moment().utcOffset(),
          displayLabel: this.$t('date.local_time'),
          tooltip: this.$t('date.local_time')
        }]

        if (this.$moment().utcOffset() !== BRAZILIAN_TIME_ZONE) {
          timezones.push({
            timezoneOffset: BRAZILIAN_TIME_ZONE,
            displayLabel: 'GMT -03:00',
            tooltip: this.$t('date.brazilian_timezone')
          })
        }

        return timezones
      }
    },
    week: {
      type: Object,
      default () {
        return {
          daynames: this.$moment.weekdaysShort(),
          workweek: true,
          hourStart: 7,
          hourEnd: 22
        }
      }
    },
    template: {
      type: Object,
      default: () => ({
        timegridDisplayPrimayTime (time) { return `${time.hour}:00` }
      })
    }
  },

  data () {
    return {
      calendarId: 0
    }
  },
  computed: {
    displayDate () {
      return this.$moment(this.value).format('MMMM, YYYY')
    },

    displayDatetime () {
      return this.$moment(this.value).format('YYYY-[W]W')
    },

    sanitizedEvents () {
      return this.events.map((event, key) => ({
        ...event,
        id: event.id || key.toString(),
        category: 'time'
      }))
    }
  },

  watch: {
    value: 'setTuiDate'
  },
  mounted () {
    this.setTuiDate()
  },

  methods: {
    forceCalendarRender () {
      this.calendarId += 1

      this.$nextTick(this.setTuiDate)
    },

    handleCreate (schedule) {
      this.$emit('create', {
        ...schedule,
        start: schedule.start.toDate().toISOString(),
        end: schedule.end.toDate().toISOString()
      })

      this.forceCalendarRender()
    },

    handleUpdate (event) {
      this.$emit('update', {
        ...event.schedule,
        start: event.start.toDate().toISOString(),
        end: event.end.toDate().toISOString()
      })
    },

    handleClick (event) {
      this.$emit('click', {
        ...event.schedule,
        start: event.schedule.start.toDate().toISOString(),
        end: event.schedule.end.toDate().toISOString()
      })
    },

    getTuiDate () {
      const date = this.$refs.tuiCalendar.invoke('getDate').toDate()
      return date.toISOString().substr(0, 10)
    },

    setTuiDate () {
      this.$refs.tuiCalendar.invoke('setDate', this.value)
    },

    changeCalendarDate (direction) {
      this.$refs.tuiCalendar.invoke(direction)
      this.$emit('input', this.getTuiDate())
    }
  }
}
</script>

<style lang="scss">
.calendar {
  background-color: $white;
  height: 100%;
  padding-top: 6*$base;
  width: 100%;

  @include breakpoint(md) {
    padding-right: 6*$base;
  }

  &__row-center {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__current-time {
    @extend %subtitle1;

    text-transform: capitalize;
  }

  &__button {
    display: inline-block;
    line-height: 0;

    &-image {
      width: 0.6rem;
    }
  }

  &__legend {
    @extend %caption-bold;

    align-items: flex-start;
    background-color: $white;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    @include breakpoint(md) {
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 4*$base 0 0;
    }

    &__item {
      @include margin(left, 11);

      padding: 2*$base 0;

      @include breakpoint(md) {
        padding-top: 0;
      }
    }

    .square {
      @include margin(right, 2);

      border: 1px solid $border-color;
      height: 12px;
      width: 12px;
    }
  }

  .tui-full-calendar-dayname-container {
    box-shadow: 0 8px 8px -8px rgba(39, 58, 80, 0.08);
  }

  .tui-full-calendar-timegrid-container,
  .tui-full-calendar-dayname-container {
    overflow-y: auto;
  }

  .tui-full-calendar-dayname-date {
    @extend %subtitle1;
  }

  .tui-full-calendar-time-schedule-content {
    @extend %caption-bold;
  }

  .tui-full-calendar-dayname-container .tui-full-calendar-today {
    border-bottom: 3px solid $red;
  }

  .tui-full-calendar-time-resize-handle {
    background-color: $red-light;
    background-image: none;
    border-radius: 1px;
    bottom: 4px;
    height: 2px;
    left: calc(50% - 16px);
    margin-left: 0;
    width: 32px;
  }

  .tui-full-calendar-week-container {
    min-height: 0;
  }
}

.calendar-component {
  height: 810px;

  @include breakpoint(md) {
    height: calc(100vh - 175px);
  }
}
</style>
