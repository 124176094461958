<template>
  <span
    id="speaker"
    data-test="speaker"
    class="speaker"
    aria-live="polite"
    aria-atomic="true"
    v-text="text"
  />
</template>
<script>
export default {
  name: 'Offscreen',
  data () {
    return {
      text: ''
    }
  },
  mounted () {
    this.$a11y.data = this.$data
  }
}
</script>
<style lang="scss">
.speaker {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
</style>
