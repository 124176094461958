<template>
  <div class="invite-new" :class="modifierClass('invite-new')">
    <app-loader v-if="isLoading" />
    <template v-else>
      <form class="invite-form" :class="modifierClass('invite-form')">
        <section class="invite-form__body">
          <h3
            v-if="showTitle"
            class="invite-form__title"
            :class="modifierClass('invite-form__title')"
          >
            {{ $t(`${langPath}.title`) }}
          </h3>

          <section
            class="invite-form__position"
            :class="modifierClass('invite-form__position')"
          >
            <label
              v-if="fixedTemplate"
              for="inviteFormPositionSelect"
              class="invite-form__template-title">
              {{ fixedTemplate.title }}
            </label>

            <label
              v-show="!fixedTemplate"
              for="inviteFormPositionSelect"
              class="invite-form__label">
              {{ $t(`${langPath}.choosePosition`) }}
            </label>

            <p
              v-if="hasAts && !fixedTemplate"
              class="invite-form__update-ats"
              data-test="ats-integration"
            >
              {{ $t(`${langPath}.update`) }}
              <span v-if="isFetchingAts">{{ $t(`${langPath}.loading`) }}</span>

              <ul class="invite-form__ats-list" v-else>
                <li
                  v-for="ats in this.ats"
                  :key="ats.id"
                  @click="updateAts(ats.id)"
                  class="invite-form__ats-list__item"
                >
                  {{ ats.atsSystem }}
                </li>
              </ul>
            </p>

            <app-searchable-select
              v-show="!fixedTemplate"
              name="templateSelected"
              :items="templatesOption"
              v-model="templateSelectedIdentifier"
              class="invite-form__input invite-form__input--template"
              id="inviteFormPositionSelect"
              data-test="invite-template-dropdown"
            />
          </section>

          <div class="invite-form__inputs">
            <app-text-input
              v-if="!templateSelected"
              v-model="title"
              v-validate="'required'"
              :data-vv-as="$t(`${langPath}.titleField`)"
              :label="$t(`${langPath}.createPositionLabel`)"
              name="title"
              class="invite-form__input"
            />

            <div v-if="!hideSalary">
              <label class="invite-form__label">
                {{ $t(`${langPath}.salaryTitle`) }}
                <span
                  v-if="canShowSalary"
                  data-test="invite-candidate-salary"
                >
                  - {{ $t(`${langPath}.salaryDescription`) }}{{ formatSalary }}
                </span>
              </label>

              <invites-salary-range
                v-if="!showSalaryEstimation"
                class="invite-form__input"
                :min-salary.sync="minSalary"
                :max-salary.sync="maxSalary"
                name="salaryRange"
                :data-vv-as="$t(`${langPath}.salaryEstimation`)"
                v-validate="'required|salary_range'"
                data-vv-value-path="salaryRange"
              />

              <app-click-grid
                v-else
                class="invite-form__input"
                :options="salaryEstimationOptions"
                v-model="salaryEstimation"
                v-validate="'required'"
                :data-vv-as="$t(`${langPath}.estimationField`)"
                name="salaryEstimation"
                :col-xs="3"
                :col-md="1"
                :col-lg="3"
              />
            </div>

            <fieldset>
              <div class="invite-form__label">
                <legend for="" class="invite-form__label">
                  {{ $t(`${langPath}.contractType`) }}
                </legend>
              </div>

              <app-click-grid
                :options="contractTypeOptions"
                v-model="contractType"
                :data-vv-as="$t(`${langPath}.contractTypeField`)"
                v-validate="'required'"
                name="contractType"
                class="invite-form__input"
                :col-xs="3"
                :col-md="1"
                :col-lg="3"
              />
            </fieldset>

            <invites-description
              v-model="description"
              v-validate="'required'"
              :data-vv-as="$t(`${langPath}.descriptionField`)"
              class="invite-form__input"
              @input:debounce="fetchInsights"
              name="description"
            />
          </div>
        </section>

        <div class="invite-form__footer">
          <ev-button
            :loading="isCreatingInvite"
            :disabled="sendInviteButtonDisabled"
            full-width
            data-cy="invite-new-confirm_button"
            @click="sendCreateInvite"
            data-test="submit-button"
          >
            {{ $t(`${langPath}.sendInvite`) }}
          </ev-button>
        </div>

        <div
          @click="showJobDescriptionInsights"
          class="insights-section"
          v-if="!isScreenSm"
          data-test="job-description-insights"
        >
          <div class="insights-container">
            <icn-wand class="insights-section--icon" view-box="0 0 45 45" />
            <span class="insights-section--text">
              <strong class="insights-section--link">
                {{ $t(`${langPath}.clickHere`) }}
              </strong>
              {{ $t(`${langPath}.callToInsights`) }}
            </span>
          </div>
        </div>

        <div
          class="invite-form__confirmation-container"
          v-if="rendersLinkToSent"
        >
          <p class="invite-form__confirmation-text">
            {{ $t(`${langPath}.confirmation.redirect.text`) }}
            <ev-button
              class="invite-form__redirect-button"
              variant="tertiary"
              size="medium"
              data-test="link-to-invite-sent"
              @click="redirectToInviteSent"
            >
              {{ $t(`${langPath}.confirmation.redirect.callToAction`) }}
            </ev-button>
          </p>
        </div>
      </form>
      <invites-insights
        :show="showInsights"
        :is-fetching="isFetchingInsights"
        :insights="insights"
        @close="hideJobDescriptionInsights"
        v-if="!isScreenSm"
      />
    </template>
  </div>
</template>

<script>
import { analyzeText } from '@/repository/companies-app/insights'

import { IcnWand } from '@revelotech/everest'
import EvButton from '@revelotech/everestV2/EvButton'
import InvitesDescription from './components/InvitesDescription'
import InvitesSalaryRange from './components/InvitesSalaryRange'
import InvitesInsights from './components/InvitesInsights'
import { createNamespacedHelpers } from 'vuex'
import {
  INVITE_TEMPLATES, CURRENT_USER, INVITES
} from '@/store/namespaces'
import gtmEvents from '@/helpers/constants/gtm-events.js'

const { mapState, mapActions } = createNamespacedHelpers(INVITES)
const inviteHelper = createNamespacedHelpers(INVITE_TEMPLATES)
const authHelper = createNamespacedHelpers(CURRENT_USER)

export default {
  name: 'InvitesNew',
  components: {
    EvButton,
    InvitesDescription,
    InvitesSalaryRange,
    InvitesInsights,
    IcnWand
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    variant: {
      type: String,
      default: '',
      validator: (value) => ['', 'inverted', 'only-white'].includes(value)
    },
    successRedirect: {
      type: Boolean,
      default: true
    },
    inviteTemplateId: {
      type: Number,
      default: null
    },
    candidate: {
      type: Object,
      required: true
    },
    fixedPositionId: {
      type: [String, Number],
      default: ''
    },
    inviteFormData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      langPath: __langpath,
      contractTypeOptions: [
        { value: 'CLT', label: 'CLT' },
        { value: 'PJ', label: 'PJ' },
        { value: 'A combinar', label: 'A combinar' }
      ],
      salaryEstimationOptions: [
        { value: 'below', label: this.$t(`${__langpath}.rangeBelow`) },
        { value: 'in_average', label: this.$t(`${__langpath}.rangeAligned`) },
        { value: 'over', label: this.$t(`${__langpath}.rangeAbove`) }
      ],
      salaryEstimation: [],
      templateSelectedIdentifier: 'null',
      minSalary: 0,
      maxSalary: 0,
      defaultDescription: this.$t(`${__langpath}.defaultTemplateDescription`),
      description: '',
      title: '',
      contractType: [],
      showInsights: false,
      windowWidth: window.innerWidth,
      isFetchingInsights: false,
      insights: {},
      sentUrl: ''
    }
  },

  computed: {
    ...authHelper.mapState(['currentUser']),
    ...mapState(['isCreatingInvite', 'inviteCreated']),
    ...inviteHelper.mapState([
      'inviteTemplates',
      'ats',
      'lastTemplate',
      'isFetchingAts',
      'isFetchingAtsOpening',
      'isFetchingInviteTemplate'
    ]),
    hideSalary () {
      return this.currentUser?.company?.hideSalary
    },
    showSalaryEstimation () {
      return this.currentUser?.company?.showSalaryEstimation
    },
    templateSelected () {
      if (this.templateSelectedIdentifier === 'null') {
        return false
      }

      const [type, id] = this.templateSelectedIdentifier.split('-')
      if (type === 'ats_opening') {
        return this.ats.reduce((arr, ats) => {
          return arr.concat(ats.atsOpenings)
        }, []).find((opening) => Number(opening.id) === Number(id))
      }

      return this.inviteTemplates.find((template) => (
        Number(template.id) === Number(id)
      ))
    },
    fixedTemplate () {
      if (!this.fixedPositionId) return null

      return this.inviteTemplates.find((inviteTemplate) => {
        return Number(inviteTemplate.positionId) === Number(this.fixedPositionId)
      })
    },
    isLoading () {
      return this.isFetchingAts ||
             this.isFetchingInviteTemplate
    },
    rendersLinkToSent () {
      return !this.isCreatingInvite && this.inviteCreated
    },
    sendInviteButtonDisabled () {
      return this.isCreatingInvite || this.inviteCreated
    },
    templatesOption () {
      const templateToOption = (template) => {
        return {
          label: template.title,
          value: `${template.type}-${template.id}`
        }
      }

      const newPosition = [
        {
          value: 'null',
          label: this.$t(
            `${__langpath}.newJob`
          )
        }
      ]

      const atsOpenings = this.ats.map((ats) => ({
        title: ats.atsSystem,
        items: ats.atsOpenings.map(templateToOption)
      }))

      return [
        {
          title: this.$t(`${__langpath}.newJob`),
          items: [...newPosition]
        },
        ...atsOpenings,
        {
          title: this.$t(`${__langpath}.myJobs`),
          items: [
            ...this
              .inviteTemplates
              .filter((template) => template.owned)
              .map(templateToOption)
          ]
        },
        {
          title: this.$t(`${__langpath}.otherJobs`),
          items: [
            ...this
              .inviteTemplates
              .filter((template) => !template.owned)
              .map(templateToOption)
          ]
        }
      ]
    },
    formatSalary () {
      return Number(this.candidate.minimumSalary)
        .toLocaleString().replace(',', '.')
    },
    inviteForm () {
      const invite = {
        ...this.inviteFormData,
        candidateId: this.candidate.id,
        title: this.title,
        salaryType: this.contractType[0],
        description: this.description
      }

      if (this.templateSelected) {
        if (this.templateSelected.type === 'invite_template') {
          invite.templateId = this.templateSelected.id
        }

        if (this.templateSelected.type === 'ats_opening') {
          invite.atsOpeningId = this.templateSelected.id
        }
      }

      if (!this.hideSalary) {
        if (this.showSalaryEstimation) {
          invite.salaryEstimation = this.salaryEstimation[0]
        } else {
          invite.minSalary = this.minSalary
          invite.maxSalary = this.maxSalary
        }
      }

      return invite
    },
    hasAts () {
      return this.ats && this.ats.length
    },
    isScreenSm () {
      return this.windowWidth < 576
    },
    canShowSalary () {
      return this.candidate.minimumSalary &&
        this.currentUser.canSeeSalary
    }
  },

  watch: {
    lastTemplate (val) {
      if (this.fixedTemplate) return
      if (this.inviteTemplateId) {
        this.templateSelectedIdentifier = `invite_template-${this.inviteTemplateId}`
      } else {
        this.templateSelectedIdentifier = `invite_template-${val.id}`
      }
    },
    templateSelectedIdentifier (val) {
      if (!this.templateSelected) {
        this.resetFields()
        return
      }

      this.title = this.templateSelected.title
      this.description = this.setDescription(this.templateSelected.description)
      this.minSalary = this.templateSelected.minSalary
      this.maxSalary = this.templateSelected.maxSalary
      this.contractType = [this.templateSelected.salaryType].filter((i) => {
        return i !== undefined && i !== null
      })

      this.handleUpdateAts()
      this.fetchInsights(this.templateSelected.description)
    },
    fixedTemplate: {
      immediate: true,
      handler (val) {
        if (!val) return

        this.templateSelectedIdentifier = `invite_template-${val.id}`
      }
    }
  },

  created () {
    this.getInviteTemplates()
    this.handleAts()
    this.resetFields()

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },

  methods: {
    ...mapActions(['createInvite']),
    ...inviteHelper.mapActions([
      'getInviteTemplates',
      'getAts',
      'updateAts',
      'updateAtsOpening'
    ]),
    resetFields () {
      this.title = ''
      this.description = this.defaultDescription
      this.minSalary = 0
      this.maxSalary = 0
      this.contractType = []
    },
    modifierClass (classSelector) {
      if (this.variant.length === 0) return ''

      return `${classSelector}--${this.variant}`
    },
    async sendCreateInvite (ev) {
      ev.preventDefault()

      if (!(await this.$validator.validate())) { return }

      const { id } = await this.createInvite({
        source: this.$route.query.source,
        sourceId: this.$route.query.sourceId,
        ...this.inviteForm
      })

      if (id) this.sentUrl = `${window.baseUrl}/#/invites/${id}/sent`

      this.$gtmTrackEvent(gtmEvents.companyInviteProfile)

      if (this.successRedirect) {
        this.redirectTo(this.sentUrl)
      }
      this.$emit('success', this.sentUrl)
    },
    redirectTo (url) {
      if (!url) { return }

      const queryString = this.$route.fullPath.split('?')[1] || ''
      const fullQueryString = queryString && `?${queryString}`

      window.location.assign(`${url}${fullQueryString}`)
    },
    handleUpdateAts () {
      if (this.templateSelected.type === 'ats_opening') {
        const openingId = this.templateSelected.id
        const ats = this.ats.find(
          (ats) => !!ats.atsOpenings.find(
            (opening) => opening === this.templateSelected
          )
        )

        const atsId = ats && ats.id

        this.updateAtsOpening({ atsId, openingId })
      }
    },
    handleAts () {
      this.getAts()
    },
    setDescription (text) {
      const description = text.length === 0 ? this.defaultDescription : text
      return description
    },
    showJobDescriptionInsights () {
      this.showInsights = true
      this.fetchInsights(this.description)
    },
    hideJobDescriptionInsights () {
      this.showInsights = false
    },
    async fetchInsights (payload) {
      if (!this.showInsights) {
        return
      }

      this.isFetchingInsights = true
      this.insights = await analyzeText(payload)
      this.isFetchingInsights = false
    },
    redirectToInviteSent () {
      window.top.location.assign(this.sentUrl)
    }
  }
}
</script>

<style lang="scss" >
.invite-new {
  position: relative;

  &--inverted {
    position: inherit;
  }
}

.invite-form {
  padding-bottom: 4*$base;
  position: relative;
  z-index: 20;

  @include breakpoint(md) {
    background-color: var(--bg-neutral-light-04);
    border: 1px solid var(--bg-neutral-light-04);

    &--inverted,
    &--only-white {
      background-color: var(--bg-neutral-blank);
      border: none;
    }
  }

  &__body {
    @include breakpoint(md) {
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      transition: 0.5s max-height;
    }
  }

  &__title {
    @extend %subtitle2;

    background-color: var(--bg-neutral-blank);

    @include breakpoint(md) {
      background-color: var(--bg-neutral-light-04);
      color: var(--tx-red-light-01);
      padding-left: 3*$base;

      &--inverted,
      &--only-white {
        background-color: var(--bg-neutral-blank);
      }
    }
  }

  &__template-title {
    @extend %subtitle1;

    color: var(--tx-neutral-light-01);
    margin-top: $base*3;
  }

  &__label {
    @extend %body-text2;

    color: var(--tx-neutral-light-01);
  }

  &__position {
    background-color: var(--bg-neutral-blank);
    padding-bottom: 3*$base;

    @include breakpoint(md) {
      padding: 0 3*$base 3*$base;

      &--inverted {
        background-color: var(--bg-neutral-light-04);
      }
    }
  }

  &__update-ats {
    @extend %body-text2;

    @include margin(top, 1);

    color: var(--tx-neutral-light-01);
  }

  &__ats {
    text-decoration: underline;

    &-list {
      display: inline-block;

      &__item {
        cursor: pointer;
        display: inline-block;
        text-decoration: underline;
        text-transform: capitalize;

        &:not(:last-child) {
          &::after {
            content: '|';
            display: inline-block;
            padding: 0 $base;
          }
        }
      }
    }
  }

  &__inputs {
    padding-bottom: 2*$base;

    @include breakpoint(md) {
      padding: 0 3*$base 2*$base;
    }
  }

  &__label,
  &__input {
    @include margin(top, 2);

    &--template {
      z-index: 999;
    }
  }

  &__footer {
    @include breakpoint(md) {
      padding: 0 3*$base 0;
    }
  }

  .insights-section {
    padding: 0 3*$base 2*$base;

    .insights-container {
      align-items: center;
      border-top: 1px solid var(--b-neutral-light-04);
      display: flex;
      padding: $base 0;
    }

    &--icon {
      cursor: pointer;
      height: 30px;
      margin-right: 8px;
      padding: 5px;
      width: 30px;
    }

    &--text {
      @extend %caption;
    }

    &--link {
      @extend %caption;

      color: var(--tx-red);
      cursor: pointer;
    }

    & > hr {
      margin-bottom: 8px;
    }
  }

  @media (max-width: $container-sm-max-width) {
    &__confirmation-container {
      margin-top: 4*$base;
    }
  }

  @include breakpoint(md) {
    &__confirmation-container {
      padding: 0 3*$base 0;
    }
  }

  &__confirmation-text {
    background: var(--bg-neutral-light-04);
    border-radius: $app-border-radius;
    font-size: 3.5*$base;
    line-height: 1.25rem;
    padding: 2*$base 3*$base;
  }

  &__redirect-button {
    display: inline;
    padding: 0;
    text-decoration: underline;
  }
}
</style>
