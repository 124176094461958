import { get, put } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import { toSnakeCase } from '@/helpers/case-style'
import deepObjectLopper from '@/helpers/deep-object-mapper'

export default {
  async getEmployersPositions (
    page, perPage, employerId = null, visible = true, sortBy = 'updated_at'
  ) {
    let positions = await get('/positions', {
      page,
      per_page: perPage,
      employer_id: employerId,
      visible: visible,
      sort_by: sortBy
    })
    const totalPages = positions.data.meta.total_pages
    const count = positions.data.meta.count || 0
    const publishedCount = positions.data.meta.published_count || 0

    positions = await JSONAPIClient.parse(positions.data)

    return { positions, totalPages, count, publishedCount }
  },

  async getPosition ({ positionId }) {
    const response = await get(`/positions/${positionId}`)

    return JSONAPIClient.parse(response.data)
  },

  async getPositions () {
    let positions = await get('/positions')
    positions = await JSONAPIClient.parse(positions.data)
    return positions
  },

  async getShortlistPositions (params = {}) {
    let positions = await get('/shortlist/positions', {
      employer_id: params.employerId,
      page: params.page,
      per_page: params.perPage
    })
    const totalPages = positions.data.meta.total_pages
    positions = await JSONAPIClient.parse(positions.data)
    return { positions, totalPages }
  },

  async getShortPositions (params = {}) {
    let positions = await get('/positions/short_index', { sort_by: params.sortBy })
    positions = await JSONAPIClient.parse(positions.data)
    return positions
  },

  async getPositionsSummary () {
    const response = await get('/employers/positions/summary', {
      page: 1,
      per_page: 6
    })

    return await JSONAPIClient.parse(response.data)
  },

  async getPositionIdFromExternalId (externalId) {
    const response = await get(`/positions/id/${externalId}/`)

    return response.data
  },

  async updatePosition (positionId, params) {
    const response = await put(
      `/positions/${positionId}/`,
      deepObjectLopper(params, toSnakeCase)
    )

    return response
  }
}
