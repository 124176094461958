import { toSnakeCase } from '@/helpers/case-style'

const mountFormData = (items) => {
  const formData = new FormData()

  Object.entries(items).forEach(([key, item]) => {
    if (item && typeof item === 'object' && Object.entries(item).length) {
      Object.entries(item).forEach(([itemKey, itemValue]) => {
        if (itemValue) formData.append(`${key}[${toSnakeCase(itemKey)}]`, itemValue)
      })
    } else {
      if (item) formData.append(toSnakeCase(key), item)
    }
  })

  return formData
}

export default mountFormData
