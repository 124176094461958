import { get, post } from '@/helpers/request'
import jsonApiClient from '@/helpers/jsonapi-client'
import { companiesUrl } from '@/helpers/constants/external-urls'

import deepObjectLooper from '@/helpers/deep-object-mapper'
import { toSnakeCase } from '@/helpers/case-style'

const baseUrl = `${companiesUrl}/api/v2/job_posting`

export default {
  async getSalaryAverage (positionID) {
    const url = `${baseUrl}/average_compensation?job_post[position_id]=${positionID}`

    const response = await get(url)

    return jsonApiClient.parse(response.data)
  },

  async matchPosition (positionTitle) {
    try {
      const response = await post(`${baseUrl}/match_position`,
        deepObjectLooper({
          jobPost: { positionTitle }
        }, toSnakeCase))

      return jsonApiClient.parse(response.data)
    } catch (httpError) {
      return null
    }
  }
}
