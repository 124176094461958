<template>
  <section class="shortlist">
    <div class="shortlist__container">
      <header class="shortlist__header">
        <div class="shortlist__header-button">
          <ev-button
            color="red"
            data-test="previous-button"
            no-padding
            variant="tertiary"
            size="small"
            @click="goToPositionDetails"
          >
            <ev-icon
              color="red"
              class="shortlist__header-button-icon"
              name="arrow-left"
              size="small"
            />

            {{ $t('pages.Shortlist.previousButtonText') }}
          </ev-button>
        </div>

        <div class="shortlist__header-heading">
          <div>
            <h1 class="shortlist__header-title">
              {{ $t('pages.Shortlist.title') }}
            </h1>

            <div class="shortlist__header-session">
              <h2
                class="shortlist__header-subtitle"
                data-test="shortlist-title"
              >
                {{ shortlist.title }}
              </h2>
            </div>
          </div>

          <div class="shortlist__header-workflows">
            <ev-button
                variant="tertiary"
                color="red"
                class="shortlist__header-share-button"
                @click="handleShareShortlist"
                data-test="share-shortlist-button"
              >
                {{ $t('pages.Shortlist.shareShortlist') }}
              </ev-button>

            <ev-router-link
              :route="workflowRoute"
              data-test="workflow-route-link"
              target="_blank"
            >
              {{ $t('pages.Shortlist.workflowRouteLink') }}
            </ev-router-link>
          </div>
        </div>
      </header>

      <div class="shortlist__content" v-if="shortlistItems.length">
        <transition-group
          name="slide-fade"
          tag="ul"
          class="shortlist__items"
        >
          <li
            v-for="candidate in shortlistItems"
            :key="candidate.id"
            class="shortlist__item"
          >
            <shortlist-card
              :candidate="candidate"
              :shortlist="shortlist"
              @remove="handleRemove"
              data-test="shortlist-card"
            />
          </li>
        </transition-group>
      </div>
      <empty-state
        v-else-if="!isLoading"
        data-test="empty-state"
        :message="$t('pages.Shortlist.emptyState.description')"
        :call-to-action-text="$t('pages.Shortlist.emptyState.callToAction')"
        :call-to-action-event-name="'go-to-workflow'"
        @go-to-workflow="redirectToWorkflow"
      />
    </div>
  </section>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'
import EvRouterLink from '@revelotech/everestV2/EvRouterLink'
import EvIcon from '@revelotech/everestV2/EvIcon'
import ShortlistCard from './components/ShortlistCard.vue'
import EmptyState from '@/pages/Positions/components/EmptyState'
import { createNamespacedHelpers } from 'vuex'
import { TheNotificationCardList } from '@revelotech/everest'

const shortlistsHelpers = createNamespacedHelpers('shortlists')

export default {
  name: 'Shortlist',

  components: {
    EvButton,
    EvIcon,
    EvRouterLink,
    ShortlistCard,
    EmptyState
  },

  data () {
    return {
      shortlistItems: [],
      shortlist: {},
      isLoading: false
    }
  },

  computed: {
    id () {
      return this.$route.params.id
    },

    workflowRoute () {
      return {
        name: 'WorkflowBoard',
        params: { positionId: this.id }
      }
    }
  },

  async created () {
    this.isLoading = true
    this.shortlist = await this.getShortlistPosition(this.id)
    this.shortlistItems = await this.getShortlistCandidates(this.id)
    this.isLoading = false
  },

  methods: {
    ...shortlistsHelpers.mapActions([
      'getShortlistCandidates',
      'getShortlistPosition',
      'shareShortlist'
    ]),
    goToPositionDetails () {
      return this.$router.push({ name: 'ActivePositions' })
    },

    removeCandidate (candidateId) {
      const candidateIndex = this.shortlistItems.findIndex(item => item.id === candidateId)

      this.shortlistItems.splice(candidateIndex, 1)
    },

    handleRemove (candidateId) {
      TheNotificationCardList.createNotification({
        message: this.$t('pages.Shortlist.actionSuccess'),
        modifier: 'success'
      })

      this.removeCandidate(candidateId)
    },

    redirectToWorkflow () {
      this.$router.push(this.workflowRoute)
    },

    async handleShareShortlist () {
      try {
        const baseUrl = window.location.origin
        const response = await this.shareShortlist(this.id)
        const shortlistUUID = response.data.data.attributes.uuid
        navigator.clipboard.writeText(`${baseUrl}/#/shortlists/shared/${shortlistUUID}`)

        TheNotificationCardList.createNotification({
          message: this.$t('pages.Shortlist.shareLinkSuccess'),
          modifier: 'success'
        })
      } catch (error) {
        TheNotificationCardList.createNotification({
          title: this.$t('pages.Shortlist.genericError.title'),
          message: this.$t('pages.Shortlist.genericError.message'),
          modifier: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import "@revelotech/everestV2/tx_overline.scss";

.shortlist {
  --shortlist-card-min-width: 290px;

  background-color: $bg-red-light-03;
  min-height: 100vh;

  @include breakpoint(md) {
    padding-top: $app-header-height;
  }

  &__container {
    @extend %container;
  }

  &__header {
    margin-top: $base * 7;

    &-button {
      &-icon {
        margin-right: $base * 2;
      }
    }

    &-heading {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: $base * 6;
    }

    &-title {
      @extend %tx-overline;
    }

    &-session {
      align-items: center;
      display: flex;
    }

    &-share-button {
      margin-left: $base * 6;
    }

    &-subtitle {
      font: $tx-title-2;
      margin-top: $base * 2;
    }

    &-workflows {
      display: flex;
    }
  }

  &__content {
    margin-top: $base * 6;
  }

  &__items {
    display: grid;
    gap: $base * 6;
    grid-template-columns:
      repeat(
        auto-fit,
        minmax(
          var(--shortlist-card-min-width),
          var(--shortlist-card-min-width)
        )
      );
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }

  .slide-fade-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.3, 0.5, 1);
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    opacity: 0;
    transform: translateY(50px);
  }
}
</style>
