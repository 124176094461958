import onboardingRoutes from './onboarding'
import signupRoutes from './signup'
import failRoutes from './fail'

export default [
  ...onboardingRoutes,
  ...failRoutes,
  {
    path: 'signup',
    children: signupRoutes,
    component: () => import('@/pages/Signup/Signup')
  }
]
