export const toKebabCase = (text) =>
  (text || '') &&
  text
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((word) => word.toLowerCase())
    .join('-')

export const toCamelCase = (text) =>
  (text || '') &&
  text
    .replace(/[^a-zA-Z0-9]+(.)/g, (_, char) => char.toUpperCase())
    .replace(/^\w/, (char) => char.toLowerCase())

export const toSnakeCase = (text) =>
  (text || '') && toKebabCase(text).replace(/-/g, '_')

export const toCapitalize = (text) => {
  if (!text) return ''

  return text.charAt(0).toUpperCase() + text.slice(1)
}
