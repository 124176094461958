import Vue from 'vue'
import VueI18n from 'vue-i18n'
import config from '@/configs/vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n(config)

const loadedLanguages = [config.defaultLanguage]

const setI18nLanguage = (lang) => {
  i18n.locale = lang
  history.pushState(
    {},
    '',
    window.location.search.replace(/locale=[\w-]+/, `locale=${lang}`)
  )
}

export const loadLanguageAsync = async (lang = config.defaultLanguage) => {
  lang = lang.toLowerCase()

  if (i18n.locale === lang) { return }

  if (loadedLanguages.includes(lang)) {
    return setI18nLanguage(lang)
  }

  try {
    const langMessages = await require(`../lang/${lang}`)
    i18n.setLocaleMessage(lang, langMessages.default)
    loadedLanguages.push(lang)
    setI18nLanguage(lang)
  } catch (err) {
    i18n.setLocaleMessage(config.defaultLanguage, config.defaultMessages)
    setI18nLanguage(config.defaultLanguage)
  }
}

export default i18n
