<template>
  <app-navigation
    class="the-header"
    data-test="header-app-navigation"
    show-notifications
    :notifications="notifications"
    :route-to="{ path: '/home' }"
    :warning="isImpersonating"
    @readAll="readAll"
  >
    <ev-link
      v-if="isImpersonating"
      slot="before-bell"
      class="the-header__link-stop-impersonating"
      a-href="/#/companies/companies/stop_impersonating"
      variant="tertiary"
      color="blue"
    >
      {{ $t(`${langPath}.menu.stopImpersonating`, { company: company.name }) }}
    </ev-link>
    <message-box
      slot="after-bell"
      class="the-header-menu-item--mobile"
    />
    <app-navigation-menu-item
      v-if="goesToFirstSteps"
      data-test="menu-first-steps"
      :warning="isImpersonating"
    >
      <router-link
        to="/companies/companies/first-steps"
        data-test="first-steps-link"
      >
        {{ $t(`${langPath}.menu.firstSteps`) }}
      </router-link>
    </app-navigation-menu-item>
    <app-navigation-menu-item
      data-test="menu-home"
      :warning="isImpersonating"
    >
      <router-link
        to="/home"
      >
        {{ $t(`${langPath}.menu.home`) }}
      </router-link>
    </app-navigation-menu-item>
    <app-navigation-menu-item
      data-test="menu-position"
      :warning="isImpersonating"
    >
      <router-link
        to="/"
      >
        {{ $t(`${langPath}.menu.positions`) }}
      </router-link>
    </app-navigation-menu-item>
    <app-navigation-menu-item
      :warning="isImpersonating"
      data-test="menu-candidates"
    >
      <router-link to="/companies/employers/candidates">
        {{ $t(`${langPath}.menu.candidates`) }}
      </router-link>
    </app-navigation-menu-item>
    <app-navigation-menu-item
      has-dropdown
      :warning="isImpersonating"
    >
      <span data-test="company-name">{{ company.name }}</span>
      <nav slot="dropdown">
        <the-user-menu
          :user="currentUser"
          data-test="the-user-menu"
        />
      </nav>
    </app-navigation-menu-item>
    <app-navigation-menu-item
      has-dropdown
      no-caret
      class="the-header-menu-item--mobile"
      :warning="isImpersonating"
    >
      <span
        class="question-icon"
        data-test="question-icon"
        @click="handleQuestionClick"
      >
        <ev-icon
          color="gray60"
          name="question-circle"
        />
        <div
          v-if="!extensionRead"
          class="question-icon__bubble"
          data-test="question-icon-bubble"
        />
      </span>
      <nav
        slot="dropdown"
      >
        <the-help-menu />
      </nav>
    </app-navigation-menu-item>
    <message-box
      slot="second-icon"
      class="the-header-menu-item--desktop"
    />
    <app-navigation-menu-item
      slot="third-icon"
      class="the-header-menu-item--desktop"
      has-dropdown
      no-caret
      :warning="isImpersonating"
    >
      <span
        class="question-icon"
        data-test="question-icon"
        @click="handleQuestionClick"
      >
        <ev-icon
          name="question-circle"
        />
        <div
          v-if="!extensionRead"
          class="question-icon__bubble"
          data-test="question-icon-bubble"
        />
      </span>
      <nav
        slot="dropdown"
      >
        <the-help-menu />
      </nav>
    </app-navigation-menu-item>
  </app-navigation>
</template>

<script>
import EvIcon from '@revelotech/everestV2/EvIcon'
import TheHelpMenu from '@/components/TheHelpMenu'
import TheUserMenu from '@/components/TheUserMenu'
import AppNavigation from '@/components/App/AppNavigation'
import AppNavigationMenuItem from '@/components/App/AppNavigationMenuItem'
import newsStorage from '@/helpers/news-storage.js'
import gtmEvents from '@/helpers/constants/gtm-events'
import EvLink from '@revelotech/everestV2/EvLink'

import { createNamespacedHelpers } from 'vuex'
import { COMPANY, CURRENT_USER, NOTIFICATIONS } from '@/store/namespaces'
import rollouts from '@/mixins/rollouts'
import MessageBox from './Chat/MessageBox'
import { companiesLatamUrl } from '@/helpers/constants/external-urls'
import routerHelper from '@/helpers/router'

const companyHelper = createNamespacedHelpers(COMPANY)
const currentUserHelper = createNamespacedHelpers(CURRENT_USER)
const notificationsHelper = createNamespacedHelpers(NOTIFICATIONS)

export default {
  name: 'TheHeader',
  components: {
    EvLink,
    EvIcon,
    TheHelpMenu,
    TheUserMenu,
    AppNavigation,
    AppNavigationMenuItem,
    MessageBox
  },
  mixins: [rollouts],
  data () {
    return {
      langPath: __langpath,
      extensionRead: true
    }
  },
  computed: {
    ...companyHelper.mapState(['company']),
    ...notificationsHelper.mapState(['notifications']),
    ...currentUserHelper.mapGetters(['isImpersonating']),
    ...currentUserHelper.mapState(['currentUser']),
    goesToFirstSteps () {
      return this.currentUser?.company?.trial
    }
  },
  async created () {
    await Promise.all([
      this.getCompany(),
      this.getNotifications(),
      this.getCurrentUser(),
      this.initFeatureData('latam_company')
    ])

    this.$identify(this.company?.id, {
      employerId: this.currentUser?.id,
      uidValue: this.currentUser?.id,
      name: this.company?.name
    })

    this.extensionRead = newsStorage.isNewsRead('extension-read', this.currentUser.id)

    if (this.featureIsActive('latam_company')) {
      routerHelper.redirectToOutside(companiesLatamUrl)
    }
  },
  mounted () {
    this.$gtmTrackEvent(gtmEvents.companiesAppEvent, {
      event_action: gtmEvents.companiesAccessing
    })
  },
  methods: {
    ...companyHelper.mapActions(['getCompany']),
    ...notificationsHelper.mapActions(['getNotifications', 'readAll']),
    ...currentUserHelper.mapActions(['getCurrentUser']),
    handleQuestionClick () {
      newsStorage.setNewsRead('extension-read', this.currentUser.id)
      this.extensionRead = true
    }
  }
}
</script>

<style lang="scss">
.the-header {
  box-shadow: $shadow-16;
  display: flex;
  padding: 0 $base*3;
  position: fixed;
  z-index: 20;

  &__link-stop-impersonating {
    &.the-header__link-stop-impersonating {
      background-color: $white;
    }
  }

  .question-icon {
    position: relative;

    &__bubble {
      background: $red;
      border-radius: 50%;
      height: 2*$base;
      position: absolute;
      right: 2*$base;
      top: 2*$base;
      width: 2*$base;
    }
  }

  &-menu-item {
    &--desktop {
      display: none;

      @include breakpoint(md) {
        display: flex;
      }
    }

    &--mobile {
      @include breakpoint(md) {
        display: none;
      }
    }
  }
}

</style>
