import { Deserializer } from 'jsonapi-serializer'

const deserializer = new Deserializer({
  keyForAttribute: 'camelCase',
  typeAsAttribute: true
})

export default {
  parse (jsonapi) {
    return deserializer.deserialize(jsonapi)
  }
}
