import notifications from '@/repository/companies-app/notifications'

export default {
  async getNotifications ({ commit }) {
    const response = await notifications.getNotifications()
    commit('SET_NOTIFICATIONS', response)
  },
  async readAll ({ commit }) {
    await notifications.readAll()
    commit('SET_NOTIFICATIONS', [])
  }
}
