<template>
  <ev-modal
    size="md"
    @close="$emit('close')"
    data-test="shortlist-card-reject-modal"
  >
    <div class="shortlist-card-reject-modal__heading">
      <h3 class="shortlist-card-reject-modal__heading-title">
        {{ $t('pages.ShortlistCardRejectModal.title') }}
      </h3>
    </div>

    <form
      class="shortlist-card-reject-modal__form"
      @submit.prevent="reject"
      data-test="form"
    >
      <div class="shortlist-card-reject-modal__form-field">
        <ev-select
          v-model="reason"
          data-test="reason-select"
          name="reason"
          :label="$t('pages.ShortlistCardRejectModal.reasonLabel')"
          :options="reasonOptions"
          @input="$v.reason.$touch"
        />
      </div>

      <div class="shortlist-card-reject-modal__form-field">
        <ev-text-area
          v-model="comment"
          data-test="comment-text-area"
          name="comment"
          :label-flag="$t('pages.ShortlistCardRejectModal.optionalFlag')"
          :label="$t('pages.ShortlistCardRejectModal.commentLabel')"
        />
      </div>

      <div class="shortlist-card-reject-modal__form-field">
        <div class="shortlist-card-reject-modal__form-actions">
          <ev-button
            type="button"
            variant="secondary"
            data-test="cancel-button"
            @click="$emit('close')"
          >
            {{ $t('pages.ShortlistCardRejectModal.cancelButton') }}
          </ev-button>

          <ev-button
            type="submit"
            data-test="submit-button"
            :disabled="$v.$invalid"
          >
            {{ $t('pages.ShortlistCardRejectModal.submitButton') }}
          </ev-button>
        </div>
      </div>
    </form>
  </ev-modal>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'
import EvModal from '@revelotech/everestV2/EvModal'
import EvSelect from '@revelotech/everestV2/EvSelect'
import EvTextArea from '@revelotech/everestV2/EvTextArea'
import talentDisqualifiedReasons from '@/helpers/constants/talent-disqualified-reason.js'
import { required } from 'vuelidate/lib/validators'
import { NOTIFICATION_CARDS } from '@/store/namespaces'
import { createNamespacedHelpers } from 'vuex'

const notificationCardsHelper = createNamespacedHelpers(NOTIFICATION_CARDS)
const shortlistsHelper = createNamespacedHelpers('shortlists')

export default {
  name: 'ShortlistCardRejectModal',

  components: {
    EvButton,
    EvModal,
    EvSelect,
    EvTextArea
  },

  props: {
    candidate: {
      type: Object,
      required: true
    },
    shortlist: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      reason: 'other',
      comment: '',
      feedback: false
    }
  },

  validations () {
    return {
      reason: { required }
    }
  },

  computed: {
    reasonOptions () {
      return talentDisqualifiedReasons()
    }
  },

  methods: {
    ...notificationCardsHelper.mapActions(['createNotificationCard']),
    ...shortlistsHelper.mapActions(['reproveApplication']),

    async reject () {
      try {
        await this.reproveApplication({
          positionId: this.shortlist.atsPositionId,
          globalCandidateId: this.candidate.globalCandidateId,
          comment: this.comment,
          reason: this.reason
        })

        this.$emit('rejected', this.candidate.id)
      } catch (error) {
        this.createNotificationCard({
          message: this.$t('pages.ShortlistCardRejectModal.errorNotificationTitle'),
          modifier: 'error'
        })

        this.$rollbar.error(error)
      } finally {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.shortlist-card-reject-modal {
  &__heading {
    margin-bottom: $base * 7;

    &-title {
      font: $tx-title-3;
    }
  }

  &__form {
    &-field {
      &:not(:last-child) {
        margin-bottom: $base * 6;
      }
    }

    &-actions {
      display: flex;
      gap: $base * 4;
    }
  }
}
</style>
