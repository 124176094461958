export default [
  {
    path: '/create_position',
    name: 'CreatePosition',
    component: () => import('@/pages/Onboarding/CreatePosition')
  },
  {
    path: '/search_steps',
    name: 'SearchSteps',
    component: () => import('@/pages/Onboarding/SearchSteps')
  },
  {
    path: '/search_results',
    name: 'SearchResults',
    component: () => import('@/pages/Onboarding/SearchResults')
  }
]
