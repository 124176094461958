import 'normalize.css'
import '@revelotech/everest/dist/everest.css'
import '@revelotech/everestV2/base.css'

import './configs/app'
import './configs/vuelidate'

import '@/components/_globals'

import Vue from 'vue'

import filters from './filters'

import i18n from './plugins/vue-i18n'
import router from './plugins/vue-router'
import store from './store'
import errorHandler from './plugins/error-handler'
import VueTheMask from 'vue-the-mask'
import VueGtm from 'vue-gtm'
import './plugins/a11y'
import './plugins/trackers'
import './plugins/vee-validate'
import './plugins/vue-moment'
import './plugins/everest'
import './plugins/validation-error-messages'
import './plugins/v-tooltip'

import App from './App.vue'

Vue.config.productionTip = process.env.NODE_ENV === 'production'

// Load filters before creating Vue instance
filters.init(Vue)

Vue.use(VueTheMask)

// Configure GTM
if (Vue.config.productionTip) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_CODE,
    enabled: true,
    vueRouter: router
  })
}

Vue.use(errorHandler, {
  router,
  onUnhandledError: (err) => {
    window.onerror(err, window.location.href)
  }
})

i18n.missing = (locale, key) => {
  Vue.prototype.$rollbar.error(`Missing translate (${locale}): ${key}`)
  return ''
}

new Vue({
  i18n,
  router,
  store,
  vuetify: Vue.buildVuetifyInstance(),
  render: h => h(App)
}).$mount('#app')
