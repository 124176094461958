import routerHelper from '@/helpers/router'
import { companiesUrl } from '@/helpers/constants/external-urls'

export default [
  {
    path: '/companies*',
    name: 'Companies',
    beforeEnter: to => {
      const companies = companiesUrl
      const newUrl = `${companies}${to.fullPath.replace('/companies', '')}`

      routerHelper.redirectToOutside(newUrl)
    }
  }
]
