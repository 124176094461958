<template>
  <ul class="usermenu">
    <li class="usermenu__item">
      <router-link
        data-test="usermenu__profile-settings"
        class="usermenu__link"
        to="/companies/companies/profile/edit"
      >
        {{ $t(`${langPath}.profileSettings`) }}
      </router-link>
    </li>
    <li class="usermenu__item">
      <router-link
        data-test="usermenu__other-settings"
        class="usermenu__link"
        to="/companies/users/profile/edit"
      >
        {{ $t(`${langPath}.otherSettings`) }}
      </router-link>
    </li>
    <li class="usermenu__item">
      <router-link
        data-test="usermenu__logout"
        class="usermenu__link"
        to="/companies/users/ats_sign_out"
      >
        {{ $t(`${langPath}.logout`) }}
      </router-link>
    </li>
  </ul>
</template>

<script>

export default {
  name: 'TheUserMenu',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>

<style lang="scss">
.usermenu {
  cursor: default;
  padding: 2*$base;

  &__item {
    @extend %button2;

    text-transform: none;

    &--dropdown { margin: 3*$base 2*$base 0; }
    &:not(:last-child) { margin-bottom: 2*$base; }
  }

  &__link {
    display: block;
    padding: 2*$base;

    &:hover { background: $gray5; }

    &-description {
      @extend %caption;

      display: block;
      margin-top: $base;
      padding-left: 2*$base;
    }
  }
}
</style>
