import { get, patch } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import { companiesUrl } from '@/helpers/constants/external-urls'

const baseUrl = `${companiesUrl}/api/v2/companies/notifications`

export default {
  getNotifications () {
    return get(baseUrl)
      .then(notifications => {
        if (!notifications) return []

        return JSONAPIClient.parse(notifications.data)
      })
      .then(notifications => notifications.map(notification => ({
        ...notification,
        href: `${companiesUrl}${notification.href}`
      })))
  },
  readAll () {
    return patch(`${baseUrl}/read_all`)
  }
}
