export default {
  SET_INVITE_TEMPLATES (state, inviteTemplates) {
    state.inviteTemplates = inviteTemplates
  },
  SET_IS_FETCHING_INVITE_TEMPLATES (state, isFetchingInviteTemplate) {
    state.isFetchingInviteTemplate = isFetchingInviteTemplate
  },
  SET_ATS (state, ats) {
    state.ats = ats
  },
  SET_IS_FETCHING_ATS (state, isFetchingAts) {
    state.isFetchingAts = isFetchingAts
  },
  SET_IS_FETCHING_ATS_OPENING (state, isFetchingAtsOpening) {
    state.isFetchingAtsOpening = isFetchingAtsOpening
  },
  SET_LAST_TEMPLATE (state, lastTemplate) {
    state.lastTemplate = lastTemplate
  }
}
