import { get } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'

export default {
  async getSharedShortlist (token) {
    const response = await get(`/shared_shortlists/${token}`)

    return JSONAPIClient.parse(response.data)
  },

  async getSharedShortlistCandidates (token) {
    const response = await get(`/shared_shortlists/${token}/candidates`)

    return JSONAPIClient.parse(response.data)
  }
}
