<template>
  <div class="alert-notifications">
    <ev-notification
      v-for="(notification) in notificationCards"
      :key="notification.id"
      :title="notification.title"
      :message="notification.message"
      :variant="notification.modifier"
      class="notifications__item"
      data-test="notification"
      @close="removeNotificationCard(notification.id)"
    />
  </div>
</template>

<script>
import EvNotification from '@revelotech/everestV2/EvNotification'
import { createNamespacedHelpers } from 'vuex'
import { NOTIFICATION_CARDS } from '@/store/namespaces'
import eventBus from '@/support/event-bus'

const alertNotificationsHelper = createNamespacedHelpers(NOTIFICATION_CARDS)
const FALLBACK_TRANSLATE = {
  error: 'genericError',
  success: 'genericSuccess'
}

export default {
  name: 'AlertNotifications',

  components: { EvNotification },

  computed: {
    ...alertNotificationsHelper.mapState(['notificationCards'])
  },
  created () {
    eventBus.on('alert-notification', this.addEventBusNotification)
  },
  methods: {
    ...alertNotificationsHelper.mapActions(['removeNotificationCard', 'createNotificationCard']),
    addEventBusNotification (notification) {
      this.createNotificationCard({
        title: notification.title,
        message: notification.message || this.$t(FALLBACK_TRANSLATE[notification.type]),
        modifier: notification.type
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-notifications {
  position: fixed;
  right: $base * 4;
  top: $app-header-height + $base * 4;
  z-index: 99999;

  &__item {
    &:not(:first-of-type) {
      margin-top: $base * 2;
    }
  }
}
</style>
