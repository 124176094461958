import applicants from '@/repository/applicants'
import interviews from '@/repository/interviews'

export default {
  async getApplicant ({ commit }, applicantId) {
    const response = await applicants.getApplicant(applicantId)
    commit('SET_APPLICANT', response)
  },
  updateCandidate (_, { applicantId, candidateId, ...candidateParams }
  ) {
    return applicants.updateCandidate({
      applicantId: applicantId,
      candidateId: candidateId,
      ...candidateParams
    })
  },
  setApplicantId ({ commit }, applicantId) {
    commit('SET_APPLICANT_ID', applicantId)
  },
  async getInterviews ({ commit, state }) {
    commit('SET_INTERVIEWS', [])
    const result = await interviews.getInterviews(state.applicantId)
    commit('SET_INTERVIEWS', result)
  },
  async createInterview ({ commit }, { applicantId, interview }) {
    const response = await interviews.createInterview({ applicantId, interview })

    commit('interviews/ADD_INTERVIEW', response, { root: true })
  },
  async cancelInterview ({ commit }, id) {
    await interviews.cancelInterview(id)
    commit('REMOVE_INTERVIEW', id)
  },
  async getApplicantsWithUnscheduledInterviews ({ commit }, page = 1) {
    if (page === 1) commit('SET_LOADING_APPLICANTS', true)
    else commit('SET_LOADING_MORE_APPLICANTS', true)

    const { data, meta } = await applicants.getApplicantsWithUnscheduledInterviews(page)

    if (page === 1) commit('SET_APPLICANTS_WITH_UNSCHEDULED_INTERVIEW', data)
    else commit('ADD_APPLICANTS_WITH_UNSCHEDULED_INTERVIEW', data)
    commit('SET_APPLICANTS_WITH_UNSCHEDULED_INTERVIEW_PAGE', meta.page)
    commit('SET_APPLICANTS_WITH_UNSCHEDULED_INTERVIEW_TOTAL_PAGES', meta.totalPages)
    commit('SET_APPLICANTS_WITH_UNSCHEDULED_INTERVIEW_TOTAL_ENTRIES', meta.totalEntries)

    commit('SET_LOADING_MORE_APPLICANTS', false)
    commit('SET_LOADING_APPLICANTS', false)
  },
  async getApplicantsWithConductedInterviews ({ commit }, page = 1) {
    if (page === 1) commit('SET_LOADING_APPLICANTS', true)
    else commit('SET_LOADING_MORE_APPLICANTS', true)

    const { data, meta } = await applicants.getApplicantsWithConductedInterviews(page)

    if (page === 1) commit('SET_APPLICANTS_WITH_CONDUCTED_INTERVIEW', data)
    else commit('ADD_APPLICANTS_WITH_CONDUCTED_INTERVIEW', data)
    commit('SET_APPLICANTS_WITH_CONDUCTED_INTERVIEW_PAGE', meta.page)
    commit('SET_APPLICANTS_WITH_CONDUCTED_INTERVIEW_TOTAL_PAGES', meta.totalPages)
    commit('SET_APPLICANTS_WITH_CONDUCTED_INTERVIEW_TOTAL_ENTRIES', meta.totalEntries)

    commit('SET_LOADING_MORE_APPLICANTS', false)
    commit('SET_LOADING_APPLICANTS', false)
  },
  async getApplicantsWithSentProposals ({ commit }, page = 1) {
    if (page === 1) commit('SET_LOADING_APPLICANTS', true)
    else commit('SET_LOADING_MORE_APPLICANTS', true)

    const { data, meta } = await applicants.getApplicantsWithSentProposals(page)

    if (page === 1) commit('SET_APPLICANTS_WITH_SENT_PROPOSAL', data)
    else commit('ADD_APPLICANTS_WITH_SENT_PROPOSAL', data)
    commit('SET_APPLICANTS_WITH_SENT_PROPOSAL_PAGE', meta.page)
    commit('SET_APPLICANTS_WITH_SENT_PROPOSAL_TOTAL_PAGES', meta.totalPages)
    commit('SET_APPLICANTS_WITH_SENT_PROPOSAL_TOTAL_ENTRIES', meta.totalEntries)

    commit('SET_LOADING_MORE_APPLICANTS', false)
    commit('SET_LOADING_APPLICANTS', false)
  },
  removeApplicantWithUnscheduledInterviews ({ commit, state }, applicantId) {
    commit('REMOVE_APPLICANT_WITH_UNSCHEDULED_INTERVIEW', applicantId)
    commit('SET_APPLICANTS_WITH_UNSCHEDULED_INTERVIEW_TOTAL_ENTRIES',
      state.applicantsWithUnscheduledInterviewTotalEntries - 1)
  },
  removeApplicantWithConductedInterviews ({ commit, state }, applicantId) {
    commit('REMOVE_APPLICANT_WITH_CONDUCTED_INTERVIEW', applicantId)
    commit('SET_APPLICANTS_WITH_CONDUCTED_INTERVIEW_TOTAL_ENTRIES',
      state.applicantsWithConductedInterviewTotalEntries - 1)
  },
  removeApplicantWithSentProposals ({ commit, state }, applicantId) {
    commit('REMOVE_APPLICANT_WITH_SENT_PROPOSAL', applicantId)
    commit('SET_APPLICANTS_WITH_SENT_PROPOSAL_TOTAL_ENTRIES',
      state.applicantsWithSentProposalTotalEntries - 1)
  },
  addApplicantWithSentProposal ({ commit, state }, applicant) {
    commit('ADD_APPLICANTS_WITH_SENT_PROPOSAL', [applicant])
    commit('SET_APPLICANTS_WITH_SENT_PROPOSAL_TOTAL_ENTRIES',
      state.applicantsWithSentProposalTotalEntries + 1)
  }
}
