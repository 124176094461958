import { companiesUrl } from '@/helpers/constants/external-urls'

export default {
  redirectToOutside (path) {
    window.location.assign(path)
  },
  redirectTo404 () {
    window.location.assign(`${companiesUrl}/404`)
  },
  redirectTo500 () {
    window.location.assign(`${companiesUrl}/500`)
  }
}
