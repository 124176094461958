export default [
  {
    path: 'company',
    component: () => import('@/pages/Signup/SignupCompany'),
    name: 'SignupCompany'
  },
  {
    path: 'sdr-schedule',
    component: () => import('@/pages/Signup/SignupSdrSchedule'),
    name: 'SignupSdrSchedule'
  },
  {
    path: 'search-filter',
    component: () => import('@/pages/Signup/SignupSearchFilter'),
    name: 'SignupSearchFilter'
  },
  {
    path: 'search-steps',
    component: () => import('@/pages/Signup/SignupSearchSteps'),
    name: 'SignupSearchSteps'
  }
]
