import { get, post } from '@/helpers/request'
import { toCamelCase } from '@/helpers/case-style'
import deepObjectLopper from '@/helpers/deep-object-mapper'
import jsonApiClient from '@/helpers/jsonapi-client'
import { companiesUrl } from '@/helpers/constants/external-urls'

const baseUrl = `${companiesUrl}/api/v2`

export default {
  async getUnavailabilities (candidateId, startDateISO) {
    const basePath = `${baseUrl}/companies/candidates/${candidateId}/unavailabilities`
    const params = '?date=' + startDateISO.replace(/T.*/, '')
    const response = await get(basePath + params)

    return jsonApiClient.parse(response.data)
  },

  async getCandidate (candidateId, urlQuery) {
    const candidateUrl = `${baseUrl}/companies/candidates/${candidateId}`
    const response = await get(candidateUrl, { params: urlQuery })

    return jsonApiClient.parse(response.data)
  },

  async getQuestions (candidateId) {
    const response = await get(
      `${baseUrl}/companies/candidates/${candidateId}/questions`
    )
    return jsonApiClient.parse(response.data)
  },

  async getUnansweredQuestions (candidateId) {
    const response = await get(
      `${baseUrl}/companies/candidates/${candidateId}/questions/unanswered_questions`
    )
    return jsonApiClient.parse(response.data)
  },

  async createQuestion (candidateId, question) {
    const params = {
      data: {
        attributes: { question }
      }
    }
    const response = await post(
      `${baseUrl}/companies/candidates/${candidateId}/questions`,
      params
    )
    return jsonApiClient.parse(response.data)
  },

  async getEvaluations (candidateId) {
    const url = `${baseUrl}/companies/candidates/${candidateId}/evaluations`
    const response = await get(url)
    const parsedResponse = await jsonApiClient.parse(response.data)
    return deepObjectLopper(parsedResponse, toCamelCase)
  },

  async getTags (candidateId) {
    const url = `${baseUrl}/companies/candidates/${candidateId}/tags`

    const response = await get(url)
    return response.data.data.map(toCamelCase)
  },

  async getTests (candidateId) {
    const url = `${baseUrl}/companies/candidates/${candidateId}/tests`

    const response = await get(url)

    return jsonApiClient.parse(response.data)
  },

  async getSummary (candidateId) {
    const path = `${baseUrl}/companies/candidates/${candidateId}/summary`
    const response = await get(path)

    return jsonApiClient.parse(response.data)
  }
}
