// Globally register all base (App) components for convenience, because they
// will be used very frequently. Components are registered using the
// Their name which should already be PascalCased

import Vue from 'vue'

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
  // Look for files in the /App directory
  './App',
  // Do not look in subdirectories
  false,
  // Only include "App" prefixed .vue files
  /^\.?\/?App[A-Z].+\.vue/
)

// For each matching file name...
requireComponent.keys().forEach((fileName) => {
  // Get the component config
  const componentConfig = requireComponent(fileName)
  const componentName = fileName
    // Remove './' from the file name
    .replace(/^\.\//, '')
    // Remove the file extension from the end
    .replace(/\.\w+$/, '')
  // Globally register the component
  Vue.component(componentName, componentConfig.default || componentConfig)
})
