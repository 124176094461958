import { get, post, del, put } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import { toSnakeCase } from '@/helpers/case-style'
import deepObjectLopper from '@/helpers/deep-object-mapper'

export default {
  async getStages (positionId) {
    const stages = await get(`/positions/${positionId}/stages`)
    return JSONAPIClient.parse(stages.data)
  },
  async getStage ({ positionId, stageId, employerId = undefined }) {
    let url = `/positions/${positionId}/stages/${stageId}`
    if (employerId) url += `?employer_id=${employerId}`
    const stage = await get(url)
    return JSONAPIClient.parse(stage.data)
  },
  async getKinds () {
    const kinds = await get('/stages/kinds')
    return kinds.data.data
  },
  async createStage ({ positionId, label, kind }) {
    const stage = await post(`/positions/${positionId}/stages`, { label, kind })
    return JSONAPIClient.parse(stage.data)
  },
  updateStage ({ id, label }) {
    return put(`/stages/${id}`, { label })
  },
  async reorderStages ({ positionId, stagesList }) {
    await put(`/positions/${positionId}/stages/update_order`, {
      update_stages: deepObjectLopper(stagesList, toSnakeCase)
    })
  },
  deleteStage (stageId) {
    return del(`/stages/${stageId}`)
  }
}
