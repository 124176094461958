import i18n from '@/plugins/vue-i18n'

export default function talentDisqualifiedReasons () {
  return [
    { value: 'other', label: i18n.t('talentDisqualifiedReasons.other') },
    { value: 'salary_expectation', label: i18n.t('talentDisqualifiedReasons.salaryExpectation') },
    { value: 'seniority', label: i18n.t('talentDisqualifiedReasons.seniority') },
    { value: 'technical_skills', label: i18n.t('talentDisqualifiedReasons.technicalSkills') }
  ]
}
