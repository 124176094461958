import planRepo from '@/repository/companies-app/plans'

export default {
  async getCurrentPlan ({ commit }) {
    const response = await planRepo.getCurrentPlan()

    commit('SET_CURRENT_PLAN', response)
    commit('SET_IS_LOADING_CURRENT_PLAN', false)
  },
  async getLastPlan ({ commit }) {
    const response = await planRepo.getLastPlan()

    commit('SET_LAST_PLAN', response)
    commit('SET_IS_LOADING_LAST_PLAN', false)
  }
}
