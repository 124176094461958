import uuid from 'uuid/v4'

export default {
  createNotificationCard ({ state },
    { title, message, modifier }) {
    const id = uuid()
    state.notificationCards.unshift({
      id,
      title,
      message,
      modifier
    })
  },
  removeNotificationCard ({ state }, id) {
    state.notificationCards = state.notificationCards.filter(
      (notificationCard) => notificationCard.id !== id
    )
  }
}
