import { get } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import deepObjectLopper from '@/helpers/deep-object-mapper'
import { toCamelCase } from '@/helpers/case-style'
import { companiesUrl } from '@/helpers/constants/external-urls'

const baseUrl = `${companiesUrl}/api/v2`

export async function getMessages (page = 1) {
  const response = await get(`${baseUrl}/companies/employers/messages?page=${page}`)
  const data = await JSONAPIClient.parse(response.data)
  const parsedData = data.map((message) => ({
    ...message,
    date: new Date(message.createdAt)
  }))

  parsedData.meta = deepObjectLopper(response.data.meta, toCamelCase)
  return parsedData
}
