import routerHelper from '@/helpers/router'

export default [
  {
    path: '/invites/:inviteId/sent',
    component: () => import('@/pages/Invites/InviteSent.vue'),
    name: 'InviteSent'
  },
  {
    name: 'EmployersInvite',
    path: '/employers/invites/:inviteId',
    beforeEnter: (to) => routerHelper.redirectToOutside(to.path)
  }
]
