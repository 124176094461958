<template>
  <v-date-picker
    v-model="internalValue"
    :dark="dark"
    :no-title="noTitle"
    :full-width="fullWidth"
    :no-shadow="noShadow"
    :class="{ 'v-date-picker--no-box-shadow': noShadow }"
    :color="colorHex"
    :header-color="headerColorHex"
    :allowed-dates="allowedDates"
    data-test="date-picker"
    @input="handleInput"
  >
    <section
      v-if="withButtons"
      class="v-date-picker__buttons-footer"
    >
      <ev-button
        variant="tertiary"
        color="blue"
        data-test="date-picker-button-cancel"
        @click="$emit('cancel')"
      >
        {{ $t(`${langPath}.cancelLabel`) }}
      </ev-button>

      <ev-button
        variant="tertiary"
        color="blue"
        data-test="date-picker-button-ok"
        @click="$emit('input', internalValue)"
      >
        {{ $t(`${langPath}.okLabel`) }}
      </ev-button>
    </section>
  </v-date-picker>
</template>

<script>
import DateFormatter from '@/helpers/date-formatter.js'
import dateMethods from '@/helpers/date-methods.js'
import { styleTokens } from '@revelotech/everest'
import EvButton from '@revelotech/everestV2/EvButton'

const COLOR_OPTIONS = {
  primary: styleTokens.red,
  secondary: styleTokens.gray100
}

export default {
  name: 'AppDatePicker',
  components: {
    EvButton
  },
  props: {
    value: {
      type: String,
      default: DateFormatter.todayISOStr()
    },
    color: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'secondary'].includes(value)
      }
    },
    headerColor: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'secondary'].includes(value)
      }
    },
    dark: {
      type: Boolean,
      default: true
    },
    noTitle: {
      type: Boolean,
      default: true
    },
    fullWidth: {
      type: Boolean,
      default: true
    },
    noShadow: {
      type: Boolean,
      default: false
    },
    withButtons: {
      type: Boolean,
      default: false
    },
    onlyWeekDays: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      langPath: __langpath,
      internalValue: this.value
    }
  },
  computed: {
    colorHex () {
      return COLOR_OPTIONS[this.color]
    },
    headerColorHex () {
      return COLOR_OPTIONS[this.headerColor]
    }
  },
  watch: {
    value: {
      handler () {
        this.internalValue = this.value
      },
      immediate: true
    }
  },
  methods: {
    handleInput () {
      if (this.withButtons) { return }

      this.$emit('input', this.internalValue)
    },

    allowedDates (date) {
      if (this.onlyWeekDays) {
        return dateMethods.isWeekDay(date)
      }
      return true
    }
  }
}
</script>

<style lang="scss">
.v-date-picker {
  width: 100%;

  &__buttons-footer {
    border: none;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &--no-box-shadow {
    box-shadow: none !important;
  }
}

.v-date-picker-table {
  height: auto !important;
  padding: 0 !important;
}

.v-date-picker-header {
  padding: 0 !important;
}
</style>
