import repository from '@/repository/companies-app/wish-lists'

export default {
  async getWishLists ({ commit }) {
    commit('SET_IS_FETCHING_WISH_LISTS', true)
    commit('SET_WISH_LISTS', await repository.getWishLists())
    commit('SET_IS_FETCHING_WISH_LISTS', false)
  },

  async createWishList ({ commit }, payload) {
    try {
      commit('SET_IS_CREATING_WISH_LIST', true)

      const responseWishList = await repository.create(payload)
      const wishListObject = {
        id: responseWishList.id,
        name: payload,
        candidates: []
      }

      commit('ADD_WISH_LIST', wishListObject)
      commit('SET_IS_CREATING_WISH_LIST', false)

      return wishListObject
    } catch (error) {
      commit('SET_IS_CREATING_WISH_LIST', false)
      throw error
    }
  },

  async addCandidate ({ commit }, { wishListId, candidateId }) {
    commit('ADD_CANDIDATE', { wishListId, candidateId })
    await repository.addCandidate({ wishListId, candidateId })
  },

  async removeCandidate ({ commit }, { wishListId, candidateId }) {
    commit('REMOVE_CANDIDATE', { wishListId, candidateId })
    await repository.deleteCandidate({ wishListId, candidateId })
  }
}
