export default [
  {
    path: '/candidates/:candidateId/invites/new',
    name: 'InvitesNew',
    component: () => import('@/pages/Invites/InvitesNewModal')
  },
  {
    path: '/invites/messages/recent',
    name: 'ListMessages',
    component: () => import('@/components/Chat/MessagesContainer')
  }
]
