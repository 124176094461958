export default {
  positions: [],
  totalPages: 0,
  positionsTotal: 0,
  publishedPositionsTotal: 0,
  activePositions: [],
  activePositionsTotal: 0,
  activePositionsPage: 0,
  activePositionsTotalPages: 0,
  employerPositions: [],
  positionsSummary: [],
  employerPositionsTotal: 0,
  employerPositionsTotalPages: 0,
  employerPositionsPage: 0,
  isFetchingPositions: true,
  archivedPositions: [],
  archivedPositionsTotalPages: 0,
  archivedPositionsPage: 0,
  shortlistPositions: [],
  shortlistPositionsTotal: 0,
  shortlistPositionsPage: 0,
  shortlistPositionsTotalPages: 0,
  isFetchingArchivedPositions: true,
  isFetchingPositionsSummary: true,
  isFetchingShortlistPositions: false,
  employerIdFilter: null,
  employerIdArchivedFilter: null,
  sortBy: 'updated_at',
  isSorting: false
}
