export const APPLICANTS = 'applicants'
export const CANDIDATES = 'candidates'
export const COMMENTS = 'comments'
export const COMPANIES_POSITIONS = 'companies_positions'
export const COMPANY = 'company'
export const CURRENT_USER = 'current_user'
export const EMPLOYERS = 'employers'
export const EXTERNAL_CALENDARS = 'external_calendars'
export const FEEDBACKS = 'feedbacks'
export const FILTERS = 'filters'
export const INTERNAL_MANAGER = 'internal_manager'
export const INTERVIEWS = 'interviews'
export const INVITES = 'invites'
export const INVITE_TEMPLATES = 'invite_templates'
export const JOB_POSTINGS = 'job_postings'
export const NOTIFICATIONS = 'notifications'
export const NOTIFICATION_CARDS = 'notification_cards'
export const POSITIONS = 'positions'
export const PLANS = 'plans'
export const ARCHIVED_POSITIONS = 'archivedPositions'
export const STAGES = 'stages'
export const ROLLOUTS = 'rollouts'
export const NEXT_TASKS = 'next_tasks'
export const SHARED_CANDIDATES = 'sharedCandidates'
export const SIGNUP = 'signup'
export const SKILLS = 'skills'
export const SHORTLISTS = 'shortlists'
export const WISH_LISTS = 'wish-lists'
export const SHARED_SHORTLISTS = 'shared_shortlists'
