import invites from '@/repository/companies-app/invites'

export default {
  async getInvite ({ commit }, inviteId) {
    commit('SET_IS_FETCHING_INVITE', true)
    const invite = await invites.getInvite(inviteId)
    commit('SET_INVITE', invite)
    commit('SET_IS_FETCHING_INVITE', false)
  },

  async createInvite ({ commit, state }, payload) {
    const invite = {
      ...payload,
      source: state.source || undefined
    }

    commit('SET_INVITE_CREATED', false)
    commit('SET_IS_CREATING_INVITE', true)
    try {
      const responseInviteData = await invites.createInvite(invite)
      commit('SET_IS_CREATING_INVITE', false)
      commit('SET_INVITE_CREATED', true)
      return responseInviteData
    } catch {
      commit('SET_IS_CREATING_INVITE', false)
      commit('SET_INVITE_CREATED', false)
      return {}
    }
  },

  async makeProposal ({ commit, state }, payload) {
    commit('SET_IS_UPDATING_INVITE', true)

    const response = await invites.makeProposal(payload)
    commit('SET_INVITE', {
      ...state.invite,
      state: 'proposal_company'
    })

    commit('SET_IS_UPDATING_INVITE', false)
    return response
  },

  setSource ({ commit }, source) {
    commit('SET_SOURCE', source)
  },

  async getInviteSummary ({ commit, state }, inviteId) {
    commit('SET_IS_FETCHING_INVITE_SUMMARY', true)
    const inviteSummary = await invites.summary(inviteId)
    commit('SET_INVITE_SUMMARY', inviteSummary)
    commit('SET_IS_FETCHING_INVITE_SUMMARY', false)

    return inviteSummary
  },

  async getSimilarCandidates ({ commit, state }, inviteId) {
    commit('SET_IS_FETCHING_SIMILAR_CANDIDATES', true)
    try {
      const similarCandidates = await invites.similarCandidates(inviteId)
      commit('SET_SIMILAR_CANDIDATES', similarCandidates)
      commit('SET_IS_FETCHING_SIMILAR_CANDIDATES', false)
      commit('SET_ERROR_FETCHING_SIMILAR_CANDIDATES', false)
      return similarCandidates
    } catch {
      commit('SET_ERROR_FETCHING_SIMILAR_CANDIDATES', true)
      commit('SET_IS_FETCHING_SIMILAR_CANDIDATES', false)
    }
  }
}
