import internalManagerRepo from '@/repository/companies-app/internal-manager'

export default {
  async getInternalManager ({ commit }) {
    commit('SET_IS_FETCHING_INTERNAL_MANAGER', true)
    const internalManager = await internalManagerRepo.getDetails()
    commit('SET_INTERNAL_MANAGER', internalManager)
    commit('SET_IS_FETCHING_INTERNAL_MANAGER', false)
  }
}
