export default {
  applicant: {},
  applicantId: undefined,
  interviews: [],
  applicantsWithUnscheduledInterview: [],
  applicantsWithUnscheduledInterviewPage: 0,
  applicantsWithUnscheduledInterviewTotalPages: 0,
  applicantsWithUnscheduledInterviewTotalEntries: 0,
  applicantsWithConductedInterview: [],
  applicantsWithConductedInterviewPage: 0,
  applicantsWithConductedInterviewTotalPages: 0,
  applicantsWithConductedInterviewTotalEntries: 0,
  applicantsWithSentProposal: [],
  applicantsWithSentProposalPage: 0,
  applicantsWithSentProposalTotalPages: 0,
  applicantsWithSentProposalTotalEntries: 0,
  loadingApplicants: false,
  loadingMoreApplicants: false
}
