import messages from '@/lang/pt-br'

const defaultLanguage = 'pt-br'

const dateTimeConfig = {
  'pt-br': {
    short: {
      day: 'numeric', month: 'short', year: 'numeric'
    },
    long: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    monthYear: {
      month: 'long',
      year: 'numeric'
    },
    dayMonthYear: {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    },
    dayMonthFullYear: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    },
    hoursMinutes: {
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  en: {
    short: {
      month: 'short', day: 'numeric', year: 'numeric'
    },
    long: {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    dayMonthYear: {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    },
    dayMonthFullYear: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    },
    hoursMinutes: {
      hour: 'numeric',
      minute: 'numeric'
    }
  }
}

const numberFormats = {
  'pt-br': {
    currency: {
      style: 'currency',
      currency: 'BRL',
      useGrouping: true,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  }
}

export default {
  defaultLanguage,
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
  messages: {
    [defaultLanguage]: messages
  },
  dateTimeFormats: dateTimeConfig,
  numberFormats
}
