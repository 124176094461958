import applicants from '@/repository/applicants'

export default [
  {
    path: '/invites/:inviteId/interviews/new',
    name: 'InvitesInterviewsNew',
    beforeEnter: (to, _from, next) => {
      const inviteId = to.params.inviteId

      applicants.getApplicantIdFromInviteId(inviteId)
        .then((response) => {
          next({
            path: `/applicants/${response.id}/interviews/new`,
            query: to.query
          })
        })
        .catch(() => next({ name: 'Workflow' }))
    }
  },
  {
    path: '/applicants/:applicantId/interviews/new',
    name: 'InterviewsNew',
    component: () => import('@/pages/Interviews/InterviewsNew/InterviewsNew')
  }
]
