import { get, post, put } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import { companiesUrl } from '@/helpers/constants/external-urls'
import { toSnakeCase, toCamelCase } from '@/helpers/case-style'
import deepObjectLopper from '@/helpers/deep-object-mapper'

const baseUrl = `${companiesUrl}/api/v2`

export default {
  async getPosition (id) {
    const response = await get(`${baseUrl}/companies/positions/${id}`)
    return JSONAPIClient.parse(response.data)
  },

  async getPositions (externalIds) {
    const params = new URLSearchParams()
    externalIds.map((id) => params.append('positions_ids[]', id))

    const response = await get(
      `${baseUrl}/ats/positions/search?${decodeURI(params.toString())}`
    )

    return JSONAPIClient.parse(response.data)
  },

  async getRecommendedCandidates (externalId) {
    const getUrl = `${baseUrl}/ats/positions/${externalId}/recommended_candidates`
    const response = await get(getUrl)

    return JSONAPIClient.parse(response.data)
  },

  async createPosition (attributes) {
    const params = {
      data: {
        attributes: deepObjectLopper(attributes, toSnakeCase)
      }
    }
    const response = await post(`${baseUrl}/companies/positions`, params)
    return {
      ...response.data.data,
      links: deepObjectLopper(response.data.links, toCamelCase)
    }
  },

  async updatePosition (id, attributes = {}) {
    if (!id || !Object.keys(attributes).length) return false

    const params = {
      data: {
        attributes: deepObjectLopper(attributes, toSnakeCase)
      }
    }

    const response = await put(`${baseUrl}/companies/positions/${id}`, params)
    const responseParsed = JSONAPIClient.parse(response.data)
    // eslint-disable-next-line no-prototype-builtins
    if (response.data.hasOwnProperty('links')) {
      responseParsed.links = response.data.links
    }

    return responseParsed
  }

}
