import { post } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import mountFormData from '@/helpers/mount-form-data'

export default {
  async addExternalCandidate ({ positionId, candidate, shortlistAttributes }) {
    const params = mountFormData({
      positionId, candidate, shortlist_attributes: shortlistAttributes
    })

    const response = await post('/candidates/', params)

    return JSONAPIClient.parse(response.data)
  }
}
