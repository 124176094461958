export default [
  {
    path: 'signup/fail/active_company',
    component: () => import('@/pages/Signup/Fail/ActiveCompany'),
    name: 'CompanyExists'
  },
  {
    path: 'signup/fail/inactive_company',
    component: () => import('@/pages/Signup/Fail/InactiveCompany'),
    name: 'InactiveCompany'
  },
  {
    path: 'signup/fail/access_request_sent',
    component: () => import('@/pages/Signup/Fail/AccessRequestSent'),
    name: 'AccessRequestSent'
  },
  {
    path: 'signup/fail/invalid_email',
    component: () => import('@/pages/Signup/Fail/InvalidEmail'),
    name: 'InvalidEmail',
    meta: { skipAuth: true }
  }
]
