export default {
  SET_STAGES (state, stages) {
    state.stages = [...stages]
  },
  SET_STAGE (state, stage) {
    state.stages = state.stages.map((stageIterator) => {
      if (stage?.id === stageIterator.id) {
        return {
          ...stageIterator,
          ...stage
        }
      }

      return stageIterator
    })
  },
  REMOVE_APPLICANT (state, { applicantId, stageId }) {
    state.stages = state.stages.map((stage) => {
      if (stage?.id === stageId) {
        return {
          ...stage,
          applicants: stage.applicants && stage.applicants.filter(
            (applicant) => applicant.id !== applicantId
          )
        }
      }
      return stage
    })
  },
  ADD_APPLICANT (state, { applicant, stageId }) {
    state.stages = state.stages.map((stage) => {
      if (stage?.id === stageId.toString()) {
        return {
          ...stage,
          applicants: [
            applicant,
            ...(stage.applicants)
          ]
        }
      }
      return stage
    })
  },
  ADD_STAGE (state, stage) {
    state.stages = [
      ...state.stages,
      stage
    ]
  },
  SET_KINDS (state, kinds) {
    state.kinds = kinds
  },
  REMOVE_STAGE (state, stageId) {
    state.stages = state.stages.filter((stage) => stage.id !== stageId)
  },
  SET_PROPOSAL_SENT (state, applicantId) {
    state.stages = state.stages?.map((stage) => {
      stage.applicants = stage.applicants?.map((applicant) => {
        const props = {}
        if (applicant.id === applicantId) {
          props.currentState = 'awaiting_proposal'
        }
        return { ...applicant, ...props }
      })
      return stage
    })
  },
  SET_PROPOSAL_ACCEPTED (state, applicantId) {
    state.stages = state.stages?.map((stage) => {
      stage.applicants = stage.applicants?.map((applicant) => {
        const props = {}
        if (applicant.id === applicantId) {
          props.currentState = 'accepted_proposal'
        }
        return { ...applicant, ...props }
      })
      return stage
    })
  },
  SET_PROPOSAL (state, { applicantId, proposal }) {
    state.stages = state.stages?.map((stage) => {
      stage.applicants = stage.applicants?.map((applicant) => {
        const props = {}
        if (applicant.id === applicantId) {
          props.proposal = { ...applicant.proposal, ...proposal }
        }
        return { ...applicant, ...props }
      })
      return stage
    })
  },
  SET_APPLICANTS_ORDER (state, { stageId, applicantsOrder }) {
    state.stages = state.stages?.map((stage) => {
      if (stage?.id !== stageId) return stage

      return {
        ...stage,
        applicants: stage.applicants?.map((applicant) => {
          if (!applicantsOrder.includes(applicant.id)) return applicant

          return {
            ...applicant,
            applicantIndex: applicantsOrder.indexOf(applicant?.id) + 1
          }
        })
      }
    })
  },
  SET_APPLICANT (state, { stageId, applicant }) {
    state.stages = state.stages?.map((stage) => {
      if (stage?.id !== stageId) return stage

      return {
        ...stage,
        applicants: stage.applicants?.map((stateApplicant) => {
          if (stateApplicant.id !== applicant.id) return stateApplicant
          return {
            ...stateApplicant,
            ...applicant
          }
        })
      }
    })
  }
}
