import { get, post, put } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import { toSnakeCase } from '@/helpers/case-style'
import deepObjectLopper from '@/helpers/deep-object-mapper'

export default {
  async getInterviews (applicantId) {
    const interviews = await get(`/applicants/${applicantId}/interviews`)

    return JSONAPIClient.parse(interviews.data)
  },
  async getEmployersInterviews (selectedDate) {
    const interviews =
      await get(`/employers/interviews?interview_date=${selectedDate}`)

    return JSONAPIClient.parse(interviews.data)
  },
  async getEmployersUpcomingInterviews (page = 1) {
    const { data } = await get(`/employers/interviews/upcoming?page=${page}`)
    const meta = data.meta

    return {
      data: await JSONAPIClient.parse(data),
      meta: { totalPages: meta.total_pages, page: meta.page }
    }
  },
  async createInterview ({ applicantId, interview }) {
    const response = await post(
      `/applicants/${applicantId}/interviews`,
      deepObjectLopper(interview, toSnakeCase)
    )
    return JSONAPIClient.parse(response.data)
  },
  cancelInterview (interviewId) {
    return put(`/interviews/${interviewId}/cancel`)
  },
  async updateInterview ({ applicantId, interview }) {
    const response = await put(
      `/applicants/${applicantId}/interviews/${interview.id}`,
      deepObjectLopper(interview, toSnakeCase)
    )
    return JSONAPIClient.parse(response.data)
  }
}
