const getNews = (newsName) => {
  const news = window.localStorage.getItem(newsName)

  if (!news) return []

  return JSON.parse(news)
}

export const isNewsRead = (newsName, currentUserID) => {
  const news = getNews(newsName)

  return !!news.find(item => item.id === currentUserID)
}

export const setNewsRead = (newsName, currentUserID) => {
  const news = getNews(newsName)

  if (news && news.find(item => item.id === currentUserID)) return

  news.push({ id: currentUserID })

  window.localStorage.setItem(newsName, JSON.stringify(news))

  return true
}

export default {
  isNewsRead,
  setNewsRead
}
