<template>
  <ul class="tabs">
    <li
      v-for="(tab, index) in tabs"
      :key="index"
    >
      <router-link
        class="tabs__item"
        active-class="tabs__item--active"
        :exact-path="true"
        :to="tab.to"
        data-test="tab-item"
      >
        <ev-icon
          class="tabs__icon"
          color="gray60"
          :name="tab.icon"
        />
        {{ tab.title }}
        <ev-badge
          v-if="!isNaN(tab.count)"
          class="tabs__badge"
          :title="tab.count.toString()"
          variant="red"
        />
      </router-link>
    </li>
  </ul>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import EvBadge from '@revelotech/everestV2/EvBadge'
import EvIcon from '@revelotech/everestV2/EvIcon'

import { POSITIONS, CURRENT_USER } from '@/store/namespaces'

const positionsHelper = createNamespacedHelpers(POSITIONS)
const currentUserHelper = createNamespacedHelpers(CURRENT_USER)

export default {
  name: 'Tabs',
  components: { EvBadge, EvIcon },
  computed: {
    ...positionsHelper.mapState(['positionsTotal', 'employerPositionsTotal']),
    ...currentUserHelper.mapState(['currentUser']),
    tabs () {
      return [
        {
          title: 'Minhas vagas',
          to: { name: 'Positions' },
          icon: 'user',
          count: this.employerPositionsTotal
        },
        {
          title: 'Todas ativas',
          to: { name: 'ActivePositions' },
          icon: 'bolt-alt',
          count: this.positionsTotal
        },
        {
          title: 'Arquivadas',
          to: { name: 'ArchivedPositions' },
          icon: 'folder'
        }
      ]
    }
  },
  async mounted () {
    await this.getCurrentUser()
    await this.getPositionsTotals(this.currentUser.id)
  },
  methods: {
    ...positionsHelper.mapActions(['getPositionsTotals']),
    ...currentUserHelper.mapActions(['getCurrentUser'])
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  @extend %body-text2;

  border-bottom: 1px solid $gray20;
  display: flex;
  font-weight: 600;
  list-style: none;
  padding-bottom: $base * 4;

  &__badge {
    display: inline;
    margin-left: $base;
  }

  &__icon {
    &.tabs__icon {
      font-size: $base*4;
    }
  }

  &__item {
    @include margin(right, 10);

    color: $gray60;
    padding-bottom: $base * 4;
    position: relative;

    &:focus {
      outline: 0;
    }

    &--active {
      color: $red;

      &::after {
        background-color: $red;
        border-radius: 2px;
        bottom: 0;
        content: '';
        display: block;
        height: 3px;
        left: 0;
        position: absolute;
        width: 100%;
      }

      .tabs__icon {
        color: $red;
      }
    }
  }
}
</style>
