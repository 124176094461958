import { get, put } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import { toSnakeCase } from '@/helpers/case-style'
import deepObjectLopper from '@/helpers/deep-object-mapper'

export default {
  async getCompany () {
    const company = await get('/employer_company')

    return JSONAPIClient.parse(company.data)
  },

  async putCompany (params) {
    const response = await put(
      '/employer_company',
      deepObjectLopper(params, toSnakeCase)
    )

    return JSONAPIClient.parse(response.data)
  }
}
