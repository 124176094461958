<template>
  <router-view
    data-test="limited-access-router-view"
  />
</template>

<script>
import authenticationGuard from '@/mixins/authentication-guard'

export default {
  name: 'LimitedAccess',
  mixins: [authenticationGuard]
}
</script>
