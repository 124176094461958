import { post } from '@/helpers/request'
import { companiesUrl } from '@/helpers/constants/external-urls'
import JSONAPIClient from '@/helpers/jsonapi-client'

const baseUrl = `${companiesUrl}/api/v2`

export async function analyzeText (text) {
  const params = {
    data: {
      attributes: {
        text: text
      }
    }
  }

  const response = await post(`${baseUrl}/text_analysis`, params)

  return JSONAPIClient.parse(response.data)
}
