<template>
  <section class="positions">
    <header class="positions__header">
      <div class="positions__header-wrapper">
        <section class="positions__links">
          <h1 class="positions__title">
            {{ $t(`${langPath}.title`) }}
          </h1>

          <section class="positions__published-positions-box">
            <a
              v-if="hasPublishedPositions"
              href="#"
              class="positions__published-positions-link"
              data-test="published-positions-link"
              @click="openModal"
            >
              <ev-icon
                class="positions__published-positions-icon"
                name="megaphone"
                color="gray60"
              />
              <span class="positions__published-positions-label">
                {{ $t(`${langPath}.publishedPositions`) }}
              </span>
            </a>
          </section>

          <ev-button
            class="positions__new-position"
            data-ga-id="clicked_position_create"
            data-test="positions-create-button"
            @click="redirectToPositionCreate"
          >
            {{ $t(`${langPath}.newPosition`) }}
          </ev-button>
        </section>
        <tabs />
      </div>
    </header>
    <section v-if="pageLoaded" class="positions__content">
      <div class="positions__wrapper">
        <router-view
          data-test="view-more-trigger"
          data-test-active-filter="active-filter"
          data-test-archvied-filter="archived-filter"
          data-test-unarchived-emitter="true"
          data-test-archived-emitter="true"
          data-test-sort-by-emitter="true"
          @fetchActivePositionsByEmployer="fetchActivePositions"
          @fetchActivePositions="fetchActivePositions"
          @fetchArchivedPositions="fetchArchivedPositions"
          @fetchArchivedPositionsByEmployer="fetchArchivedPositions"
          @fetchShortlistPositions="fetchShortlistPositions"
          @fetchShortlistPositionsPage="fetchShortlistPositionsPage"
          @fetchEmployerShortlistPositions="fetchEmployerShortlistPositions"
          @fetchEmployerShortlistPositionsPage="fetchEmployerShortlistPositionsPage"
          @nextActivePositionsPage="nextActivePositionsPage"
          @nextArchivedPositionsPage="nextArchivedPositionsPage"
          @nextEmployerPositionsPage="nextEmployersPage"
          @archivePosition="handleArchivePosition"
          @unarchivePosition="handleUnarchivePosition"
          @updated-sort-by="handleUpdateSortBy"
        />
      </div>
    </section>

    <published-positions-modal
      v-if="showModal"
      :company="company"
      :published-positions-total="publishedPositionsTotal"
      data-test="published-positions-modal"
      @close="closeModal"
      @copy="handleCopy"
    />
  </section>
</template>

<script>
import EvIcon from '@revelotech/everestV2/EvIcon'
import EvButton from '@revelotech/everestV2/EvButton'
import Tabs from './components/Tabs'
import gtmEvents from '@/helpers/constants/gtm-events'
import { TheNotificationCardList } from '@revelotech/everest'
import { copyToClipboard } from '@/helpers/clipboard'
import PublishedPositionsModal from './components/PublishedPositionsModal'

import { createNamespacedHelpers } from 'vuex'
import { CURRENT_USER, POSITIONS, COMPANY } from '@/store/namespaces'

const companyHelper = createNamespacedHelpers(COMPANY)
const positionsHelper = createNamespacedHelpers(POSITIONS)
const currentUserHelper = createNamespacedHelpers(CURRENT_USER)

export default {
  name: 'Positions',
  components: {
    EvButton,
    EvIcon,
    PublishedPositionsModal,
    Tabs
  },
  data () {
    return {
      langPath: __langpath,
      employersLoaded: false,
      pageLoaded: false,
      selectedEmployerId: null,
      selectedArchivedEmployerId: null,
      showModal: false
    }
  },
  computed: {
    ...companyHelper.mapState(['company']),
    ...positionsHelper.mapState([
      'positions',
      'activePositionsPage',
      'archivedPositionsPage',
      'shortlistPositionsPage',
      'publishedPositionsTotal',
      'sortBy'
    ]),
    ...currentUserHelper.mapState(['currentUser']),
    hasPublishedPositions () {
      return this.publishedPositionsTotal > 0
    },
    archivedSuccessMessage () {
      return this.$t(`${this.langPath}.archivedSuccess`)
    },
    archivedErrorMessage () {
      return this.$t(`${this.langPath}.archivedError`)
    },
    unarchivedSuccessMessage () {
      return this.$t(`${this.langPath}.unarchivedSuccess`)
    },
    unarchivedErrorMessage () {
      return this.$t(`${this.langPath}.unarchivedError`)
    }
  },
  async created () {
    this.pageLoaded = true
    this.$gtmTrackEvent(gtmEvents.accessedPositionsPage)
    this.employersLoaded = true

    this.trackGDTSignup()
  },
  methods: {
    ...positionsHelper.mapActions([
      'getEmployerPositionsNextPage', 'getActivePositions',
      'getArchivedPositions', 'archivePosition', 'unarchivePosition',
      'sortingFinished', 'getShortlistPositions'
    ]),
    nextActivePositionsPage () {
      this.getActivePositions({
        employerId: this.selectedEmployerId,
        currentPage: this.activePositionsPage,
        sortBy: this.sortBy
      })
    },
    nextArchivedPositionsPage () {
      this.getArchivedPositions({
        employerId: this.selectedEmployerId,
        currentPage: this.archivedPositionsPage
      })
    },
    nextEmployersPage () {
      this.getEmployerPositionsNextPage(this.currentUser.id)
    },
    closeModal () {
      this.showModal = false
    },
    openModal () {
      this.showModal = true
    },
    handleCopy (value) {
      if (copyToClipboard(value)) {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.successCopying`),
          modifier: 'success'
        })
      } else {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.errorCopying`),
          modifier: 'error'
        })
      }
    },
    async redirectToPositionCreate () {
      this.$smartlook('track', gtmEvents.clickedOnCreatePosition)

      this.$router.push({ name: 'PositionsCreate' })
    },
    trackGDTSignup () {
      const eventNotSent = !window.localStorage.getItem('gdt_signup')
      const gdtSignupOrigin = this.$route.query.gdt_signup

      if (gdtSignupOrigin && eventNotSent) {
        window.localStorage.setItem('gdt_signup', true)

        this.$gtmTrackEvent(gtmEvents.gdtSignUp)
      }
    },
    fetchArchivedPositions (employerId) {
      this.selectedArchivedEmployerId = employerId

      this.getArchivedPositions({ employerId: employerId || null })
    },
    fetchActivePositions (employerId) {
      this.selectedEmployerId = employerId
      this.getActivePositions({ employerId: employerId || null, sortBy: this.sortBy })
      this.getShortlistPositions({ employerId: employerId || null })
    },
    fetchShortlistPositions (employerId) {
      this.getShortlistPositions({ employerId: employerId || null })
    },
    fetchShortlistPositionsPage (employerId) {
      this.getShortlistPositions({
        employerId: employerId || null,
        currentPage: this.shortlistPositionsPage
      })
    },
    fetchEmployerShortlistPositions () {
      if (!this.currentUser.id) return null
      this.getShortlistPositions({ employerId: this.currentUser.id })
    },
    fetchEmployerShortlistPositionsPage () {
      if (!this.currentUser.id) return null
      this.getShortlistPositions({
        employerId: this.currentUser.id,
        currentPage: this.shortlistPositionsPage
      })
    },
    async handleArchivePosition (positionId) {
      const success = await this.archivePosition(positionId)
      this.createNotification(
        success ? this.archivedSuccessMessage : this.archivedErrorMessage,
        success
      )
    },
    async handleUnarchivePosition (positionId) {
      const success = await this.unarchivePosition(positionId)
      this.createNotification(
        success ? this.unarchivedSuccessMessage : this.unarchivedErrorMessage,
        success
      )
    },
    createNotification (message, isSuccess) {
      TheNotificationCardList.createNotification({
        message: message,
        modifier: isSuccess ? 'success' : 'error'
      })
    },
    async handleUpdateSortBy () {
      await this.getActivePositions({
        employerId: this.selectedEmployerId,
        currentPage: this.activePositionsPage,
        sortBy: this.sortBy
      })
      this.getShortlistPositions({
        employerId: this.selectedEmployerId,
        currentPage: this.shortlistPositionsPage
      })
      await this.getEmployerPositionsNextPage(this.currentUser.id)
      this.sortingFinished()
    }
  }
}
</script>

<style lang='scss'>
.positions {
  background-color: $bg-red-light-03;
  min-height: 100vh;

  &__wrapper {
    @extend %container;
  }

  &__new-position {
    margin-left: $base*5;
  }

  &__header {
    background-color: $white;

    @include breakpoint(md) {
      padding-top: $app-header-height;
    }

    &-wrapper {
      @extend %container;

      padding-bottom: 0;
    }
  }

  &__links {
    display: flex;
    justify-content: space-between;
    padding: $base*10 0 $base*8;
  }

  &__title { @extend %h3; }

  &__total {
    @extend %body-text2;

    color: $gray60;
    text-transform: uppercase;
  }

  &__employers {
    margin-left: $base*8;
    min-width: 15%;
  }

  &__more {
    text-align: right;

    button {
      display: inline-block;
    }
  }

  &__published-positions-box {
    display: flex;
    margin-left: auto;
  }

  &__published-positions-link {
    @extend %body-text2;

    align-self: center;
    color: $gray100;
    display: flex;
  }

  &__published-positions-icon {
    align-self: center;
    color: $gray60;
  }

  &__published-positions-label {
    margin-left: $base*2.5;
  }
}
</style>
