export default {
  getFilters ({ commit }) {
    let employerFilter = window.localStorage.getItem('gdt-employer-filter')
    if (employerFilter !== 'undefined') employerFilter = JSON.parse(employerFilter)

    const filter = window.localStorage.getItem('gdt-filter')

    commit('SET_EMPLOYER_FILTER', employerFilter)
    commit('SET_FILTER', filter || 'all_candidates')
  },
  async setEmployerFilter ({ commit }, employer) {
    window.localStorage.setItem('gdt-employer-filter', JSON.stringify(employer))
    commit('SET_EMPLOYER_FILTER', employer)
  },
  async setFilter ({ commit }, filter) {
    window.localStorage.setItem('gdt-filter', filter)
    commit('SET_FILTER', filter)
  },
  async setDefaultFilter ({ dispatch }) {
    dispatch('setFilter', { filter: 'all_candidates', employerFilter: {} })
  }
}
