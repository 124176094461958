import { get, put } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import { toSnakeCase } from '@/helpers/case-style'
import deepObjectLopper from '@/helpers/deep-object-mapper'

export default {
  async getShortlistCandidate (params) {
    const response = await get(`/shortlist/positions/${params.positionId}/candidate`, {
      position_id: params.positionId,
      global_candidate_id: params.globalCandidateId
    })

    return JSONAPIClient.parse(response.data)
  },

  async getShortlistCandidates (id) {
    const response = await get(`/shortlist/positions/${id}/candidates`)

    return JSONAPIClient.parse(response.data)
  },

  async getShortlistPosition (id) {
    const response = await get(`/shortlist/positions/${id}`)

    return JSONAPIClient.parse(response.data)
  },

  async reproveApplication (params) {
    const positionId = params.positionId

    const requestParams = {
      ...params,
      positionId: undefined
    }

    return put(`shortlist/positions/${positionId}/reprove`, {
      ...deepObjectLopper(requestParams, toSnakeCase)
    })
  },

  async shareShortlist (positionId) {
    return put(`shortlist/positions/${positionId}/share`)
  }
}
