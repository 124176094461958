export default {
  maxRetries: 1,
  defaultConfigs: {
    baseURL:
      process.env.VUE_APP_ATS_API_URL || 'https://api.ats.dev.revelo.io/',
    headers: {
      Accept: 'application/json'
    },
    withCredentials: true
  }
}
