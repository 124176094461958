<template>
  <div
    ref="dropdown"
    class="dropdown"
    tabindex="-1"
    @blur="hideMenu"
  >
    <a
      v-if="dots"
      class="dropdown__button dropdown__button--dots"
      tabIndex="0"
      @keyup.space="handleClick"
      @click="handleClick"
    >
      ...
    </a>
    <a
      v-else
      class="dropdown__button"
      tabIndex="0"
      @keyup.space="handleClick"
      @click="handleClick"
    >
      <slot name="title" />
    </a>
    <ul
      v-show="!menuHidden"
      class="menu"
      :class="[menuHidden, menuSideClass]"
      @mouseleave="hideMenu()"
    >
      <slot />
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AppDropdownMenu',
  props: {
    dots: {
      type: Boolean,
      default: false
    },
    menuSide: {
      type: String,
      default: 'left'
    }
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    menuHidden () {
      return this.show ? '' : 'dropdown__menu--hidden'
    },
    menuSideClass () {
      if (this.menuSide === 'right') {
        return 'menu--right'
      }
      return ''
    }
  },
  methods: {
    handleClick () {
      this.show = !this.show
      if (this.show) {
        this.$refs.dropdown.focus()
      }
    },
    hideMenu ($ev) {
      if ($ev && $ev.relatedTarget != null) {
        return
      }
      this.show = false
    }
  }
}
</script>

<style lang="scss">
.dropdown {
  background: transparent;
  cursor: pointer;
  display: inline-block;
  position: relative;
  vertical-align: middle;

  &__button {
    align-items: center;
    border: 0;
    display: flex;
    font: var(--tx-body);
    height: 9*$base;
    justify-content: space-between;
    overflow: hidden;
    padding: 0;
    user-select: none;

    &:focus {
      border: none;
      outline: $base auto -webkit-focus-ring-color;
    }

    &--dots {
      display: inline-block;
      font: var(--tx-title-4);
      vertical-align: text-top;
    }
  }
}

.menu {
  background: var(--bg-white);
  border: 1px solid var(--bg-gray20);
  padding: $base*2;
  position: absolute;
  z-index: 1;

  &--right {
    right: -1px;
  }

  &--hidden {
    display: none;
  }
}
</style>
