const defaultOptions = {
  theme: 'snow',
  bounds: document.body,
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: [] }, { background: [] }],
      ['clean'],
      ['link']
    ]
  },
  placeholder: '',
  readOnly: false
}

export { defaultOptions }
