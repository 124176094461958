export default {
  SET_SKILLS (state, skills) {
    const previousSkills = state.skills.filter((previousSkill) =>
      !skills.find((newSkill) => newSkill.id === previousSkill.id))

    state.skills = [
      ...previousSkills,
      ...skills
    ]
  },

  SET_IS_FETCHING_SKILLS (state, isFetchingSkills) {
    state.isFetchingSkills = isFetchingSkills
  }
}
