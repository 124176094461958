import { ROLLOUTS } from '@/store/namespaces'
import { createNamespacedHelpers } from 'vuex'

const rolloutsHelpers = createNamespacedHelpers(ROLLOUTS)

export default {
  computed: {
    ...rolloutsHelpers.mapState(['features'])
  },
  methods: {
    ...rolloutsHelpers.mapActions(['getFeature']),
    featureExists (featureName) {
      return this.features[featureName] !== undefined
    },
    async initFeatureData (featureName) {
      if (this.featureExists(featureName)) return

      await this.getFeature(featureName)
    },
    featureIsActive (featureName) {
      return this.features[featureName]
    }
  }
}
