export default {
  SET_EXTERNAL_CALENDARS (state, value) {
    state.externalCalendars = value
  },
  SET_EXTERNAL_EVENTS (state, value) {
    state.externalEvents = value
  },
  SET_IS_FETCHING_EXTERNAL_EVENTS (
    state, isFetchingExternalEvents
  ) {
    state.isFetchingExternalEvents = isFetchingExternalEvents
  },
  SET_SELECTED_CALENDARS (state, selectedCalendars) {
    state.externalCalendars = state.externalCalendars
      .map((externalCalendar) => ({
        ...externalCalendar,
        ...(selectedCalendars.find(
          (selectedCalendar) => selectedCalendar.calendarId ===
            externalCalendar.calendarId
        ))
      }))
  }
}
