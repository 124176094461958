import { post } from '@/helpers/request'
import { toSnakeCase } from '@/helpers/case-style'
import deepObjectLopper from '@/helpers/deep-object-mapper'
import { companiesUrl } from '@/helpers/constants/external-urls'

const baseUrl = `${companiesUrl}/api/v2`

export default {
  async getSearchUrl (params) {
    const postUrl = `${baseUrl}/companies/signup/search`
    const positionParams = { data: deepObjectLopper(params, toSnakeCase) }
    const response = await post(postUrl, positionParams)

    return response.data.data
  },

  failRequestAccess () {
    const postUrl = `${baseUrl}/companies/signup/fail/request_accesses`
    return post(postUrl, {})
  }
}
