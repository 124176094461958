import sharedCandidates from '@/repository/companies-app/shared-candidates'
import { CANDIDATES } from '@/store/namespaces'

export default {
  async getSharedCandidate ({ commit }, sharedCandidateToken) {
    const sharedCandidate = await sharedCandidates.getSharedCandidate(sharedCandidateToken)

    commit(`${CANDIDATES}/ADD_CANDIDATE`, sharedCandidate, { root: true })

    return sharedCandidate
  },

  async createSharedCandidate (___, { candidateId, message, emails }) {
    await sharedCandidates.createSharedCandidate(
      { candidateId, message, emails }
    )
  },

  async getSharedCandidateSubjects ({ commit }, sharedCandidateToken) {
    const subjects = await sharedCandidates
      .getSharedCandidateSubjects(sharedCandidateToken)
    commit('SET_SHARED_CANDIDATE_SUBJECTS', subjects)
  },

  async createExternalFeedback (___, feedback) {
    await sharedCandidates.createExternalFeedback(feedback)
  },

  async getEvaluationStatus ({ commit }, token) {
    const status = await sharedCandidates.getEvaluationStatus(token)
    commit('SET_SHARED_CANDIDATE_EVALUATION_STATUS', status)
  },

  async setEvaluationStatus ({ commit }, status) {
    commit('SET_SHARED_CANDIDATE_EVALUATION_STATUS', status)
  }
}
