export default {
  SET_INVITE (state, invite) {
    state.invite = invite
  },
  SET_IS_FETCHING_INVITE (state, isFetchingInvite) {
    state.isFetchingInvite = isFetchingInvite
  },
  SET_IS_CREATING_INVITE (state, isCreatingInvite) {
    state.isCreatingInvite = isCreatingInvite
  },
  SET_IS_UPDATING_INVITE (state, isUpdatingInvite) {
    state.isUpdatingInvite = isUpdatingInvite
  },
  SET_SOURCE (state, source) {
    state.source = source
  },
  SET_IS_FETCHING_INVITE_SUMMARY (state, isFetchingInviteSummary) {
    state.isFetchingInviteSummary = isFetchingInviteSummary
  },
  SET_IS_FETCHING_SIMILAR_CANDIDATES (state, isFetchingSimilarCandidates) {
    state.isFetchingSimilarCandidates = isFetchingSimilarCandidates
  },
  SET_SIMILAR_CANDIDATES (state, similarCandidates) {
    state.similarCandidates = similarCandidates.data.map((candidate) => ({
      id: candidate.id,
      ...candidate.attributes
    }))
  },
  SET_INVITE_SUMMARY (state, inviteSummary) {
    const invitedCandidate = inviteSummary.included.find((included) =>
      included.type === 'invited_candidate'
    )
    state.inviteSummary = {
      id: inviteSummary.id,
      ...inviteSummary.attributes,
      invitedCandidate: {
        id: invitedCandidate.id,
        ...invitedCandidate.attributes
      }
    }
  },
  SET_ERROR_FETCHING_SIMILAR_CANDIDATES (state, error) {
    state.errorFetchingSimilarCandidates = error
  },
  SET_INVITE_CREATED (state, inviteCreated) {
    state.inviteCreated = inviteCreated
  }
}
