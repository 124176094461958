import shortlistsRepo from '@/repository/shortlists'

export default {
  getShortlistCandidate (_store, params) {
    return shortlistsRepo.getShortlistCandidate(params)
  },
  getShortlistCandidates (_store, id) {
    return shortlistsRepo.getShortlistCandidates(id)
  },
  getShortlistPosition (_store, id) {
    return shortlistsRepo.getShortlistPosition(id)
  },
  reproveApplication (_store, params) {
    return shortlistsRepo.reproveApplication(params)
  },
  shareShortlist (_store, positionId) {
    return shortlistsRepo.shareShortlist(positionId)
  }
}
