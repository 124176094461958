export default {
  SET_FEEDBACKS (state, feedbacks) {
    state.feedbacks = feedbacks
  },

  ADD_FEEDBACK (state, feedback) {
    state.feedbacks.push(feedback)
  },

  SET_SUBJECTS (state, subjects) {
    state.subjects = subjects
  }
}
