<template>
  <li class="menu__item">
    <router-link
      v-if="linkTo"
      class="menu__link"
      :to="linkTo"
      @click="$emit('click')"
    >
      <slot />
    </router-link>
    <div
      v-else
      class="menu__link"
      @click.stop="$emit('click')"
    >
      <slot />
    </div>
  </li>
</template>

<script>
export default {
  name: 'AppItemMenu',
  props: {
    linkTo: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss">
.menu {
  &__item {
    font: var(--tx-body-small);
    min-width: 150px;
    text-align: left;
  }

  &__link {
    align-items: center;
    background: inherit;
    cursor: pointer;
    display: flex;
    padding: 2*$base 3*$base;
    white-space: nowrap;

    &:hover {
      background: var(--bg-gray5);
    }
  }
}
</style>
