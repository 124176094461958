import { get, post } from '@/helpers/request'
import jsonApiClient from '@/helpers/jsonapi-client'
import { companiesUrl } from '@/helpers/constants/external-urls'
import { toCamelCase, toSnakeCase } from '@/helpers/case-style'
import deepObjectLopper from '@/helpers/deep-object-mapper'

const baseUrl = `${companiesUrl}/api/v2/companies/invites`

export default {
  async getFeedbacks (inviteId) {
    const response = await get(`${baseUrl}/${inviteId}/feedbacks`)
    const parsedResponse = await jsonApiClient.parse(response.data)

    return deepObjectLopper(parsedResponse, toCamelCase)
  },

  async create (inviteId, interviewId, feedback) {
    const params = {
      data: {
        attributes: deepObjectLopper(feedback, toSnakeCase)
      }
    }

    const response = await post(
      `${baseUrl}/${inviteId}/feedbacks?interview_id=${interviewId}`,
      params
    )
    return response.data
  },

  async getFeedbackSubjects (inviteId, interviewId = null) {
    const feedbackUrl = `${baseUrl}/${inviteId}/feedbacks/subjects`
    const searchParams = interviewId ? `?interview_id=${interviewId}` : ''
    const endpointUrl = feedbackUrl + searchParams

    const response = await get(endpointUrl)
    return jsonApiClient.parse(response.data)
  }
}
