<template>
  <li
    ref="item"
    data-test="app-navigation-menu-item"
    class="app-navigation-menu-item"
    :class="{
      'app-navigation-menu-item--opened': opened,
      'app-navigation-menu-item--dropdown': hasDropdown && !noCaret,
      'app-navigation-menu-item--warning': warning
    }"
    tabindex="-1"
    @click="setOpen(!opened)"
    @blur="setOpen(false)"
    @mousedown.prevent
  >
    <slot />
    <div
      v-if="hasDropdown && opened"
      data-test="app-navigation-menu-item__dropdown"
      class="app-navigation-menu-item__dropdown"
    >
      <slot
        name="dropdown"
      />
    </div>
  </li>
</template>

<script>
export default {
  name: 'AppNavigationMenuItem',
  props: {
    hasDropdown: {
      type: Boolean,
      default: false
    },
    noCaret: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      opened: false
    }
  },
  methods: {
    setOpen (opt) {
      if (this.hasDropdown) {
        if (opt) {
          this.open()
        } else {
          this.close()
        }
      }
    },
    close () {
      this.opened = false
    },
    open () {
      this.opened = true
      this.$refs.item.focus()
    }
  }
}
</script>

<style lang="scss">
  .app-navigation-menu-item {
    @extend %button2;

    align-items: center;
    display: flex;
    height: 100%;
    padding: 2*$base 2*$base 1*$base;
    position: relative;
    text-transform: uppercase;

    & > * {
      @extend %button2;
      @extend %open-sans;

      align-items: center;
      color: $gray100-52;
      display: flex;
      padding: 2*$base;
    }

    &--dropdown {
      padding-right: $base*2;

      &::before {
        border-bottom: $base solid transparent;
        border-left: $base solid transparent;
        border-right: $base solid transparent;
        border-top: $base solid $gray40;
        content: '';
        display: block;
        height: 0;
        position: absolute;
        right: $base;
        top: 50%;
        width: 2px;
      }

      & > * {
        position: relative;
      }
    }

    &--opened {
      &::before {
        border-top-color: $gray100;
        z-index: 0;
      }

      & > * { color: $gray100; }
    }

    &--warning {
      background-color: $red-light;
    }

    &:hover {
      cursor: pointer;

      &::before { border-top-color: $gray100; }
      & > * { color: $gray100; }
    }

    &:last-child { margin-right: 0; }

    &__dropdown {
      background: $white;
      border: 1px solid $gray20;
      box-shadow: $shadow-8;
      display: block;
      height: auto;
      padding: $base*4;
      position: absolute;
      right: 0;
      top: 100%;
      width: 100%;
      z-index: 2;
    }

    @include breakpoint(lg) {
      & > * {
        padding: 2*$base;

        &:not(.app-navigation-menu-item__dropdown) { position: relative; }

        &.router-link-exact-active {
          color: $gray100;

          &::after {
            background: $red;
            bottom: -($base*3 + 3);
            content: '';
            display: block;
            height: $base;
            left: 0;
            position: absolute;
            width: 100%;
          }
        }
      }

      &__dropdown {
        min-width: 190px;
        right: -$base*4;
        width: auto;
      }
    }
  }
</style>
