export const numberFromSalary = (salary) => {
  return (salary && convertMoneyToNumber(salary)) || 0
}

export default numberFromSalary

const convertMoneyToNumber = (salary) => {
  const thousandFixed = salary.replace(/[^,\d]/g, '')

  return parseFloat(thousandFixed)
}
