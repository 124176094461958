export default {
  accessedPositionsPage: 'accessed_positions_page',
  clickedOnCandidateRecommended: 'clicked_on_candidate_recommended',
  clickedOnCreatePosition: 'clicked_on_create_position',
  companiesAccessing: 'companies_accessing',
  companiesAppEvent: 'companies_app_event',
  companyInviteProfile: 'company_invite_profile',
  companyProfileView: 'company_profile_view',
  companySignupRecurrence: 'company_signup_recurrence',
  companyTrialEndedByProfile: 'company_trial_ended_by_profile',
  employerAsksToCreateShortlist: 'company_asks_to_create_shortlist',
  gdtSignUp: 'GDT Sign Up'
}
