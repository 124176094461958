import Axios from 'axios'
import AxiosRetry from 'axios-retry'
import configs from '../configs/axios'

AxiosRetry(Axios, {
  retries: configs.maxRetries,
  retryDelay: AxiosRetry.exponentialDelay
})

async function request ({ method, url, params, data }) {
  const result = await Axios
    .request({ method, url, params, data, ...configs.defaultConfigs })
    .then((response) => { return response })
    .catch((error) => {
      if (error) {
        throw error
      } else {
        throw new Error('Server response null')
      }
    })

  return result
}

export const get = (url, params) => request({ method: 'get', url, params })
export const post = (url, data) => request({ method: 'post', url, data })
export const put = (url, data) => request({ method: 'put', url, data })
export const patch = (url, data) => request({ method: 'patch', url, data })
export const del = (url, params) => request({ method: 'delete', url, params })
export const head = (url, params) => request({ method: 'head', url, params })
