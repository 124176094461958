import stages from '@/repository/stages'
import applicants from '@/repository/applicants'
import candidates from '@/repository/candidates'

import proposals from '@/repository/proposals'

export default {
  async addExternalCandidate ({ commit }, { positionId, candidate, shortlistAttributes }) {
    const response = await candidates.addExternalCandidate({
      positionId,
      candidate,
      shortlistAttributes
    })

    commit('ADD_APPLICANT', { applicant: response, stageId: response.stageId })
  },
  async getStages ({ commit }, params) {
    const response = await stages.getStages(params.positionId)
    const responseWithApplicants = response.map((stage) => ({
      applicants: [],
      ...stage
    }))

    commit(
      'SET_STAGES',
      responseWithApplicants.sort(
        (stageA, stageB) => stageA.stageIndex - stageB.stageIndex
      )
    )
  },
  async getStage ({ commit }, { positionId, stageId, employerId }) {
    const response = await stages.getStage({ positionId, stageId, employerId })

    const stageResponse = response
    const stage = {
      id: stageResponse.id,
      label: stageResponse.label,
      default: stageResponse.default,
      stageIndex: stageResponse.stageIndex,
      applicants: stageResponse.applicants || []
    }

    commit('SET_STAGE', stage)
  },
  async getKinds ({ commit }) {
    const response = await stages.getKinds()

    commit('SET_KINDS', response)
  },
  async createStage ({ commit }, { positionId, label, kind }) {
    const stage = await stages.createStage({ positionId, label, kind })

    commit('ADD_STAGE', { ...stage, applicants: [] })
  },
  async updateStage ({ commit }, { id, label }) {
    await stages.updateStage({ id, label })

    commit('SET_STAGE', { id, label })
  },
  moveApplicant ({ commit, state }, { applicantId, fromStageId, toStageId }) {
    const stage = state.stages.find((stage) => {
      return stage.id === fromStageId
    })
    const applicant = stage.applicants?.find((applicantIterator) => {
      return applicantIterator.id === applicantId
    })

    commit('ADD_APPLICANT', { applicant, stageId: toStageId })
    commit('REMOVE_APPLICANT', {
      applicantId: applicantId,
      stageId: fromStageId
    })

    return applicants
      .moveApplicant({
        applicantId,
        stageId: toStageId
      })
      .catch((_e) => {
        commit('ADD_APPLICANT', { applicant, stageId: fromStageId })
        commit('REMOVE_APPLICANT', {
          applicantId: applicantId,
          stageId: toStageId
        })
      })
  },
  async reorderStages ({ commit }, { positionId, stagesList }) {
    await stages.reorderStages({ positionId, stagesList })

    commit('SET_STAGES', stagesList)
  },
  async deleteStage ({ commit }, stageId) {
    await stages.deleteStage(stageId)

    commit('REMOVE_STAGE', stageId)
  },
  moveApplicantNextStage ({ state, dispatch }, applicantId) {
    const fromStage = state.stages.find((stage) => {
      return stage.applicants.find((applicant) => applicant.id === applicantId)
    })

    const toStage = state.stages.find(
      (stage) => stage.stageIndex === fromStage.stageIndex + 1
    )

    if (!toStage) return

    return dispatch('moveApplicant', {
      applicantId,
      fromStageId: fromStage.id,
      toStageId: toStage.id
    })
  },
  async rejectCandidate (
    { commit, state },
    { applicantId, currentStageId, rejectionReason, rejectionMessage }
  ) {
    const stage = state.stages.find((stage) => {
      return stage.id === currentStageId
    })

    const applicant = stage.applicants.find((applicantIterator) => {
      return applicantIterator.id === applicantId
    })

    await applicants
      .rejectCandidate({
        applicantId,
        rejectionReason,
        rejectionMessage
      })
      .then((response) => {
        const newApplicant = {
          ...applicant,
          currentState: response.currentState,
          rejectionMessage: response.rejectionMessage,
          rejectionReason: response.rejectionReason
        }

        commit('REMOVE_APPLICANT', {
          applicantId: applicantId,
          stageId: currentStageId
        })

        commit('ADD_APPLICANT', {
          applicant: newApplicant,
          stageId: response.stageId
        })

        return response
      })
  },
  moveApplicantHired ({ state, dispatch }, applicantId) {
    const fromStage = state.stages.find((stage) => {
      return stage.applicants?.find((applicant) => applicant.id === applicantId)
    })

    const toStage = state.stages.find((stage) => stage.kind === 'hired')

    if (fromStage?.id === toStage?.id) return

    return dispatch('moveApplicant', {
      applicantId,
      fromStageId: fromStage.id,
      toStageId: toStage.id
    })
  },
  moveApplicantOffer ({ state, dispatch }, applicantId) {
    const fromStage = state.stages.find((stage) => {
      return stage.applicants.find((applicant) => applicant.id === applicantId)
    })

    const toStage = state.stages.find((stage) => stage.kind === 'offer')

    if (fromStage?.id === toStage?.id) return

    return dispatch('moveApplicant', {
      applicantId,
      fromStageId: fromStage.id,
      toStageId: toStage.id
    })
  },
  moveApplicantRefused ({ state, dispatch }, applicantId) {
    const fromStage = state.stages.find((stage) => {
      return stage.applicants.find((applicant) => applicant.id === applicantId)
    })

    const toStage = state.stages.find((stage) => stage.kind === 'refused')

    if (fromStage.id === toStage.id) return

    return dispatch('moveApplicant', {
      applicantId,
      fromStageId: fromStage.id,
      toStageId: toStage.id
    })
  },
  async createApplicantProposal (
    { commit, rootState },
    { applicantId, proposalParams }
  ) {
    await proposals.createProposal(applicantId, proposalParams)
    if (proposalParams.accepted) {
      commit('SET_PROPOSAL_ACCEPTED', applicantId)
      commit('SET_PROPOSAL', {
        applicantId,
        proposal: {
          onlyFormalized: true,
          jobSalary: proposalParams.jobSalary,
          jobStartDate: proposalParams.jobStartDate,
          createdByName: rootState.current_user?.currentUser?.name
        }
      })
    } else {
      commit('SET_PROPOSAL_SENT', applicantId)
    }
  },
  updateOrder ({ commit }, { stageId, applicantsOrder }) {
    applicants.updateOrder({
      stageId,
      applicantsOrder: applicantsOrder.map((applicantid, index) => ({
        id: applicantid,
        applicantIndex: index + 1
      }))
    })

    commit('SET_APPLICANTS_ORDER', { stageId, applicantsOrder })
  },
  async clearNotifications ({ commit }, { stageId, applicantId }) {
    await applicants.clearNotifications(applicantId)

    commit('SET_APPLICANT', {
      stageId,
      applicant: {
        id: applicantId,
        notificationCount: 0,
        newCandidate: false
      }
    })
  }
}
