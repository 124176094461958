import * as namespaces from './namespaces'
import notificationCards from './notifications-cards'
import applicants from './applicants'
import candidates from './candidates'
import comments from './comments'
import company from './company'
import employers from './companies-app/employers'
import externalCalendars from './external-calendars'
import feedbacks from './feedbacks'
import filters from './filters'
import interviews from './interviews'
import invites from './invites'
import inviteTemplates from './invite-templates'
import plans from './plans'
import positions from './positions'
import stages from './stages'
import jobPostings from './job-postings'
import notifications from './companies-app/notifications'
import currentUser from './companies-app/current-user'
import internalManager from './companies-app/internal-manager'
import rollouts from './companies-app/rollouts'
import companiesPositions from './companies-app/positions'
import nextTasks from './next-tasks'
import sharedCandidates from './shared-candidates'
import signup from './companies-app/signup'
import skills from './skills'
import shortlists from './shortlists'
import wishLists from './wish-lists'
import sharedShortlists from './shared-shortlists'

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    [namespaces.NOTIFICATION_CARDS]: {
      namespaced: true,
      ...notificationCards
    },
    [namespaces.APPLICANTS]: {
      namespaced: true,
      ...applicants
    },
    [namespaces.CANDIDATES]: {
      namespaced: true,
      ...candidates
    },
    [namespaces.COMMENTS]: {
      namespaced: true,
      ...comments
    },
    [namespaces.COMPANIES_POSITIONS]: {
      namespaced: true,
      ...companiesPositions
    },
    [namespaces.COMPANY]: {
      namespaced: true,
      ...company
    },
    [namespaces.CURRENT_USER]: {
      namespaced: true,
      ...currentUser
    },
    [namespaces.EMPLOYERS]: {
      namespaced: true,
      ...employers
    },
    [namespaces.EXTERNAL_CALENDARS]: {
      namespaced: true,
      ...externalCalendars
    },
    [namespaces.FEEDBACKS]: {
      namespaced: true,
      ...feedbacks
    },
    [namespaces.FILTERS]: {
      namespaced: true,
      ...filters
    },
    [namespaces.INTERVIEWS]: {
      namespaced: true,
      ...interviews
    },
    [namespaces.INVITES]: {
      namespaced: true,
      ...invites
    },
    [namespaces.INVITE_TEMPLATES]: {
      namespaced: true,
      ...inviteTemplates
    },
    [namespaces.INTERNAL_MANAGER]: {
      namespaced: true,
      ...internalManager
    },
    [namespaces.JOB_POSTINGS]: {
      namespaced: true,
      ...jobPostings
    },
    [namespaces.NOTIFICATIONS]: {
      namespaced: true,
      ...notifications
    },
    [namespaces.PLANS]: {
      namespaced: true,
      ...plans
    },
    [namespaces.POSITIONS]: {
      namespaced: true,
      ...positions
    },
    [namespaces.ROLLOUTS]: {
      namespaced: true,
      ...rollouts
    },
    [namespaces.STAGES]: {
      namespaced: true,
      ...stages
    },
    [namespaces.NEXT_TASKS]: {
      namespaced: true,
      ...nextTasks
    },
    [namespaces.SHARED_CANDIDATES]: {
      namespaced: true,
      ...sharedCandidates
    },
    [namespaces.SIGNUP]: {
      namespaced: true,
      ...signup
    },
    [namespaces.SKILLS]: {
      namespaced: true,
      ...skills
    },
    [namespaces.SHORTLISTS]: {
      namespaced: true,
      ...shortlists
    },
    [namespaces.WISH_LISTS]: {
      namespaced: true,
      ...wishLists
    },
    [namespaces.SHARED_SHORTLISTS]: {
      namespaced: true,
      ...sharedShortlists
    }
  }
})

export default store
