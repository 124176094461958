<template lang="html">
  <div class="insights" :class="showClass">
    <div class="insights-header">
      <icn-wand
        class="insights-header__icon insights-header__icon--display"
        view-box="0 0 45 45"
      />
      <h2 class="insights-header__title">
        {{ $t(`${langPath}.title`) }}
      </h2>
      <icn-arrow-right-black
        class="insights-header__icon insights-header__icon--close"
        @click="$emit('close')"
      />
    </div>
    <div class="insights-content">
      <app-loader v-if="isFetching" />
      <div v-else class="insights-body">
        <div class="insights-body__attractiveness">
          <h4>{{ $t(`${langPath}.score.title`) }}</h4>
          <div class="insights-body__progress-bar">
            <app-progress-bar
              :max="maxScore"
              :value="insights.percentageScore || 0"
              :color="scoreColor"
              size="medium"
            />
            <span
              class="insights-body__score-label"
              :class="scoreLabelModifier"
            >{{ insights.percentageScore }}%</span>
          </div>
          <p class="insights-body__description">{{ scoreDescription }}</p>
        </div>
        <ul class="insights-list">
          <li
            class="insights-list__item"
            :class="readingTimeModifier('insights-list__item')"
          >
            <p
              class="insights-list__title"
              :class="readingTimeModifier('insights-list__title')"
            >
              {{ $t(`${langPath}.readingTime.title`) }}
            </p>
            <p class="insights-list__description" data-test="reading-time">
              {{ readingTimeDescription }}
            </p>
          </li>
          <li
            class="insights-list__item"
            :class="textSizeModifier('insights-list__item')"
          >
            <p
              class="insights-list__title"
              :class="textSizeModifier('insights-list__title')"
            >
              {{ textSizeTitle }}
            </p>
            <p class="insights-list__description">{{ textSizeDescription }}</p>
          </li>
          <li
            class="insights-list__item"
            :class="readabilityModifier('insights-list__item')"
          >
            <p
              class="insights-list__title"
              :class="readabilityModifier('insights-list__title')"
            >
              {{ $t(`${langPath}.readability.title`) }}
            </p>
            <p class="insights-list__description">
              {{ readabilityDescription }}
            </p>
          </li>
          <li
            class="insights-list__item"
            :class="sentimentAnalyzeModifier('insights-list__item')"
          >
            <p
              class="insights-list__title"
              :class="sentimentAnalyzeModifier('insights-list__title')"
            >
              {{ $t(`${langPath}.sentimentAnalyze.title`) }}
            </p>
            <p class="insights-list__description">
              {{ sentimentAnalyzeDescription }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { IcnWand, IcnArrowRightBlack } from '@revelotech/everest'

export default {
  components: {
    IcnWand,
    IcnArrowRightBlack
  },
  props: {
    show: {
      type: Boolean,
      default: true
    },
    isFetching: {
      type: Boolean,
      required: true
    },
    insights: {
      type: Object,
      default: () => ({
        percentageScore: 0,
        readability: 0,
        sentimentAnalyze: '',
        textCharacterSize: 0,
        readingTime: 0,
        textSize: ''
      })
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    maxScore () {
      return 100
    },
    scoreColor () {
      if (!this.insights.percentageScore) {
        return 'default'
      }

      if (this.insights.percentageScore <= 25) {
        return 'red'
      } else if (this.insights.percentageScore <= 50) {
        return 'yellow'
      } else if (this.insights.percentageScore <= 75) {
        return 'light-blue'
      }

      return 'green'
    },
    scoreLabelModifier () {
      return `insights-body__score-label--${this.scoreColor}`
    },
    easyToRead () {
      return this.insights.readability > 4.0 && this.insights.readability < 14.9
    },
    sentimentAnalyzeState () {
      let state = 'warning'

      if (this.insights.sentimentAnalyze === 'positive') {
        state = 'success'
      } else if (this.insights.sentimentAnalyze === 'negative') {
        state = 'error'
      }

      return state
    },
    scoreDescription () {
      const state = this.insights.percentageScore ? 'default' : 'empty'

      return this.$t(`${this.langPath}.score.description.${state}`)
    },
    readingTimeDescription () {
      if (!this.insights.textCharacterSize) {
        return this.$t(`${this.langPath}.readingTime.description.empty`)
      }

      return this.$t(
        `${this.langPath}.readingTime.description.default`,
        {
          time: this.$moment.duration(
            this.insights.readingTime, 'seconds'
          ).humanize()
        }
      )
    },
    textSizeTitle () {
      if (!this.insights.textSize || !this.insights.textCharacterSize) {
        return this.$t(`${this.langPath}.textSize.title.empty`)
      }

      return this.$t(
        `${this.langPath}.textSize.title.default`,
        { size: this.insights.textCharacterSize }
      )
    },
    textSizeDescription () {
      if (!this.insights.textSize || !this.insights.textCharacterSize) {
        return this.$t(`${this.langPath}.textSize.description.empty`)
      }

      return this.$t(
        `${this.langPath}.textSize.description.${this.insights.textSize}Text`
      )
    },
    readabilityDescription () {
      if (!this.insights.readability) {
        return this.$t(`${this.langPath}.readability.description.empty`)
      }

      const state = this.easyToRead ? 'success' : 'error'

      return this.$t(`${this.langPath}.readability.description.${state}`)
    },
    sentimentAnalyzeDescription () {
      if (!this.insights.sentimentAnalyze) {
        return this.$t(`${this.langPath}.sentimentAnalyze.description.empty`)
      }

      const state = this.sentimentAnalyzeState

      return this.$t(`${this.langPath}.sentimentAnalyze.description.${state}`)
    },
    showClass () {
      if (!this.show) {
        return 'insights--hidden'
      }

      return ''
    }
  },
  methods: {
    readingTimeModifier (baseClass) {
      if (!this.insights.textCharacterSize) {
        return ''
      }

      return `${baseClass}--success`
    },
    textSizeModifier (baseClass) {
      if (!this.insights.textSize || !this.insights.textCharacterSize) {
        return ''
      }

      const modifier = this.insights.textSize === 'ok' ? 'success' : 'error'

      return `${baseClass}--${modifier}`
    },
    readabilityModifier (baseClass) {
      if (!this.insights.readability) {
        return ''
      }

      const modifier = this.easyToRead ? 'success' : 'warning'

      return `${baseClass}--${modifier}`
    },
    sentimentAnalyzeModifier (baseClass) {
      if (!this.insights.sentimentAnalyze) {
        return ''
      }

      return `${baseClass}--${this.sentimentAnalyzeState}`
    }
  }
}
</script>

<style lang="scss">
.insights {
  background-color: var(--bg-neutral-blank);
  border: 1px solid var(--bg-neutral-light-04);
  border-right: 0;
  opacity: 1;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  transition: right 0.5s ease, opacity 0.5s ease;
  width: 336px;

  &--hidden {
    opacity: 0;
    right: 0%;
  }

  .insights-header {
    align-items: center;
    background-color: var(--bg-neutral-light-04);
    border-bottom: 1px solid var(--b-neutral-light-04);
    display: flex;
    padding: 8px;

    &__icon {
      height: 32px;
      padding: 4px;
      width: 32px;

      &--display {
        border: 1px solid var(--bg-neutral-light-04);
        margin-right: 8px;
      }

      &--close {
        cursor: pointer;
        height: 24px;
        width: 24px;
      }
    }

    &__title {
      @extend %subtitle1;

      flex-grow: 1;
    }
  }

  .insights-body {
    padding: 16px 20px;

    &__attractiveness {
      border-bottom: 1px solid var(--b-neutral-light-04);
      padding-bottom: 20px;
    }

    &__progress-bar {
      align-items: center;
      display: flex;
      margin: 8px 0;
    }

    &__score-label {
      @include margin(left, 2);

      &--red {
        color: var(--tx-red-light-01);
      }

      &--yellow {
        color: var(--tx-warning);
      }

      &--light-blue {
        color: var(--tx-blue);
      }

      &--green {
        color: var(--tx-success);
      }
    }

    &__description {
      @extend %caption;

      color: var(--tx-neutral-light-01);
    }
  }

  .insights-list {
    padding-top: 20px;

    &__item {
      margin-left: 32px;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 2 * $base;
      }

      &::after {
        background-attachment: center;
        background-color: var(--bg-neutral-light-04);
        background-position: center;
        background-repeat: no-repeat;
        color: var(--tx-neutral-blank);
        content: '';
        display: inline-block;
        height: 20px;
        left: -32px;
        position: absolute;
        top: 4px;
        width: 20px;
      }

      &--success {
        &::after {
          background: {
            color: var(--tx-blue);
            image: url(
              '../../../assets/images/icn-success-strip.svg'
            );
          }
        }
      }

      &--warning {
        &::after {
          background: {
            color: var(--tx-warning);
            image: url(
              '../../../assets/images/icn-warning-strip.svg'
            );
          }
        }
      }

      &--error {
        &::after {
          background: {
            color: var(--tx-red-light-01);
            image: url(
              '../../../assets/images/icn-error-strip.svg'
            );
          }
        }
      }
    }

    &__title {
      @extend %subtitle2;

      &--success {
        color: var(--tx-blue);
      }

      &--warning {
        color: var(--tx-warning);
      }

      &--error {
        color: var(--tx-red-light-01);
      }
    }

    &__description {
      @extend %body-text2;
    }
  }
}
</style>
