<template>
  <button
    :class="{ 'app-arrow-button': true, 'app-arrow-button--disabled': disabled }"
    @click="handleClick"
  >
    <ev-icon :name="iconName" color="white" data-test="arrow-icon" />
  </button>
</template>

<script>
import EvIcon from '@revelotech/everestV2/EvIcon'

export default {
  name: 'AppArrowButton',
  components: {
    EvIcon
  },
  props: {
    direction: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconName () {
      return `angle-${this.direction}-b`
    }
  },
  methods: {
    handleClick () {
      if (!this.disabled) this.$emit('click')
    }
  }
}
</script>

<style lang="scss">
.app-arrow-button {
  background-color: var(--bg-primary);
  border: solid 1px var(--b-red);
  border-radius: 50%;
  height: $base*8;
  padding: 1px 3px 0;
  width: $base*8;

  &:hover {
    background-color: var(--bg-primary-hover);
  }

  &:hover:active {
    border-color: var(--bg-white);
  }

  &--disabled {
    background-color: var(--bg-primary-disabled);
    border: solid 1px var(--bg-white);

    &:hover {
      background-color: var(--bg-primary-disabled);
    }
  }
}
</style>
