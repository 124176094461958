<template>
  <section class="empty-state">
    <h1 class="empty-state__title" data-test="title">{{ title }}</h1>

    <p v-if="message" class="empty-state__message" data-test="message">{{ message }}</p>

    <div
      v-if="hasCallToAction"
      class="empty-state__call-to-action"
    >
      <ev-button
        data-test="call-to-action"
        @click="emitCallToActionEvent"
      >
        {{ callToActionText }}
      </ev-button>
    </div>
  </section>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'

export default {
  name: 'EmptyState',
  components: { EvButton },
  props: {
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      default: ''
    },
    callToActionText: {
      type: String,
      required: false,
      default: ''
    },
    callToActionEventName: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    hasCallToAction () {
      return this.callToActionText.length > 0 &&
        this.callToActionEventName.length > 0
    }
  },
  methods: {
    emitCallToActionEvent () {
      this.$emit(this.callToActionEventName)
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-state {
  align-items: flex-start;
  background-color: var(--bg-white);
  display: flex;
  flex-direction: column;
  margin-bottom: $base*10;
  margin-top: $base*4;
  padding: $base*10 $base*6;

  &__title {
    @extend %h4;
  }

  &__message {
    @extend %body-text2;
  }

  &__call-to-action {
    @include margin(top, 6);
  }
}
</style>
