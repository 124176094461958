import Vue from 'vue'

Vue.prototype.$identify = function (companyId, values) {
  window.smartlook('identify', companyId, values)
  if (window.Appcues) window.Appcues.identify(companyId, values)
  if (this.$gtm) {
    this.$gtm.trackEvent({
      event: values.eventName || 'accessed_gdt',
      gdtCompanyId: companyId,
      ...values
    })
  }
}

Vue.prototype.$appcuesTrackEvent = function (eventName) {
  if (window.Appcues) {
    window.Appcues.track(eventName)
  }
}

Vue.prototype.$gtmTrackEvent = function (eventAction, values) {
  if (this.$gtm) {
    this.$gtm.trackEvent({
      event: 'gdt_app_event',
      event_action: eventAction,
      ...values
    })
  }
}

Vue.prototype.$smartlook = window.smartlook
