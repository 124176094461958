import { get } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import { companiesUrl } from '@/helpers/constants/external-urls'

export default {
  async getDetails () {
    const response = await get(`${companiesUrl}/api/v2/ats/internal_manager`)
    if (response.data?.data?.attributes) return JSONAPIClient.parse(response.data)

    return {}
  }
}
