<template>
  <v-time-picker
    v-model="internalValue"
    :min="minTime"
    :max="maxTime"
    :dark="dark"
    :no-title="noTitle"
    :full-width="fullWidth"
    :class="`v-time-picker ${shadowClass}`"
    :color="colorHex"
    :header-color="headerColorHex"
    format="24hr"
    data-test="time-picker"
  >
    <section
      v-if="withButtons"
      class="v-time-picker__buttons-footer"
    >
      <ev-button
        variant="tertiary"
        color="blue"
        data-test="time-picker-button-cancel"
        @click="$emit('cancel')"
      >
        {{ $t(`${langPath}.cancelLabel`) }}
      </ev-button>

      <ev-button
        variant="tertiary"
        color="blue"
        data-test="time-picker-button-ok"
        @click="$emit('input', internalValue)"
      >
        {{ $t(`${langPath}.okLabel`) }}
      </ev-button>
    </section>
  </v-time-picker>
</template>

<script>
import { styleTokens } from '@revelotech/everest'
import EvButton from '@revelotech/everestV2/EvButton'

const COLOR_OPTIONS = {
  primary: styleTokens.red,
  secondary: styleTokens.gray100
}

export default {
  name: 'AppTimePicker',
  components: {
    EvButton
  },
  props: {
    value: {
      type: String,
      required: true
    },
    minTime: {
      type: String,
      default: ''
    },
    maxTime: {
      type: String,
      default: ''
    },
    dark: {
      type: Boolean,
      default: false
    },
    noTitle: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: true
    },
    noShadow: {
      type: Boolean,
      default: false
    },
    withButtons: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'secondary'].includes(value)
      }
    },
    headerColor: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'secondary'].includes(value)
      }
    }
  },
  data () {
    return {
      langPath: __langpath,
      internalValue: this.value
    }
  },
  computed: {
    colorHex () {
      return COLOR_OPTIONS[this.color]
    },
    headerColorHex () {
      return COLOR_OPTIONS[this.headerColor]
    },
    shadowClass () {
      return this.noShadow ? 'v-time-picker--no-box-shadow' : ''
    }
  }
}
</script>

<style lang="scss">
.v-time-picker {
  width: 100%;

  &__buttons-footer {
    border: none;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &--no-box-shadow {
    box-shadow: none !important;
  }

  .theme--light.v-time-picker-clock {
    @extend %caption;

    background-color: $gray5 !important;
    color: $gray100;
  }

  .theme--light.v-time-picker-clock--indeterminate {
    .v-time-picker-clock__hand {
      background-color: $gray40 !important;
    }

    .v-time-picker-clock__item--active {
      background-color: $gray40 !important;
    }
  }

  .v-picker {
    height: auto !important;
    padding: 0 !important;
  }

  .v-picker__title {
    @extend %h6;
  }
}
</style>
