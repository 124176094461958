import { get } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import { companiesUrl } from '@/helpers/constants/external-urls'

const baseUrl = `${companiesUrl}/api/v2/company_plans`

export default {
  async getCurrentPlan () {
    const response = await get(
      baseUrl
    )

    return JSONAPIClient.parse(response.data)
  },
  async getLastPlan () {
    const response = await get(
      `${baseUrl}/last_company_plan`
    )

    return JSONAPIClient.parse(response.data)
  }
}
