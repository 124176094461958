import repository from '@/repository/companies-app/feedbacks'

export default {
  async getFeedbacks ({ commit }, payload) {
    const feedbacks = await repository.getFeedbacks(payload.inviteId)

    commit('SET_FEEDBACKS', feedbacks)
  },

  async createFeedback ({ dispatch }, payload) {
    await repository.create(
      payload.inviteId,
      payload.interviewId,
      payload.feedback
    )

    return dispatch('getFeedbacks', payload)
  },

  async getFeedbackSubjects ({ commit }, payload) {
    const subjects = await repository.getFeedbackSubjects(
      payload.inviteId, payload.interviewId
    )

    commit('SET_SUBJECTS', subjects)
  }
}
