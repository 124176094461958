import { CURRENT_USER } from '@/store/namespaces'

const addEvaluationToGroup = ({ group, evaluation, fromUser }) => {
  group[evaluation.name] = [
    ...(group[evaluation.name] || []),
    { ...evaluation, fromUser }
  ]
}

export default {
  currentUserFeedback (state, __g, rootState) {
    return state.feedbacks?.find((feedback) =>
      feedback.fromUser.id === rootState[CURRENT_USER].currentUser.id)
  },

  profileEvaluationsGroupedByName: (state) => {
    const groupedProfileEvaluations = {}

    for (const feedback of state.feedbacks) {
      for (const profileEvaluation of feedback.profileEvaluations) {
        addEvaluationToGroup({
          group: groupedProfileEvaluations,
          evaluation: profileEvaluation,
          fromUser: feedback.fromUser
        })
      }
    }

    return groupedProfileEvaluations
  },

  skillEvaluationsGroupedByName: (state) => {
    const groupedSkillEvaluations = {}

    for (const feedback of state.feedbacks) {
      for (const skillEvaluation of feedback.skillEvaluations) {
        addEvaluationToGroup({
          group: groupedSkillEvaluations,
          evaluation: skillEvaluation,
          fromUser: feedback.fromUser
        })
      }
    }

    return groupedSkillEvaluations
  },

  averageScore: (state) => {
    if (!state.feedbacks.length) return 0

    const totalScore = state.feedbacks.reduce((total, value) => (total + value.score), 0)
    return totalScore / state.feedbacks.length
  }
}
