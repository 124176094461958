import emailsOfDomain from './emails-of-domain'
import emailsRules from './emails-rules'
import location from './location'
import moneyRequired from './money-required'
import moneyMin from './money-min'
import moneyMax from './money-max'
import salaryRange from './salary-range'

export default {
  extendRules (validator) {
    validator.extend('emails_rules', emailsRules)
    validator.extend('location', location)
    validator.extend('money_required', moneyRequired)
    validator.extend('money_min', moneyMin)
    validator.extend('money_max', moneyMax)
    validator.extend('emails_of_domain', emailsOfDomain)
    validator.extend('salary_range', salaryRange)
  }
}
