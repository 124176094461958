export default {
  SET_CURRENT_PLAN (state, currentPlan) {
    state.currentPlan = currentPlan
  },

  SET_IS_LOADING_CURRENT_PLAN (state, isLoading) {
    state.isLoadingCurrentPlan = isLoading
  },

  SET_LAST_PLAN (state, currentPlan) {
    state.lastPlan = currentPlan
  },

  SET_IS_LOADING_LAST_PLAN (state, isLoading) {
    state.isLoadinglastPlan = isLoading
  }
}
