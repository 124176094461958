import sharedShortlistsRepo from '@/repository/shared-shortlists'

export default {
  getSharedShortlist (_store, token) {
    return sharedShortlistsRepo.getSharedShortlist(token)
  },

  getSharedShortlistCandidates (_store, token) {
    return sharedShortlistsRepo.getSharedShortlistCandidates(token)
  }
}
