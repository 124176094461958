<template>
  <article class="shortlist-card">
    <div class="shortlist-card__container">
      <header class="shortlist-card__header">
        <figure v-if="candidate.photoUrl" class="shortlist-card__avatar">
          <img
            class="shortlist-card__avatar-image"
            :src="candidate.photoUrl"
            :alt="$t('pages.ShortlistCard.avatarUrlAlt')"
            data-test="candidate-avatar"
          >
        </figure>

        <div>
          <h3
            class="shortlist-card__name"
            data-test="candidate-name"
          >
            {{ candidate.name }}
          </h3>

          <h4
            class="shortlist-card__career"
            data-test="career-name"
          >
            {{ candidate.careerName }}
          </h4>
        </div>
      </header>

      <div class="shortlist-card__social-links">
        <a
          v-if="candidate.linkedin"
          :href="candidate.linkedin"
          target="_blank"
          data-test="linkedin-link"
        >
          <ev-icon
            name="linkedin"
            color="blue"
          />
        </a>

        <a
          v-if="candidate.cvUrl"
          :href="candidate.cvUrl"
          target="_blank"
          data-test="cv-link"
        >
          <ev-icon
            name="file-info-alt"
            color="blue"
          />
        </a>
      </div>

      <div class="shortlist-card__skills">
        <h4 class="shortlist-card__section-title shortlist-card__skills-title">
          {{ $t('pages.ShortlistCard.skillsTitle') }}
        </h4>

        <ul class="shortlist-card__skills-list">
          <li
            v-for="skill in candidateSkills"
            :key="skill.id"
            class="shortlist-card__skills-item"
          >
            <ev-badge
              :title="skill.skillLabel"
              variant="red-light"
              data-test="skill-badge"
            />
          </li>
        </ul>
      </div>

      <div class="shortlist-card__description">
        <h4 class="shortlist-card__section-title shortlist-card__description-title">
          {{ $t('pages.ShortlistCard.descriptionTitle') }}
        </h4>

        <p
          class="shortlist-card__description-text"
          data-test="description"
        >
          {{ candidate.applications && candidate.applications[0].comment }}
        </p>

        <ev-button
          class="shortlist-card__description-button"
          variant="tertiary"
          size="small"
          data-test="see-more-button"
          target="_blank"
          no-padding
          @click="toggleResumeModal"
        >
          {{ $t('pages.ShortlistCard.seeMoreButton') }}
        </ev-button>
      </div>

      <div class="shortlist-card__salary">
        <h4 class="shortlist-card__section-title shortlist-card__salary-title">
          {{ $t('pages.ShortlistCard.salaryTitle') }}
        </h4>

        <p
          class="shortlist-card__salary-text"
          data-test="salary-pretension"
        >
          {{ $n(candidate.applications && candidate.applications[0].negotiatedSalary, 'currency') }}
        </p>
      </div>

      <div
        v-if="candidate.candidatesAppId"
        class="shortlist-card__profile-completed"
      >
        <ev-router-link
          color="red"
          variant="secondary"
          size="small"
          data-test="profile-completed-button"
          target="_blank"
          :route="profileRoute"
        >
          {{ $t('pages.ShortlistCard.profileCompletedButton') }}
        </ev-router-link>
      </div>

      <footer class="shortlist-card__footer">
        <div class="shortlist-card__footer-container">
          <div class="shortlist-card__footer-disclaimer">
            <p class="shortlist-card__footer-disclaimer-text">
              {{ $t('pages.ShortlistCard.scheduleMeeting') }}
            </p>
          </div>

          <div class="shortlist-card__footer-actions">
            <ev-button
              class="shortlist-card__footer-action
              shortlist-card__footer-action--reject"
              size="small"
              data-test="reject-button"
              @click="toggleRejectModal"
            >
              <ev-icon
                name="times"
                color="white"
              />
            </ev-button>

            <ev-button
              class="shortlist-card__footer-action
              shortlist-card__footer-action--approve"
              size="small"
              data-test="approve-button"
              :loading="isSending"
              @click="handleApproveClick"
            >
              <ev-icon
                name="check"
                color="white"
              />
            </ev-button>
          </div>
        </div>
      </footer>
    </div>

    <shortlist-card-reject-modal
      v-show="showRejectModal"
      :candidate="candidate"
      @close="toggleRejectModal"
      @rejected="removeCandidate"
      :shortlist="shortlist"
      data-test="reject-modal"
    />

    <shortlist-card-approve-modal
      v-if="showApproveModal"
      :candidate="candidate"
      :shortlist="shortlist"
      @success="removeCandidate"
      @close="toggleApproveModal"
      data-test="approve-modal"
    />

    <shortlist-card-resume-modal
      v-show="showResumeModal"
      :candidate="candidate"
      @close="toggleResumeModal"
      data-test="resume-modal"
    />
  </article>
</template>

<script>
import EvBadge from '@revelotech/everestV2/EvBadge'
import EvButton from '@revelotech/everestV2/EvButton'
import EvRouterLink from '@revelotech/everestV2/EvRouterLink'
import EvIcon from '@revelotech/everestV2/EvIcon'
import ShortlistCardApproveModal from './ShortlistCardApproveModal.vue'
import ShortlistCardRejectModal from './ShortlistCardRejectModal.vue'
import ShortlistCardResumeModal from './ShortlistCardResumeModal.vue'
import { TheNotificationCardList } from '@revelotech/everest'
import { createNamespacedHelpers } from 'vuex'
import { STAGES } from '@/store/namespaces'

const stagesHelper = createNamespacedHelpers(STAGES)

export default {
  name: 'ShortlistCard',

  components: {
    EvBadge,
    EvButton,
    EvIcon,
    EvRouterLink,
    ShortlistCardApproveModal,
    ShortlistCardRejectModal,
    ShortlistCardResumeModal
  },

  props: {
    candidate: {
      type: Object,
      required: true
    },
    shortlist: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      showRejectModal: false,
      showApproveModal: false,
      showResumeModal: false,
      isSending: false
    }
  },

  computed: {
    profileRoute () {
      return {
        name: 'CandidateProfile',
        params: {
          id: this.candidate.candidatesAppId
        },
        query: {
          source: 'shortlist',
          talentPositionId: this.shortlist.id,
          globalCandidateId: this.candidate.globalCandidateId,
          fixedPositionId: this.shortlist.companiesPositionId,
          serviceLevel: this.shortlist.serviceLevel,
          positionTitle: this.shortlist.title,
          atsPositionId: this.shortlist.atsPositionId
        }
      }
    },
    candidateSkills () {
      return this.candidate.candidateSkills
        .filter((skill) => skill.isPrimary)
        .map((skill) => ({
          ...skill,
          skillLabel: skill.experienceTime
            ? `${skill.name} ${skill.experienceTime}A`
            : skill.name
        }))
    }
  },

  methods: {
    ...stagesHelper.mapActions(['addExternalCandidate']),

    toggleApproveModal () {
      this.showApproveModal = !this.showApproveModal
    },

    toggleRejectModal () {
      this.showRejectModal = !this.showRejectModal
    },

    toggleResumeModal () {
      this.showResumeModal = !this.showResumeModal
    },

    removeCandidate () {
      this.$emit('remove', this.candidate.id)
    },

    handleApproveClick () {
      if (this.shortlist.serviceLevel === 'sourcing_only') {
        this.toggleApproveModal()
      } else {
        this.approveExternalCandidate()
      }
    },

    async approveExternalCandidate () {
      this.isSending = true

      try {
        await this.addExternalCandidate({
          positionId: this.shortlist.atsPositionId,
          candidate: {
            name: this.candidate.name,
            email: this.candidate.email,
            phone: this.candidate.phone,
            linkedinUrl: this.candidate.linkedin,
            source: 'shortlist'
          },
          shortlistAttributes: {
            globalCandidateId: this.candidate.globalCandidateId,
            talentPositionId: this.shortlist.id
          }
        })
        this.removeCandidate()
      } catch {
        TheNotificationCardList.createNotification({
          message: this.$t('pages.ShortlistCard.addError'),
          modifier: 'error'
        })
      } finally {
        this.isSending = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@revelotech/everestV2/tx_overline.scss";

.shortlist-card {
  background-color: $white;
  border-radius: $b-radius;
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: $base * 6;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-flow: nowrap;
    margin-bottom: $base * 8;
  }

  &__avatar {
    margin-right: $base * 6;

    &-image {
      border-radius: 50%;
      height: 48px;
      width: 48px;
    }
  }

  &__name {
    font: $tx-title-3;
  }

  &__career {
    @extend %tx-overline;

    margin-top: $base;
  }

  &__section {
    &-title {
      @extend %tx-overline;

      margin-bottom: $base * 2;
    }
  }

  &__skills {
    &-list {
      display: flex;
      flex-flow: wrap;
      gap: $base * 2;
    }
  }

  &__description {
    margin-top: $base * 8;

    &-text {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      font: $tx-body-small;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-button {
      margin-top: $base;
    }
  }

  &__salary {
    margin-top: $base * 4;

    &-text {
      font: $tx-body-small;
    }
  }

  &__profile-completed {
    display: flex;
    margin-top: $base * 7;
  }

  &__footer {
    border-top: 1px solid $b-gray5;
    margin-top: $base * 6;
    padding-top: $base * 5;

    &-container {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &-disclaimer {
      &-text {
        font: $tx-caption;
      }
    }

    &-actions {
      display: flex;
      gap: $base * 4;
    }
  }

  &__social-links {
    display: flex;
    flex-direction: row;
    gap: $base * 2;
    margin-bottom: $base * 2;
  }
}
</style>
