<template>
  <ev-modal
    size="md"
    @close="$emit('close')"
  >
    <invites-new
      v-if="companiesCandidate"
      variant="inverted"
      :candidate="companiesCandidate"
      :show-title="true"
      :success-redirect="false"
      :fixed-position-id="shortlist.companiesPositionId"
      :invite-form-data="additionalInviteData"
      data-test="invites-new"
      @success="onSuccess"
    />
  </ev-modal>
</template>

<script>
import EvModal from '@revelotech/everestV2/EvModal'
import InvitesNew from '@/pages/Invites/InvitesNew'
import { createNamespacedHelpers } from 'vuex'

const candidatesHelper = createNamespacedHelpers('candidates')

export default {
  name: 'ShortlistCardApproveModal',

  components: {
    EvModal,
    InvitesNew
  },

  props: {
    candidate: {
      type: Object,
      required: true
    },
    shortlist: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...candidatesHelper.mapGetters(['getCandidateById']),
    companiesCandidate () {
      return this.getCandidateById(this.candidate.candidatesAppId)
    },
    additionalInviteData () {
      return {
        talentPositionId: this.shortlist.id,
        globalCandidateId: this.candidate.globalCandidateId
      }
    }
  },

  created () {
    this.getCandidate({ candidateId: this.candidate.candidatesAppId })
  },

  methods: {
    ...candidatesHelper.mapActions(['getCandidate']),
    onSuccess () {
      this.$emit('success')
      this.$emit('close')
    }
  }
}
</script>

<style>

</style>
