export default {
  SET_APPLICANT (state, value) {
    state.applicant = value
  },
  SET_APPLICANT_ID (state, value) {
    state.applicantId = value
  },
  SET_INTERVIEWS (state, value) {
    state.interviews = value
  },
  ADD_INTERVIEW (state, interview) {
    state.interviews.push(interview)
  },
  REMOVE_INTERVIEW (state, value) {
    state.interviews = state.interviews.filter(
      (interview) => interview.id !== value
    )
  },
  SET_LOADING_APPLICANTS (state, loading) {
    state.loadingApplicants = loading
  },
  SET_LOADING_MORE_APPLICANTS (state, loading) {
    state.loadingMoreApplicants = loading
  },
  SET_APPLICANTS_WITH_UNSCHEDULED_INTERVIEW (state, applicants) {
    state.applicantsWithUnscheduledInterview = applicants
  },
  ADD_APPLICANTS_WITH_UNSCHEDULED_INTERVIEW (state, applicants) {
    state.applicantsWithUnscheduledInterview =
      [...state.applicantsWithUnscheduledInterview, ...applicants]
  },
  SET_APPLICANTS_WITH_UNSCHEDULED_INTERVIEW_PAGE (state, page) {
    state.applicantsWithUnscheduledInterviewPage = page
  },
  SET_APPLICANTS_WITH_UNSCHEDULED_INTERVIEW_TOTAL_PAGES (state, totalPages) {
    state.applicantsWithUnscheduledInterviewTotalPages = totalPages
  },
  SET_APPLICANTS_WITH_UNSCHEDULED_INTERVIEW_TOTAL_ENTRIES (state, totalEntries) {
    state.applicantsWithUnscheduledInterviewTotalEntries = totalEntries
  },
  SET_APPLICANTS_WITH_CONDUCTED_INTERVIEW (state, applicants) {
    state.applicantsWithConductedInterview = applicants
  },
  ADD_APPLICANTS_WITH_CONDUCTED_INTERVIEW (state, applicants) {
    state.applicantsWithConductedInterview =
      [...state.applicantsWithConductedInterview, ...applicants]
  },
  REMOVE_APPLICANT_WITH_UNSCHEDULED_INTERVIEW (state, applicantId) {
    state.applicantsWithUnscheduledInterview =
      state.applicantsWithUnscheduledInterview.filter((applicant) => {
        return applicant.id !== applicantId
      })
  },
  REMOVE_APPLICANT_WITH_CONDUCTED_INTERVIEW (state, applicantId) {
    state.applicantsWithConductedInterview =
      state.applicantsWithConductedInterview.filter((applicant) => {
        return applicant.id !== applicantId
      })
  },
  REMOVE_APPLICANT_WITH_SENT_PROPOSAL (state, applicantId) {
    state.applicantsWithSentProposal =
      state.applicantsWithSentProposal.filter((applicant) => {
        return applicant.id !== applicantId
      })
  },
  SET_APPLICANTS_WITH_CONDUCTED_INTERVIEW_PAGE (state, page) {
    state.applicantsWithConductedInterviewPage = page
  },
  SET_APPLICANTS_WITH_CONDUCTED_INTERVIEW_TOTAL_PAGES (state, totalPages) {
    state.applicantsWithConductedInterviewTotalPages = totalPages
  },
  SET_APPLICANTS_WITH_CONDUCTED_INTERVIEW_TOTAL_ENTRIES (state, totalEntries) {
    state.applicantsWithConductedInterviewTotalEntries = totalEntries
  },
  SET_APPLICANTS_WITH_SENT_PROPOSAL (state, applicants) {
    state.applicantsWithSentProposal = applicants
  },
  ADD_APPLICANTS_WITH_SENT_PROPOSAL (state, applicants) {
    state.applicantsWithSentProposal =
      [...state.applicantsWithSentProposal, ...applicants]
  },
  SET_APPLICANTS_WITH_SENT_PROPOSAL_PAGE (state, page) {
    state.applicantsWithSentProposalPage = page
  },
  SET_APPLICANTS_WITH_SENT_PROPOSAL_TOTAL_PAGES (state, totalPages) {
    state.applicantsWithSentProposalTotalPages = totalPages
  },
  SET_APPLICANTS_WITH_SENT_PROPOSAL_TOTAL_ENTRIES (state, totalEntries) {
    state.applicantsWithSentProposalTotalEntries = totalEntries
  }
}
