import atsCompany from '@/repository/company'
import marketplaceCompany from '@/repository/companies-app/company'

export default {
  async getCompany ({ commit }) {
    const response = await atsCompany.getCompany()
    commit('SET_COMPANY', response)
  },

  async createCompany (_, payload) {
    return marketplaceCompany.createCompany(payload)
  },

  async saveCompanyPreference ({ commit, state }, preference) {
    const response = await atsCompany.putCompany({
      preferences: { ...state.company.preferences, ...preference }
    })

    commit('SET_COMPANY', response)
  },

  async getTrial ({ commit }) {
    const trial = await marketplaceCompany.getTrial()

    commit('SET_TRIAL', trial)
  }
}
