<template>
  <ul class="helpmenu">
    <li class="helpmenu__item helpmenu__item--dropdown">
      <p data-test="helpmenu__help-center">
        {{ $t(`${langPath}.helpCenter`) }}
      </p>
      <ul class="helpmenu helpmenu--dropdown">
        <li class="helpmenu__item">
          <a
            data-test="helpmenu__has-questions"
            class="helpmenu__link"
            target="_blank"
            :href="zendeskUrl"
          >
            {{ $t(`${langPath}.hasQuestions`) }}
          </a>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { zendesk } from '@/configs/constants'
import { createNamespacedHelpers } from 'vuex'
import { CURRENT_USER } from '@/store/namespaces'
const currentUserHelper = createNamespacedHelpers(CURRENT_USER)

export default {
  name: 'TheHelpMenu',

  data () {
    return {
      langPath: __langpath,
      zendeskUrl: `${zendesk.helpCenterBasePath}${zendesk.helpCenterID}`
    }
  },

  computed: {
    ...currentUserHelper.mapState(['currentUser'])
  },

  async mounted () {
    await this.getCurrentUser()
  },

  methods: {
    ...currentUserHelper.mapActions(['getCurrentUser'])
  }
}
</script>

<style lang="scss">
.helpmenu {
  cursor: default;
  min-width: 70*$base;
  padding: 0;

  &__item {
    @extend %button2;

    text-transform: none;

    &--dropdown {
      margin: 3*$base 2*$base 0;
      padding: 2*$base;
    }
    &:not(:first-child) { margin-top: 2*$base; }

    &--extension {
      margin: -2*$base;
    }
  }

  &__link {
    display: block;
    padding: 2*$base;
  }

  &__item--extension &__link {
    align-items: center;
    background: $gray5;
    display: flex;
    justify-content: center;
    padding: 4*$base 6*$base;
    width: 100%;

    & > span,
    .new-label {
      margin-left: $base*2;
    }
  }

  &--dropdown &__item {
    font-weight: normal;
    margin: 2*$base 0;
  }

  &--dropdown { padding: 0; }
  &__link:hover { background: $gray20; }
}
</style>
