<template>
  <section class="app-rich-text-input">
    <label
      class="app-rich-text-input__label"
      data-test="label"
      :for="name"
    >
      {{ label }}
    </label>

    <section
      class="app-rich-text-input__wrapper"
      :class="inputErrorClass"
    >
      <quill-editor
        :id="name"
        :value="value"
        class="app-rich-text-input__input"
        :class="customClass"
        data-test="input"
        :options="inputOptions"
        v-on="$listeners"
      />
    </section>

    <app-assistive-text
      v-if="displayedAssistiveText"
      :assistive-text="displayedAssistiveText"
      :variant="assistiveTextVariant"
      data-test="assistive-text"
    />
  </section>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import { validateField } from '@revelotech/everest'
import { defaultOptions } from '@/configs/quill'

export default {
  name: 'AppRichTextInput',
  components: { quillEditor },
  mixins: [validateField],
  props: {
    assistiveText: {
      type: String,
      default: ''
    },
    customClass: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: () => {}
    },
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    assistiveTextVariant () {
      return this.error ? 'error' : ''
    },
    inputOptions () {
      return Object.assign(defaultOptions, this.options)
    },
    displayedAssistiveText () {
      return this.error || this.assistiveText
    },
    inputErrorClass () {
      return this.error ? 'app-rich-text-input__wrapper--error' : ''
    }
  }
}
</script>

<style lang='scss'>
.app-rich-text-input {
  &__label {
    @extend %body-text2;
  }

  &__wrapper {
    @include margin(top, 2);

    &--error {
      border: 1px solid $error;

      &:focus {
        border: 1px solid $error;
      }
    }
  }

  .ql-container,
  .ql-editor {
    min-height: inherit;
  }
}
</style>
