import { get } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import { companiesUrl } from '@/helpers/constants/external-urls'
import { signInGtmEvent, userGtmEvents } from '@/helpers/user-gtm-events'

import deepObjectLooper from '@/helpers/deep-object-mapper'
import { toCamelCase } from '@/helpers/case-style'

const baseUrl = `${companiesUrl}/api/v2/current_user`

export default {
  async getCurrentUser () {
    const response = deepObjectLooper(await get(baseUrl), toCamelCase)

    let data = response ? await JSONAPIClient.parse(response.data) : {}

    if (response?.data.meta) {
      data = {
        ...data,
        redirectToAccessDenied: Boolean(response.data.meta.redirectToAccessDenied)
      }
    }

    userGtmEvents({
      user: data,
      gtmEvents: response?.data?.meta?.gtmEvents
    })
    signInGtmEvent(data)

    return data
  }
}
