<template>
  <div id="app">
    <alert-notifications />

    <router-view />
  </div>
</template>

<script>
import AlertNotifications from '@/components/AlertNotifications'

export default {
  name: 'App',

  components: {
    AlertNotifications
  }
}
</script>

<style lang="scss">
html,
body {
  min-height: 100vh;
  overflow: hidden;
}

#app {
  @extend %base-typography;

  background-color: $white;
  height: 100%;
}

@media print {
  @page {
    margin-bottom: 0;
    margin-top: 0;
  }
}
</style>
