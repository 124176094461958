const mapArrayKeys = (data, alterator) =>
  data.map(value => mapEachKey(value, alterator))

const mapObjectKeys = (data, alterator) => {
  const newData = {}

  Object.keys(data).forEach((key) => {
    newData[alterator(key)] = mapEachKey(data[key], alterator)
  })

  return newData
}

const mapEachKey = (data, alterator) => data instanceof Array
  ? mapArrayKeys(data, alterator)
  : data instanceof Object
    ? mapObjectKeys(data, alterator)
    : data

export default mapEachKey
