import fullAccess from './full-access'
import limitedAccess from './limited-access'
import publicAccess from './public-access'
import legacy from './legacy'

import FullAccess from '@/pages/FullAccess.vue'
import LimitedAccess from '@/pages/LimitedAccess.vue'

export default [
  ...legacy,
  {
    path: '',
    component: FullAccess,
    children: fullAccess
  },
  {
    path: '',
    component: LimitedAccess,
    children: limitedAccess
  },
  {
    path: '',
    component: () => import('@/pages/PublicAccess.vue'),
    children: publicAccess
  },
]
