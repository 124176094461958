import { get, post } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import { companiesUrl } from '@/helpers/constants/external-urls'
import { toSnakeCase, toCamelCase } from '@/helpers/case-style'
import deepObjectLopper from '@/helpers/deep-object-mapper'

const baseUrl = `${companiesUrl}/api/v2`

export default {
  async getInvite (inviteId) {
    const response = await get(`${baseUrl}/companies/invites/${inviteId}`)
    return JSONAPIClient.parse(response.data)
  },
  async createInvite (invite) {
    const {
      candidateId,
      atsOpeningId,
      templateId,
      title,
      minSalary,
      maxSalary,
      salaryType,
      description,
      salaryEstimation,
      source,
      sourceId,
      talentPositionId,
      globalCandidateId
    } = invite

    const params = {
      data: {
        attributes: {
          candidate_id: candidateId,
          ats_opening_id: atsOpeningId,
          template_id: templateId,
          title,
          source: source || null,
          min_salary: minSalary,
          max_salary: maxSalary,
          salary_type: salaryType,
          job_description: description,
          salary_estimation: salaryEstimation,
          source_id: sourceId || null,
          talent_position_id: talentPositionId,
          global_candidate_id: globalCandidateId
        }
      }
    }
    const response = await post(`${baseUrl}/companies/invites`, params)
    return deepObjectLopper(response.data.data, toCamelCase)
  },
  async makeProposal (payload) {
    const params = {
      data: {
        attributes: {
          ...deepObjectLopper(payload, toSnakeCase)
        }
      }
    }

    const response = await post(
      `${baseUrl}/invites/${payload.id}/proposal`,
      params
    )
    return deepObjectLopper(response.data.data, toCamelCase)
  },
  async summary (id) {
    const response = await get(`${baseUrl}/invites/${id}/summary`)
    return deepObjectLopper({
      ...response.data.data,
      included: response.data.included
    }, toCamelCase)
  },
  async similarCandidates (id) {
    const response = await get(`${baseUrl}/invites/${id}/similar_candidates`)
    return deepObjectLopper({
      ...response.data,
      included: response.included
    }, toCamelCase)
  }
}
