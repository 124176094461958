import { get } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import { companiesUrl } from '@/helpers/constants/external-urls'

const baseUrl = `${companiesUrl}/api/v2`

export default {
  async getFeature (feature) {
    const response = await get(`${baseUrl}/rollouts/${feature}`)

    return JSONAPIClient.parse(response.data)
  }
}
