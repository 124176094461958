import positions from '@/repository/companies-app/positions'
import filters from '@/filters/filters.js'
import jobPosting from '@/repository/companies-app/job-posting'
import { numberFromSalary } from '@/helpers/salary-helpers'

const sanitizeForm = (form) => ({
  benefits: form.benefits?.map((benefit) => benefit.label),
  careerPositionId: form.positionId,
  careerPositionName: form.positionName,
  contractTypeIds: form.contractType,
  description: form.description,
  diversities: form.diversities,
  employersAllowedIds: form.employersAllowedIds || [],
  experience_time: form.experienceTime,
  googlePlacesId: form.googlePlacesId,
  googlePlacesName: form.locale,
  max_salary: numberFromSalary(form.maximumSalary),
  min_salary: numberFromSalary(form.minimumSalary),
  privatePosition: form.privatePosition,
  remoteOnly: form.localeRemote,
  skills: form.skills?.map((skill) => ({
    id: skill.value, name: skill.label
  })),
  title: form.positionTitle
})

export default {
  async createPosition ({ commit, state }) {
    const form = state.positionForm

    commit('SET_IS_CHANGING_POSITION', true)

    let response
    try {
      response = await positions.createPosition(sanitizeForm(form))
      commit('SET_POSITION_CREATED', true)
    } catch (error) {
      response = error.response.data
    } finally {
      commit('SET_IS_CHANGING_POSITION', false)
    }
    return response
  },

  async getPosition ({ commit }, id) {
    try {
      commit('SET_IS_CHANGING_POSITION', true)

      const response = await positions.getPosition(id)

      const expectedForm = {
        id,
        positionTitle: response.title,
        positionId: response.careerPositionId,
        positionName: response.careerPositionName,
        experienceTime: `${response.experienceTime}`,
        locale: response.locationName,
        localeRemote: response.remoteOnly,
        contractType: response.contractTypeIds.map((value) => `${value}`),
        minimumSalary: filters.currency(response.minSalary).split(',')[0],
        maximumSalary: filters.currency(response.maxSalary).split(',')[0],
        description: response.description,
        skills: [
          ...response.skill.map(
            (skill) => ({ value: skill.id, label: skill.name })
          ),
          ...response.outsideSkills.map(
            (skill) => ({ value: null, label: skill })
          )
        ],
        benefits: response.benefits && response.benefits.map(
          (benefit) => ({ value: benefit, label: benefit })
        ),
        collectionLink: response.collectionLink,
        privatePosition: response.privatePosition,
        employersAllowedIds: response.employersAllowedIds,
        diversities: response.diversities?.map((diversity) => `${diversity.id}`)
      }

      commit('SET_POSITION_FORM', expectedForm)
    } catch (error) {} finally {
      commit('SET_IS_CHANGING_POSITION', false)
    }
  },

  async clearPosition ({ commit }) {
    commit('CLEAR_POSITION_FORM')
  },

  async setPositionFormAttribute ({ commit, dispatch }, payload) {
    commit('SET_POSITION_FORM_ATTRIBUTE', payload)

    if (payload.name === 'positionId' && payload.value !== 'others') {
      dispatch('updateSalaryAverage')
    }
  },

  async updatePosition ({ commit, state }) {
    const form = state.positionForm

    commit('SET_IS_CHANGING_POSITION', true)

    let response
    try {
      response = await positions.updatePosition(
        form.id, sanitizeForm(form)
      )
    } catch (error) {
      response = error.response.data
    } finally {
      commit('SET_IS_CHANGING_POSITION', false)
    }
    return response
  },

  async updateSalaryAverage ({ commit, state }) {
    if (!state.positionForm.positionId) {
      return commit(
        'SET_POSITION_FORM_ATTRIBUTE',
        { name: 'salaryAverage', value: undefined }
      )
    }

    const response = await jobPosting.getSalaryAverage(
      state.positionForm.positionId
    )

    commit('SET_POSITION_FORM_ATTRIBUTE', { name: 'salaryAverage', value: response.average }
    )
  }
}
