export default [
  {
    path: '/home',
    name: 'NewHome',
    component: () => import('@/pages/NewHome/NewHome'),
    children: [
      {
        path: 'applicant/:applicantId',
        component: () => import('@/pages/NewHome/components/ApplicantModal'),
        children: [
          {
            name: 'NewHomeApplicantProposal',
            path: 'proposal',
            component: () => import('@/pages/NewHome/components/ApplicantModalProposal')
          },
          {
            name: 'NewHomeApplicantReject',
            path: 'reject',
            component: () => import('@/pages/NewHome/components/ApplicantModalReject')
          }
        ]
      },
    ]
  },
  {
    path: '/pending-interviews',
    name: 'pendingInterviews',
    component: () => import('@/pages/NewHome/components/InterviewsCard')
  }
]
