import Vue from 'vue'
import VueRouter from 'vue-router'
import configs from '@/configs/vue-router'
import { loadLanguageAsync } from '@/plugins/vue-i18n'
import currentUser from '@/repository/companies-app/current-user'

Vue.use(VueRouter)

const router = new VueRouter(configs)

router.beforeEach((to, _from, next) => {
  if (to.meta?.skipAuth) return next()

  currentUser.getCurrentUser().then((user) => {
    if (user.redirectToAccessDenied) {
      next({ name: 'AccessVerification' })
    }
  })

  const lang = to.query.locale
  loadLanguageAsync(lang).then(() => next())
})

export default router
