<template>
  <app-modal
    :backdrop-click-close="true"
    :close-button="true"
    @close="$emit('close')"
  >
    <label class="published-positions-modal__label">
      {{ $t(`${langPath}.title`) }}
      <section class="published-positions-modal__controls">
        <app-slotted-text-input
          class="published-positions-modal__input"
          name="link"
          :value="company.publishedPositionsLink"
        >
          <template #right>
            <ev-button
              class="published-positions-modal__copy-button"
              data-test="copy-button"
              size="small"
              variant="secondary"
              color="blue"
              @click="handleCopy"
            >
              {{ $t(`${langPath}.copy`) }}
            </ev-button>
          </template>
        </app-slotted-text-input>
        <ev-link
          class="published-positions-modal__open-button"
          data-test="open-button"
          :a-href="company.publishedPositionsLink"
          target="_blank"
          variant="primary"
          @click="$emit('close')"
        >
          {{ $t(`${langPath}.openLink`) }}
        </ev-link>
      </section>
    </label>
    <section
      class="published-positions-modal__positions-count"
      data-test="positions-count"
    >
      <span
        v-if="publishedPositionsTotal"
        class="published-positions-modal__positions-count-text"
      >
        <span
          class="published-positions-modal__positions-count-number"
          data-test="positions-count-number"
        >
          {{ publishedPositionsTotal }}
        </span>
        <span>{{ $t(`${langPath}.publishedPositions`) }}</span>
      </span>
      <span
        v-else
        class="published-positions-modal__positions-count-empty"
        data-test="positions-count-empty-state"
      >
        {{ $t(`${langPath}.nonePublished`) }}
      </span>
      <span class="published-positions-modal__tooltip">
        <ev-icon
          class="published-positions-modal__tooltip-icon"
          color="gray60"
          name="question-circle"
        />
        <span class="published-positions-modal__tooltip-text">
          {{ $t(`${langPath}.tooltip`) }}
        </span>
      </span>
    </section>
    <router-link
      class="published-positions-modal__edit-profile"
      data-test="edit-profile"
      to="/companies/companies/profile/edit"
    >
      {{ $t(`${langPath}.editProfile`) }}
    </router-link>
  </app-modal>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'
import EvIcon from '@revelotech/everestV2/EvIcon'
import EvLink from '@revelotech/everestV2/EvLink'

export default {
  name: 'PublishedPositionsModal',
  components: { EvButton, EvIcon, EvLink },
  props: {
    company: {
      type: Object,
      required: true
    },
    publishedPositionsTotal: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  methods: {
    handleCopy () {
      this.$emit('copy', this.company.publishedPositionsLink)
      this.$emit('close')
    }
  }
}
</script>
<style lang='scss'>
.published-positions-modal {
  &__controls {
    display: flex;
    margin-top: $base;
  }

  &__copy-button {
    height: 100%;
  }

  &__edit-profile {
    @extend %body-text2;

    color: $blue;
    display: inline-block;
    margin-top: $base*6;

    &:hover {
      color: $blue;
    }
  }

  &__input {
    margin-top: 0;
    width: 100%;
  }

  &__input > .slotted-text-input__field {
    height: $base*12;
    padding: $base*2;

    & > input {
      overflow: hidden;
      padding-right: $base * 2;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__label {
    @extend %body-text2;
  }

  &__open-button {
    margin-left: $base*2;
    min-width: $base*28;
  }

  &__positions-count {
    display: block;
    line-height: 1;
    margin-top: $base*7;

    &-number {
      color: $success;
      font-family: Antenna, sans-serif;
      font-size: $base*6;
    }

    *:not(:first-child) {
      margin-left: $base*2;
    }
  }

  &__tooltip {
    position: relative;
  }

  &__tooltip-icon.published-positions-modal__tooltip-icon.published-positions-modal__tooltip-icon {
    font-size: $base*5;
  }

  &__tooltip-text {
    @extend %caption;

    background-color: $gray100;
    color: $white;
    display: none;
    left: 24px;
    padding: 2*$base;
    position: absolute;
    top: -5px;
    width: 210px;

    &::before {
      border-right: $gray100 8px solid;
      border-style: solid;
      border-width: 6px;
      content: '';
      display: block;
      height: 1px;
      left: -12px;
      position: absolute;
      top: 10px;
      width: 1px;
    }
  }

  &__tooltip:hover > &__tooltip-text {
    display: block;
  }
}
</style>
