import i18n from '@/plugins/vue-i18n'

const DAY_IN_MILLISECONDS = 1000 * 3600 * 24
const NUMBER_OF_DAYS_IN_YEAR = 365
const NUMBER_OF_DAYS_IN_MONTH = 30

const dateDiffs = (dateFrom, dateTo) => {
  const timeDiff = Math.abs(Date.parse(dateTo) - Date.parse(dateFrom))
  const diffDays = Math.ceil(timeDiff / DAY_IN_MILLISECONDS)
  const diffYears = Math.floor(diffDays / NUMBER_OF_DAYS_IN_YEAR)

  let diffMonths = (diffDays - (diffYears * NUMBER_OF_DAYS_IN_YEAR)) / NUMBER_OF_DAYS_IN_MONTH
  diffMonths = Math.floor(diffMonths)
  diffMonths = diffYears === 0 ? Math.max(diffMonths, 1) : diffMonths

  return {
    diffDays,
    diffMonths,
    diffYears
  }
}

export default {
  isWeekDay (date) {
    const dayOfWeek = new Date(date).getDay()

    return [0, 1, 2, 3, 4].includes(dayOfWeek)
  },

  humanDateDifference (dateFrom, dateTo) {
    if (!dateFrom || !dateTo || dateFrom > dateTo) return ''

    const { diffMonths, diffYears } = dateDiffs(dateFrom, dateTo)

    let yearText = ''
    if (diffYears > 0) {
      yearText = `${diffYears} ${i18n.tc('date.units.years', diffYears)}`
    }

    let monthText = ''
    if (diffMonths > 0) {
      monthText = `${diffMonths} ${i18n.tc('date.units.months', diffMonths)}`
    }

    return `${yearText} ${monthText}`.trim()
  },

  machineDateDifference (dateFrom, dateTo) {
    if (!dateFrom || !dateTo || dateFrom > dateTo) return ''

    const { diffMonths, diffYears } = dateDiffs(dateFrom, dateTo)

    let yearText = ''
    if (diffYears > 0) {
      yearText = `${diffYears}YYYY`
    }

    let monthText = ''
    if (diffMonths > 0) {
      monthText = `${diffMonths}MM`
    }

    return `P${yearText}${monthText}`
  }
}
