export default {
  REMOVE_POSITION (state, removedPosition) {
    state.positions = state.positions.filter(
      position => position.id !== removedPosition.id
    )
  },
  SET_POSITIONS (state, positions) {
    state.positions = positions
  },
  SET_ARCHIVED_POSITIONS (state, archivedPositions) {
    state.archivedPositions = archivedPositions
  },
  SET_POSITION (state, position) {
    const existingPosition = state.positions.find(pos => pos.id === position.id)
    state.positions = [
      ...state.positions.filter(pos => pos.id !== position.id),
      {
        ...existingPosition,
        ...position
      }
    ]
  },
  SET_TOTAL_PAGES (state, totalPages) {
    state.totalPages = totalPages
  },
  SET_EMPLOYER_POSITIONS_TOTAL (state, employerPositionsTotal) {
    state.employerPositionsTotal = employerPositionsTotal
  },
  SET_POSITIONS_TOTAL (state, positionsTotal) {
    state.positionsTotal = positionsTotal
  },
  SET_PUBLISHED_POSITIONS_TOTAL (state, publishedPositionsTotal) {
    state.publishedPositionsTotal = publishedPositionsTotal
  },
  SET_EMPLOYER_POSITIONS (state, employerPositions) {
    state.employerPositions = employerPositions
  },
  SET_POSITIONS_SUMMARY (state, positionsSummary) {
    state.positionsSummary = positionsSummary
  },
  SET_IS_FETCHING_POSITIONS (state, isFetchingPositions) {
    state.isFetchingPositions = isFetchingPositions
  },
  SET_IS_FETCHING_ARCHIVED_POSITIONS (state, isFetchingArchivedPositions) {
    state.isFetchingArchivedPositions = isFetchingArchivedPositions
  },
  SET_IS_FETCHING_SHORTLIST_POSITIONS (state, isFetchingShortlistPositions) {
    state.isFetchingShortlistPositions = isFetchingShortlistPositions
  },
  SET_IS_FETCHING_POSITIONS_SUMMARY (state, isFetchingPositionsSummary) {
    state.isFetchingPositionsSummary = isFetchingPositionsSummary
  },
  SET_EMPLOYER_POSITIONS_TOTAL_PAGES (state, employerPositionsTotalPages) {
    state.employerPositionsTotalPages = employerPositionsTotalPages
  },
  SET_ARCHIVED_POSITIONS_TOTAL_PAGES (state, archivedPositionsTotalPages) {
    state.archivedPositionsTotalPages = archivedPositionsTotalPages
  },
  SET_ACTIVE_POSITIONS (state, activePositions) {
    state.activePositions = activePositions
  },
  SET_ACTIVE_POSITIONS_TOTAL_PAGES (state, activePositionsTotalPages) {
    state.activePositionsTotalPages = activePositionsTotalPages
  },
  SET_EMPLOYER_POSITIONS_PAGE (state, employerPositionsPage) {
    state.employerPositionsPage = employerPositionsPage
  },
  SET_ACTIVE_POSITIONS_PAGE (state, activePositionsPage) {
    state.activePositionsPage = activePositionsPage
  },
  SET_ARCHIVED_POSITIONS_PAGE (state, archivedPositionsPage) {
    state.archivedPositionsPage = archivedPositionsPage
  },
  SET_EMPLOYER_ID_FILTER (state, employerIdFilter) {
    state.employerIdFilter = employerIdFilter
  },
  SET_EMPLOYER_ID_ARCHIVED_FILTER (state, employerIdArchivedFilter) {
    state.employerIdArchivedFilter = employerIdArchivedFilter
  },
  SET_SORT_BY (state, sortBy) {
    state.sortBy = sortBy
  },
  SET_IS_SORTING (state, isSorting) {
    state.isSorting = isSorting
  },
  SET_SHORTLIST_POSITIONS (state, shortlistPositions) {
    state.shortlistPositions = shortlistPositions
  },
  SET_SHORTLIST_POSITIONS_TOTAL_PAGES (state, shortlistPositionsTotalPages) {
    state.shortlistPositionsTotalPages = shortlistPositionsTotalPages
  },
  SET_SHORTLIST_POSITIONS_PAGE (state, shortlistPositionsPage) {
    state.shortlistPositionsPage = shortlistPositionsPage
  }
}
