export const csWhatsappUrl = 'https://api.whatsapp.com/send?phone=551149337732'
export const salesWhatsappUrl = 'https://api.whatsapp.com/send?phone=551149337732'
export const companiesUrl = process.env.VUE_APP_COMPANIES_URL
export const companiesLatamUrl = process.env.VUE_APP_COMPANIES_LATAM_URL
export const logoutUrl = `${companiesUrl}/users/ats_sign_out`
export const sdrScheduleUrl = 'https://calendly.com/leda-barsotti/30min'
export const signinUrl = `${companiesUrl}/auth/keycloak/sign_in`
export const forCandidatesUrl = 'https://www.revelo.com.br/para-candidatos'
export const forCompaniesUrl = 'https://www.revelo.com.br/para-empresas'

export default {
  csWhatsappUrl,
  salesWhatsappUrl,
  companiesUrl,
  companiesLatamUrl,
  sdrScheduleUrl,
  signinUrl,
  forCandidatesUrl,
  forCompaniesUrl
}
