<template>
  <header
    class="app-navigation"
    :class="{ 'app-navigation--warning': warning }"
    data-test="app-navigation"
  >
    <section class="app-navigation__brand">
      <a
        v-if="aHref"
        class="app-navigation__brand-link"
        :href="aHref"
      >
        <app-logo height="24px" />
      </a>
      <router-link
        v-else-if="routeTo"
        class="app-navigation__brand-link"
        :to="routeTo"
      >
        <app-logo height="24px" />
      </router-link>
      <app-logo
        v-else
        height="24px"
      />
    </section>
    <section class="app-navigation__nav">
      <slot name="before-bell" />
      <notifications-box
        v-if="showNotifications"
        data-test="app-navigation__notifications"
        :notifications="notifications"
        class="app-navigation__notifications app-navigation__notifications--mobile"
        @readAll="$emit('readAll')"
        @read="$emit('read', $event)"
      />
      <slot name="after-bell" />
      <template v-if="showMenu">
        <div
          data-test="app-navigation__hamburger"
          class="app-navigation__hamburger"
          :class="{
            'app-navigation__hamburger--opened': hamburgerOpened
          }"
          @click="toggleMobile"
        >
          <span class="app-navigation__hamburger-bar" />
          <span class="app-navigation__hamburger-bar" />
          <span class="app-navigation__hamburger-bar" />
        </div>
        <ul
          data-test="app-navigation__mainmenu"
          class="app-navigation__mainmenu"
          :class="{
            'app-navigation__mainmenu--opened': hamburgerOpened
          }"
        >
          <slot />
        </ul>
      </template>
      <notifications-box
        v-if="showNotifications"
        data-test="app-navigation__notifications"
        :notifications="notifications"
        class="app-navigation__notifications app-navigation__notifications--desktop"
        @readAll="$emit('readAll')"
        @read="$emit('read', $event)"
      />
      <slot name="second-icon" />
      <slot name="third-icon" />
    </section>
  </header>
</template>

<script>
import NotificationsBox from './NotificationsBox'

export default {
  name: 'AppNavigation',
  components: { NotificationsBox },
  props: {
    aHref: {
      type: String,
      default: undefined
    },
    notifications: {
      type: Array,
      default: () => []
    },
    routeTo: {
      type: Object,
      default: undefined
    },
    showNotifications: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hamburgerOpened: false
    }
  },
  computed: {
    showMenu () {
      return this.$slots.default?.length > 0
    }
  },
  methods: {
    toggleMobile () {
      this.hamburgerOpened = !this.hamburgerOpened
    }
  }
}
</script>

<style lang="scss">
  .app-navigation {
    align-items: center;
    background: $white;
    border-bottom: 1px solid $gray5;
    display: flex;
    height: $app-header-height;
    justify-content: space-between;
    padding: $base*2 $base*4;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    &__brand {
      padding-bottom: $base * 4;
      padding-top: $base * 4;

      &-link { cursor: pointer; }
    }

    &__notifications {
      align-items: center;
      height: 66px;
      padding: 6*$base 2*$base;

      &--desktop {
        display: none;

        @include breakpoint(md) {
          display: flex;
        }
      }

      &--mobile {
        @include breakpoint(md) {
          display: none;
        }
      }
    }

    &__nav {
      align-items: center;
      display: flex;
      height: 100%;
    }

    &__mainmenu {
      background: $white;
      border-top: 1px solid $gray5;
      box-shadow: $shadow-8;
      display: block;
      height: auto;
      left: 0;
      position: absolute;
      top: $app-header-height;
      transform: scaleY(0);
      transform-origin: top center;
      transition: 0.4s ease;
      width: 100%;
      z-index: 9;

      &--opened {
        overflow: inherit;
        transform: scaleY(1);
      }

      @include breakpoint(lg) {
        border-top: 0;
        box-shadow: none;
        display: flex;
        height: 100%;
        left: auto;
        padding: 0;
        position: static;
        transform: scaleY(1);
        width: auto;
      }
    }

    &__hamburger {
      $hamburgerclass: &;

      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      transition: all 0.4s ease;

      &-bar {
        background: $gray80;
        border-radius: 0.125em;
        content: '';
        display: inline-block;
        height: $base/2;
        margin: $base/2;
        position: relative;
        transition: all 0.4s ease;
        width: 5*$base;
      }

      &--opened {
        #{ $hamburgerclass }-bar {
          &:nth-child(1) {
            transform: rotate(225deg) translate(-5px, -5px);
          }

          &:nth-child(2) {
            transform: scale(0);
          }

          &:nth-child(3) {
            transform: rotate(135deg) translate(-4px, 3px);
          }
        }
      }
    }

    &--warning {
      background-color: $red-light;
      border-color: $red-light;
    }

    @include breakpoint(lg) {
      &__hamburger { display: none; }

      &__mainmenu {
        height: 100%;
        overflow: inherit;
        position: static;
      }
    }
  }
</style>
