import { isUnauthorizedError, isForbiddenError } from '@/helpers/error-matcher'
import { companiesUrl, logoutUrl } from '@/helpers/constants/external-urls'
import eventBus from '@/support/event-bus'

export default {
  methods: {
    handleErrors (err) {
      if (isUnauthorizedError(err)) {
        window.location.href = companiesUrl
      } else if (isForbiddenError(err)) {
        window.location.href = logoutUrl
      }
    }
  },

  created () {
    eventBus.on('error', this.handleErrors)
  },

  beforeDestroy () {
    eventBus.off('error', this.handleErrors)
  }
}
