import { get } from '@/helpers/request'
import jsonApiClient from '@/helpers/jsonapi-client'
import { companiesUrl } from '@/helpers/constants/external-urls'

const baseUrl = `${companiesUrl}/api/v2`

export default {
  async getSkillsByIds (skillIds) {
    const params = new URLSearchParams()
    for (const skillId of skillIds) {
      params.append('skills[]', skillId)
    }

    const response = await get(`${baseUrl}/skills/dictionary?${params.toString()}`)
    return jsonApiClient.parse(response.data)
  }
}
