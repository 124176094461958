export default {
  isChangingPosition: false,
  positionForm: {
    positionTitle: '',
    positionId: '',
    positionName: '',
    experienceTime: '',
    locale: '',
    localeRemote: false,
    contractType: '',
    minimumSalary: '',
    maximumSalary: '',
    skills: [],
    benefits: [],
    collectionLink: '',
    privatePosition: false,
    employersAllowedIds: [],
    diversities: [],
    careerId: '',
    salaryAverage: 0
  }
}
