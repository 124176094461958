<template>
  <div>
    <div
      ref="messagebox"
      class="message-box"
      tabindex="-1"
      :class="dynamicClasses"
      data-test="message-box"
      @blur="hideMenu"
      @click="handleClick"
      @keyup.space="handleClick"
    >
      <ev-icon
        data-test="messages-icon"
        name="comment-message"
      />
      <div
        v-show="!messagesHidden"
        class="message-box__container"
        :class="messagesHidden"
        data-test="messages-container"
      >
        <section @click.prevent="hideMenu">
          <messages-container />
        </section>
        <section class="message-box__actions">
          <ev-icon
            name="times"
            @click="hideMenu"
          />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import EvIcon from '@revelotech/everestV2/EvIcon'
import MessagesContainer from './MessagesContainer'
import { getMessages } from '@/repository/messages'

export default {
  name: 'MessageBox',

  components: {
    EvIcon,
    MessagesContainer
  },

  data () {
    return {
      messages: [],
      show: false
    }
  },

  computed: {
    messagesHidden () {
      return this.show ? '' : 'message-box__container--hidden'
    },

    hasUnreadMessages () {
      return this.messages.find((message) => message.readByEmployer === false)
    },

    dynamicClasses () {
      return {
        'message-box--open': this.show,
        'message-box--unread': this.hasUnreadMessages
      }
    }
  },

  async created () {
    const messages = await getMessages()

    this.messages = messages
  },

  methods: {
    handleClick () {
      if (!this.show) {
        this.openMenu()
      } else {
        this.hideMenu()
      }
    },

    openMenu () {
      this.show = true
      this.$refs.messagebox.focus()
    },

    hideMenu () {
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.message-box {
  align-items: center;
  color: var(--tx-gray100);
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  text-transform: none;
  vertical-align: middle;
  width: 64px;

  @include breakpoint(md) {
    padding: 5*$base;
  }

  &--open {
    background: var(--bg-gray40);
  }

  &--unread::after {
    background-color: var(--bg-red);
    border-radius: 50%;
    content: '';
    flex-shrink: 0;
    height: 2*$base;
    position: relative;
    right: 2*$base;
    top: -2*$base;
    width: 2*$base;
  }

  &__actions {
    position: absolute;
    right: 5*$base;
    top: 5*$base;

    @include breakpoint(md) {
      display: none;
    }
  }

  &__container {
    background: var(--bg-white);
    border-radius: var(--b-radius);
    box-shadow: var(--shadow-8);
    cursor: default;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;

    @include breakpoint(md) {
      height: 600px;
      position: absolute;
      top: 100%;
      width: 360px;
    }

    &--hidden {
      display: none;
    }
  }
}
</style>
