<template>
  <section class="invite-description">
    <label for="inviteFormDescriptionInput" class="invite-description__label">
      {{ $t(`${langPath}.positionDescription`) }}
    </label>

    <div class="invite-description__textarea-wrapper">
      <div class="invite-description__tags-list" ref="list" />

      <div
        ref="textArea"
        contenteditable="true"
        role="textbox"
        @input="updateValue"
        @tribute-replaced="updateValue"
        :aria-placeholder="placeholder"
        :placeholder="placeholder"
        spellcheck="true"
        aria-autocomplete="list"
        aria-controls="js_jf"
        aria-multiline="true"
        class="textarea invite-description__textarea"
        :class="errorClass"
      />

      <app-assistive-text :assistive-text="error" variant="error" />
      <!-- eslint-disable vue/no-v-html -->
      <p class="invite-description__tip" v-html="tagTriggerTipText" />
      <!-- eslint-enable vue/no-v-html -->
    </div>
  </section>
</template>

<script>
import Tribute from 'tributejs'
import removeAccents from 'remove-accents'
import { validateField } from '@revelotech/everest'
import debounce from 'lodash/debounce'

const DEBOUNCE_TIME = 500

export default {
  name: 'InvitesDescription',
  mixins: [validateField],
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    const tagTrigger = '{'

    return {
      langPath: __langpath,
      tagTrigger,
      placeholder: this.$t(`${__langpath}.placeholder`),
      descriptionText: '',
      tags: [
        {
          name: this.$t(`${__langpath}.tags.candidateNamePlaceholder`),
          value: this.$t(`${__langpath}.tags.candidateName`)
        },
        {
          name: this.$t(`${__langpath}.tags.companyDescriptionPlaceholder`),
          value: this.$t(`${__langpath}.tags.companyDescription`)
        },
        {
          name: this.$t(`${__langpath}.tags.positionTitlePlaceholder`),
          value: this.$t(`${__langpath}.tags.positionTitle`)
        },
        {
          name: this.$t(`${__langpath}.tags.employerNamePlaceholder`),
          value: this.$t(`${__langpath}.tags.employerName`)
        }
      ],
      tagTriggerTipText: this.$t(
        `${__langpath}.tip`,
        {
          trigger: '<strong class="invite-description__tag">' +
          tagTrigger + '</strong>'
        }
      )
    }
  },
  computed: {
    errorClass () {
      if (this.error) return 'textarea--error'

      return ''
    }
  },
  watch: {
    value (newValue) {
      // if value was not typed (came from parent props)
      if (this.descriptionText === newValue) return

      this.descriptionText = newValue
      this.replaceHtml(this.descriptionText)
    }
  },
  mounted () {
    const debounced = debounce((event) => {
      this.$emit('input:debounce', event)
    }, DEBOUNCE_TIME)

    this.$on('input', (event) => {
      debounced(event)
    })

    this.replaceHtml(this.value)

    // Init autocomplete
    const tribute = new Tribute({
      trigger: this.tagTrigger,
      allowSpaces: true,
      positionMenu: false,
      menuContainer: this.$refs.list,
      selectClass: 'invite-description__selected',
      lookup: (tag, mentionText) => removeAccents(tag.name),
      values: this.tags,
      menuItemTemplate: (item) => {
        return (
          `<div class="invite-description__item">${item.original.name}</div>`
        )
      },
      selectTemplate: (item) => {
        if (item) return this.smartTagSpan(item.original.value)

        return null
      }
    })
    tribute.attach(this.$refs.textArea)
  },
  methods: {
    smartTagSpan (value) {
      return '<span contenteditable="false" class="invite-description__tag">' +
       `{{${value}}}</span>`
    },
    updateValue (ev) {
      this.descriptionText = ev.target.innerText
      this.$emit('input', this.descriptionText)
    },
    replaceHtml (newValue) {
      const tagPattern = /\{\{([^}]*)\}\}(?!<\/)/g

      this.$refs.textArea.innerHTML = newValue.replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(
          tagPattern, this.smartTagSpan('$1')
        )
    }
  }
}
</script>

<style lang="scss">
.invite-description {
  &__label {
    @extend %body-text2;

    color: var(--tx-neutral-light-01);
  }

  &__textarea-wrapper {
    position: relative;
  }

  &__tags-list {
    .tribute-container {
      background-color: var(--bg-neutral-blank);
      border: solid 1px var(--b-neutral-light-04);
      max-height: 216px;
      overflow-y: scroll;
    }

    bottom: 100%;
    position: absolute;
    width: 100%;
    z-index: 100;
  }

  &__item {
    @extend %body-text2;

    background-color: var(--bg-neutral-blank);
    display: flex;
    height: 36px;
    padding: 2*$base 4*$base;
  }

  &__selected > &__item {
    background-color: var(--bg-neutral-light-04);
    cursor: pointer;
  }

  &__textarea {
    @include margin(top, 2);

    @extend %open-sans;
    @extend %body-text2;

    background-color: var(--bg-neutral-blank);
    max-height: calc(1.25rem * 8 + 16px);
    min-height: calc(1.25rem * 5 + 16px);
    overflow-x: hidden;
    overflow-y: auto;
    white-space: pre-wrap;
    word-break: break-word;

    &:empty::before {
      color: $input-placeholder-color;
      content: attr(placeholder);
      display: block;
    }
  }

  &__hidden-input {
    display: none;
  }

  &__tag {
    background-color: var(--bg-blue-light-01);
  }

  &__tip {
    @include margin(top, 2);

    @extend %caption;

    text-align: right;
  }
}
</style>
