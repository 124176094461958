export default {
  SET_IS_CHANGING_POSITION (state, isChangingPosition) {
    state.isChangingPosition = isChangingPosition
  },
  SET_POSITION_FORM (state, positionForm) {
    state.positionForm = {
      ...state.positionForm,
      ...positionForm
    }
  },
  CLEAR_POSITION_FORM (state) {
    state.positionForm = {}
  },
  SET_POSITION_FORM_ATTRIBUTE (state, payload) {
    state.positionForm = {
      ...state.positionForm, [payload.name]: payload.value
    }
  },
  SET_POSITION_CREATED (state, value) {
    state.positionCreated = value
  }
}
