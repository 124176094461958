import { get, put, del } from '@/helpers/request'
import jsonApiClient from '@/helpers/jsonapi-client'
import { toSnakeCase } from '@/helpers/case-style'
import deepObjectLopper from '@/helpers/deep-object-mapper'

export default {
  async getCalendars () {
    const response = await get('/employers/external_calendars')

    return jsonApiClient.parse(response.data)
  },

  async getAuthUrl () {
    const redirectURI = encodeURIComponent(window.location.href)
    const response = await get(
      `/employers/external_calendars/auth_url?redirect_url=${redirectURI}`
    )

    return window.location.replace(response.data.url)
  },

  async disconnect () {
    const response = await del('/employers/external_calendars')

    return response
  },

  async getEvents (selectedDate) {
    const response = await get(
      `/employers/external_calendars/events?date=${selectedDate}`
    )

    return jsonApiClient.parse(response.data)
  },

  async selectCalendars (date, calendars) {
    const response = await put(
      '/employers/external_calendars/select',
      {
        date,
        calendars: deepObjectLopper(calendars, toSnakeCase)
      }
    )

    return jsonApiClient.parse(response.data)
  }
}
