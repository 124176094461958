import candidates from '@/repository/companies-app/candidates'
import positions from '@/repository/companies-app/positions'

export default {
  async getUnavailabilities ({ commit }, payload) {
    commit('SET_IS_FETCHING_CANDIDATE_UNAVAILABILITIES', true)
    const unavailabilities = await candidates.getUnavailabilities(
      payload.candidateId,
      payload.startDateISO
    )
    commit('SET_CANDIDATE_UNAVAILABILITIES', unavailabilities)
    commit('SET_IS_FETCHING_CANDIDATE_UNAVAILABILITIES', false)
  },

  async getRecommendedCandidates ({ commit }, externalId) {
    const response = await positions.getRecommendedCandidates(externalId)

    commit('SET_RECOMMENDED_CANDIDATES', response)
  },

  async getCandidate ({ commit }, options) {
    const candidate = await candidates.getCandidate(
      options.candidateId,
      options.urlQuery
    )
    commit('ADD_CANDIDATE', candidate)
  },

  async getQuestions ({ commit }, candidateId) {
    commit('SET_IS_FETCHING_QUESTIONS', true)
    const questions = await candidates.getQuestions(candidateId)
    commit('SET_QUESTIONS', questions)
    commit('SET_IS_FETCHING_QUESTIONS', false)
  },

  async getUnansweredQuestions ({ commit }, candidateId) {
    const unansweredQuestions = await candidates.getUnansweredQuestions(
      candidateId
    )
    commit('SET_UNANSWARED_QUESTIONS', unansweredQuestions)
  },

  async createQuestion ({ commit }, { candidateId, question }) {
    const createdQuestion = await candidates.createQuestion(
      candidateId,
      question
    )

    commit('ADD_QUESTION', {
      id: createdQuestion.id,
      text: question,
      answer: null,
      answeredAt: null
    })
  },

  async getEvaluations ({ commit }, candidateId) {
    const evaluations = await candidates.getEvaluations(candidateId)
    commit('SET_CANDIDATE_EXTERNAL_EVALUATIONS', evaluations)
  },

  async getTags ({ commit }, candidateId) {
    const tags = await candidates.getTags(candidateId)
    commit('SET_TAGS', tags)
  },

  setFavoriteModal ({ commit }, value) {
    commit('SET_FAVORITE_MODAL', value)
  }
}
