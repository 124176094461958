import { post } from '@/helpers/request'
import { toSnakeCase } from '@/helpers/case-style'
import deepObjectLopper from '@/helpers/deep-object-mapper'

export default {
  createProposal (applicantId, params) {
    return post(
      `/applicants/${applicantId}/proposals`,
      deepObjectLopper(params, toSnakeCase)
    )
  }
}
