export default {
  SET_INTERVIEWS (state, interviews) {
    state.interviews = interviews
  },
  ADD_INTERVIEW (state, interview) {
    state.interviews.push(interview)
  },
  SET_LOADING_INTERVIEWS (state, loading) {
    state.loadingInterviews = loading
  },
  SET_LOADING_MORE_INTERVIEWS (state, loading) {
    state.loadingMoreInterviews = loading
  },
  SET_UPCOMING_INTERVIEWS (state, interviews) {
    state.upcomingInterviews = interviews
  },
  ADD_UPCOMING_INTERVIEWS (state, interviews) {
    state.upcomingInterviews = [...state.upcomingInterviews, ...interviews]
  },
  REMOVE_UPCOMING_INTERVIEW (state, id) {
    state.upcomingInterviews = state.upcomingInterviews.filter(i => i.id !== id)
  },
  SET_UPCOMING_INTERVIEWS_PAGE (state, page) {
    state.upcomingInterviewsPage = page
  },
  SET_UPCOMING_INTERVIEWS_TOTAL_PAGES (state, totalPages) {
    state.upcomingInterviewsTotalPages = totalPages
  }
}
