export default {
  components: {
    App: {
      AppViewMore: {
        seeMore: 'Ver mais',
        seeLess: 'Ver menos'
      },
      NotificationsBox: {
        newMessage: 'Nova mensagem de <strong>{name}</strong>',
        accepted: '<strong>{name}</strong> aceitou o seu convite',
        cancelled: '<strong>{name}</strong> cancelou o processo',
        waitingForSchedule: 'Agende agora a entrevista',
        emptyState: 'Não há novas notificações',
        notifications: 'Notificações',
        tooltip: 'Marcar todas como lidas',
        callToAction: 'Marcar todas como lidas'
      },
      AppDatePicker: {
        cancelLabel: 'Cancelar',
        okLabel: 'OK'
      },
      AppTimePicker: {
        cancelLabel: 'Cancelar',
        okLabel: 'OK'
      }
    },
    Chat: {
      messageBox: {
        title: 'Mensagens',
        sentByCurrentUser: 'Você:',
        emptyState: {
          title: 'Você não tem mensagens',
          text: 'As conversas com candidatos e candidatas vão aparecer aqui'
        },
        loadMoreMessages: 'Ver mais',
        yesterday: 'Ontem'
      }
    },
    DatePicker: {
      cancelLabel: 'Cancelar',
      okLabel: 'Ok'
    },
    TheHeader: {
      menu: {
        home: 'Início',
        plans: 'Planos',
        firstSteps: 'Primeiros Passos',
        candidates: 'Feed de Talentos',
        positions: 'Vagas',
        stopImpersonating: 'Sair da {company}'
      }
    },
    TheUserMenu: {
      profileSettings: 'Meu Perfil',
      plan: 'Meu Plano',
      otherSettings: 'Outras Configurações',
      logout: 'Sair'
    },
    TheHelpMenu: {
      helpCenter: 'Precisa de Ajuda?',
      hasQuestions: 'Perguntas Frequentes',
      extensionNew: 'Novo'
    },
    Candidates: {
      CandidateProfileShareModal: {
        emailLabel: 'Email',
        emailPlaceholder: 'email@revelo.com.br',
        messageLabel: 'Mensagem',
        messagePlaceholder: 'Escreva aqui a sua mensagem',
        modalConfirm: 'Enviar',
        modalTitle: 'Compartilhar Perfil',
        shareProfile: 'Compartilhar',
        successTitle: 'Sucesso',
        successMessage: 'Perfil compartilhado com sucesso',
        errorTitle: 'Erro',
        errorMessage: 'Houve um erro ao compartilhar o perfil'
      },
      CandidateProfileExternalReview: {
        title: 'Deixar avaliação',
        sendFeedback: 'Enviar avaliação',
        choosePosition: 'Escolha uma posição',
        authorLabel: 'Nome',
        feedbackAuthor: 'Seu nome',
        comments: 'Comentários sobre o candidato',
        commentsLabel: 'Comentários',
        profile: 'Perfil',
        skill: 'Habilidades',
        culturalFit: 'Fit Cultural',
        experience: 'Experiência',
        successTitle: 'Sucesso',
        successMessage: 'Feedback enviado com sucesso',
        errorTitle: 'Erro',
        errorMessage: 'Houve um erro ao enviar o feedback',
        feedbackDone: 'Obrigado, seu comentário foi registrado com sucesso.'
      },
      CandidateProfileDiversities: {
        title: 'Diversidade',
        disability: 'Deficiência',
        specificNeeds: 'Adaptação do local de trabalho',
        hearing: 'Auditiva',
        physical: 'Física',
        intellectual: 'Intelectual',
        multiple: 'Mais de uma',
        visual: 'Visual'
      },
      CandidateProfileAbout: {
        about: 'Um pouco sobre',
        mainSkills: 'Principais Habilidades',
        generalSkills: 'Habilidades Gerais',
        certifications: 'Certificações',
        experienceTime: {
          zeroYears: 'Quero começar!',
          betweenZeroAndThreeYears: '0 - 3 anos',
          betweenThreeAndSixYears: '3 - 6 anos',
          moreThanSixYears: '+ de 6 anos'
        }
      },
      CandidateProfileCertificates: {
        certifications: 'Certificações',
        clickHere: 'Clique aqui',
        additionalInfo: 'Informações adicionais',
        validationTitle: 'Validação',
        validationText: 'para acessar o site de validação.'
      },
      CandidateProfileAnswers: {
        question: 'P:',
        answered: 'Respondido em:',
        waitingAnswer: 'Aguardando resposta do candidato',
        answerStatus: 'O candidato respondeu sua pergunta'
      },
      CandidateProfileEducations: {
        title: 'Formação Acadêmica'
      },
      CandidateProfileEndorsements: {
        title: 'Referências'
      },
      CandidateProfileSidebar: {
        CandidateProfileSidebarAddExternalCandidate: {
          addError: 'Houve um erro ao criar o candidato',
          addSuccess: 'Candidato criado com sucesso',
          button: 'Entrar em contato',
          title: 'Gostou deste candidato?'
        },
        CandidateProfileSidebarGoToInvite: {
          sendNewInvite: 'Enviar novo convite',
          scheduleInterview: 'Agende uma entrevista',
          interviewDescription: '{candidateName} aceitou seu convite.',
          pending: 'Participando do processo',
          completed: 'Candidato já participou do processo',
          inviteModalTitle: 'Entrar em contato',
          warningTitle: 'O processo anterior será cancelado ao enviar o convite'
        },
        CandidateProfileSidebarCurrentUserFeedback: {
          title: 'Sua avaliação',
          score: 'Nota',
          profile: 'Perfil',
          experience: 'Experiência',
          culturalFit: 'Fit cultural',
          skills: 'Habilidades',
          observation: 'Sua observação'
        },
        CandidateProfileSidebarCompanyFeedbacks: {
          title: 'Avaliação dos seus colegas',
          score: 'Nota',
          profile: 'Perfil',
          experience: 'Experiência',
          culturalFit: 'Fit cultural',
          skills: 'Habilidades',
          currentUserEvaluation: '(você)'
        },
        CandidateProfileSidebarFeedbackNew: {
          feedbackButton: 'Avaliar candidato',
          title: 'Como foi a entrevista?',
          evaluate: 'Avalie a entrevista',
          profile: 'Perfil',
          skill: 'Habilidades',
          description: 'O que você achou de {name}?',
          feedbackPlaceholder: 'Informe aqui seu feedback',
          interviewNotHappened: 'A entrevista não aconteceu.',
          submit: 'Salvar',
          scoreLegend: 'Sendo 10 muito bom',
          culturalFit: 'Fit Cultural',
          experience: 'Experiência'
        },
        CandidateProfileSidebarFeedback: {
          feedbackButton: 'Avaliar candidato',
          title: 'Como foi a entrevista?',
          evaluate: 'Avalie a entrevista',
          profile: 'Perfil',
          skill: 'Habilidades',
          description: 'O que você achou de {name}?',
          feedbackPlaceholder: 'Informe aqui seu feedback',
          interviewNotHappened: 'A entrevista não aconteceu.',
          submit: 'Salvar',
          scoreLegend: 'Sendo 10 muito bom',
          culturalFit: 'Fit Cultural',
          experience: 'Experiência'
        }
      },
      CandidateProfileModal: {
        CandidateProfileModalConfirmInvite: {
          alreadyInvited: 'Candidato já convidado',
          activeProcess: 'Este candidato possui um {emphasis} com sua empresa.',
          activeProcessEmphasis: 'processo ativo',
          contact: 'Para entrar em contato, você irá {emphasis}',
          contactEmphasis: 'finalizar o processo.',
          process: 'Processo',
          createdAt: 'Criado',
          invitedBy: 'Convidado por',
          cancel: 'Cancelar',
          confirm: 'Confirmar'
        }
      },
      CandidateProfileEvaluationsModal: {
        evaluationCount:
          '{firstAuthor} avaliou o perfil |' +
          ' {firstAuthor} e +1 pessoa externa avaliou |' +
          ' {firstAuthor} e +{otherAuthors} pessoas externas avaliaram',
        modalTitle: 'Avaliações Externas',
        culturalFit: 'Fit Cultural',
        experience: 'Experiência'
      },
      CandidateProfileHeader: {
        evaluationCount: '{firstAuthor} avaliou o perfil | {firstAuthor} e +1 pessoa externa avaliou | {firstAuthor} e +{otherAuthors} pessoas externas avaliaram',
        livesIn: 'Cidade onde mora',
        yearsOfExperience: 'Experiência na área',
        salaryExpectation: 'Pretensão salarial',
        hiringType: 'Aceita propostas',
        locations: 'Disposto a trabalhar em:',
        englishLevel: 'Nível de inglês',
        experienceTime: {
          zeroYears: 'Quero começar!',
          betweenZeroAndThreeYears: '0 - 3 anos',
          betweenThreeAndSixYears: '3 - 6 anos',
          moreThanSixYears: '+ de 6 anos'
        },
        salary: 'R$ {salary}',
        advanced: 'Avançado ou fluente',
        newThisWeek: 'Novo essa semana',
        shareProfile: 'Compartilhar',
        CandidateProfileHeaderEarlyAccessBanner: {
          title: 'Candidato em Acesso Antecipado',
          ribbon: 'Acesso antecipado',
          text:
            'Este candidato está aguardando a data marcada ou ainda não preencheu todos os requisitos para entrar na rodada.',
          textEmphasis:
            'Fique atento que ele pode demorar um pouco mais para responder que um candidato que já está na rodada.'
        },
        CandidateProfileHeaderLanguages: {
          levels: {
            basic: 'Básico',
            intermediate: 'Intermediário',
            advanced: 'Avançado'
          },
          languages: {
            arabic: 'Nível de Árabe',
            dutch: 'Nível de Holandês',
            english: 'Nível de Inglês',
            french: 'Nível de Francês',
            german: 'Nível de Alemão',
            hebrew: 'Nível de Hebreu',
            hindi: 'Nível de Hindi',
            italian: 'Nível de Italiano',
            japanese: 'Nível de Japonês',
            korean: 'Nível de Coreano',
            mandarin_chinese: 'Nível de Mandarin Chinês',
            russian: 'Nível de Russo',
            spanish: 'Nível de Espanhol'
          }
        }
      },
      CandidateProfileIncomplete: {
        avatarAlt: 'Avatar do(a) candidato(a)',
        ribbon: 'Não Avaliado',
        title: 'Candidato não avaliado',
        text: 'Este candidato ainda não finalizou o preenchimento do perfil. Estamos exibindo apenas os dados informados pelo usuário.'
      },
      CandidateProfileJobs: {
        title: 'Experiência Profissional',
        CandidateProfileJobExperience: {
          current: 'Até o momento'
        }
      },
      CandidateProfileQuestions: {
        title: 'Perguntas anônimas para {name}',
        description:
          'Pergunte ao candidato sobre algum projeto, experiência ou conhecimento. A pergunta é anônima e a resposta ficará visível no perfil para outras empresas.',
        withoutQuestion: 'Ainda não há perguntas anônimas para {name}',
        fieldName: 'Pergunta',
        placeholder: 'Ex: Qual sua experiência com Photoshop e Sketch?',
        sendButton: 'Enviar',
        notificationCard: {
          title: 'Sua pergunta foi enviada',
          message: 'Você receberá uma notificação por email quando for respondida'
        }
      },
      CandidateProfileSidebarSignIn: {
        title: 'Entreviste este profissional!',
        description: 'Na Revelo você tem acesso aos melhores candidatos de',
        dev: 'Desenvolvimento',
        mkt: 'Marketing',
        design: 'Design',
        business: 'Negócios',
        bi: 'Business Inteligence',
        fin: 'Finanças',
        signupButton: 'Entrevistar candidato'
      },
      CandidateProfileSocial: {
        cv: 'Currículo',
        linkedin: 'LinkedIn',
        blog: 'Blog',
        portifolio: 'Portfólio',
        behance: 'Behance',
        dribbble: 'Dribbble',
        github: 'Github',
        stackoverflow: 'Stackoverflow'
      },
      CandidateProfileTimeline: {
        title: 'Timeline',
        you: 'Você',
        footerMessage:
          '* As mensagens só serão visíveis para os seus colegas'
      },
      CandidateProfileVideo: {
        title: 'Vídeo de apresentação'
      },
      CandidateProfileFab: {
        favorite: 'Favoritar',
        share: 'Compartilhar',
        contact: 'Entrar em contato'
      },
      CandidateProfileFavoriteButton: {
        favorite: 'Favoritar',
        newList: 'Criar nova lista',
        newListBtn: 'Criar',
        duplicatedNameError: {
          title: "Erro ao criar Wish List",
          message: 'O nome {name} já foi cadastrado'
        },
        genericError: {
          title: 'Tivemos um problema',
          message: 'Tente novamente daqui a pouco'
        }
      },
      CandidateProfileFavoriteModal: {
        title: 'Salvar na Wish List',
        subtitle:
          'Crie uma nova Wish List ou adicione o candidato em uma das existentes.',
        createNewWishList: 'Criar nova Wish List',
        close: 'Fechar',
        name: 'Nome da Wish List',
        cancel: 'Cancelar',
        create: 'Criar'
      },
      CandidateProfileLanguages: {
        title: 'Idiomas',
        levels: {
          basic: 'Básico',
          intermediate: 'Intermediário',
          advanced: 'Avançado'
        },
        languages: {
          arabic: 'Nível de Árabe',
          dutch: 'Nível de Holandês',
          english: 'Nível de Inglês',
          french: 'Nível de Francês',
          german: 'Nível de Alemão',
          hebrew: 'Nível de Hebreu',
          hindi: 'Nível de Hindi',
          italian: 'Nível de Italiano',
          japanese: 'Nível de Japonês',
          korean: 'Nível de Coreano',
          mandarin_chinese: 'Nível de Mandarin Chinês',
          russian: 'Nível de Russo',
          spanish: 'Nível de Espanhol'
        }
      },
      SourceBadge: {
        applicationPage: 'Mural de vagas',
        github: 'GitHub',
        linkedin: 'LinkedIn',
        manuallyAdded: 'Adicionado(a)',
        marketplace: 'Feed de talentos',
        prime: 'Recomendação',
        referral: 'Indicação',
        shortlist: 'Shortlist'
      }
    }
  },
  pages: {
    PublicAccess: {
      components: {
        PublicAccessHeader: {
          linkSignin: 'Entrar/Cadastrar',
          linkForCompanies: 'Para empresas',
          linkForCandidates: 'Para profissionais'
        }
      }
    },
    Onboarding: {
      CreatePosition: {
        greeting: 'Que bom ter você de novo!',
        fillInfo: 'Preencha as informações da sua vaga para' +
          ' começar a filtrar profissionais e economizar' +
          ' horas analisando currículos.',
        searchProfessionals: 'Buscar profissionais',
        skipStage: 'Pular etapa',
        position: {
          title: 'Título da vaga',
          titlePlaceholder: 'Nome da vaga',
          salaryRange: 'Faixa salarial',
          optional: '(Opcional)',
          career: 'Qual profissional você busca?',
          careerPlaceholder: 'Selecione',
          skills: 'Informe as habilidades mais importantes',
          skillsPlaceholder: 'Insira pelo menos uma habilidade'
        },
        findTalent: {
          letsFind: 'Vamos encontrar',
          rightTalent: 'o talento certo',
          toYourPosition: 'para sua vaga?'
        }
      },
      SearchSteps: {
        title: {
          searchingFor: 'Estamos buscando ',
          bestTalent: 'os melhores talentos ',
          toYourPosition: 'para a sua vaga'
        },
        analysingProfiles: 'ANALISANDO PERFIS...',
        filteringProfessionals: 'FILTRANDO PROFISSIONAIS PELAS HABILIDADES...',
        selectingBestTalent: 'SELECIONANDO OS MELHORES TALENTOS...'
      },
      SearchResults: {
        allSet: 'Tudo pronto!',
        altImage: 'pessoa capturando talentos',
        title: {
          analysedProfiles: 'Analisamos mais de ',
          thirteenThousand: '13 mil perfis ',
          bestMatch: 'e selecionamos os que mais combinam com a sua vaga!'
        },
        showProfessionals: 'Mostrar profissionais'
      }
    },
    AccessVerification: {
      ExpiredPlan: {
        header: 'Plano expirado',
        title: 'Seu plano expirou em',
        talkToUs: 'Para continuar contratando os melhores talentos do mercado de tecnologia,' +
        ' entre em contato conosco e renove seu acesso.',
        emailAddress: 'empresas@revelo.io',
        phoneNumber: '(11) 96163-7657',
        talkToManager: 'Conversar com gerente da conta'
      },
      ExpiredCredits: {
        header: 'Seus créditos expiraram',
        title: 'Seus créditos expiraram em',
        talkToUs: 'Para continuar contratando profissionais,' +
        ' renove seu acesso à plataforma comprando mais créditos.',
        emailAddress: 'empresas@revelo.io',
        phoneNumber: '(11) 96163-7657',
        talkToManager: 'Conversar com gerente da conta'
      },
      CompletedPlan: {
        header: 'Você contratou 1 profissional | Você contratou {numberOfPositions} profissionais',
        title: 'Você usou todos os seus créditos',
        title2: 'contratando os melhores talentos do mercado de tecnologia.',
        talkToUs: 'Para continuar contratando profissionais,' +
        ' renove seu acesso à plataforma comprando mais créditos.',
        emailAddress: 'empresas@revelo.io',
        phoneNumber: '(11) 96163-7657',
        talkToManager: 'Conversar com gerente da conta'
      },
      PayPerHireEnded: {
        header1: 'A Revelo evoluiu.',
        header2: 'Nossos planos, também.',
        title: '<b>O seu plano, pay-per-hire, foi descontinuado</b>. Mas não se preocupe,' +
        ' vamos reembolsar os valores do mês atual e dos futuros caso seja necessário.',
        talkToUs: 'Se restarem dúvidas entre em contato em:',
        emailAddress: 'empresas@revelo.io',
        phoneNumber: '(11) 96163-7657',
        talkToSalesText1: 'E não vamos deixar você sem opção, viu?',
        talkToSalesText2: 'Desenvolvemos novos planos que se ' +
        'adequam às necessidades de contratação da sua empresa.',
        talkToSalesText3: 'Entre em contato com a gente para continuar' +
        ' contratando os melhores talentos do mercado de tecnologia.',
        talkToSalesTeam: 'Conversar com time de vendas'
      },
      LiteEnded: {
        header1: 'A Revelo evoluiu.',
        header2: 'Nossos planos, também.',
        title: '<b>O seu plano, lite, foi descontinuado</b>. Mas não se preocupe, ' +
        'sua assinatura já foi cancelada e os meses de fevereiro e março não foram cobrados. ' +
        'Qualquer cobrança indevida será reembolsada.',
        talkToUs: 'Se restarem dúvidas entre em contato em:',
        emailAddress: 'empresas@revelo.io',
        phoneNumber: '(11) 96163-7657',
        talkToSalesText1: 'E não vamos deixar você sem opção, viu?',
        talkToSalesText2: 'Desenvolvemos novos planos que se ' +
        'adequam às necessidades de contratação da sua empresa.',
        talkToSalesText3: 'Entre em contato com a gente para continuar' +
        ' contratando os melhores talentos do mercado de tecnologia.',
        talkToSalesTeam: 'Conversar com time de vendas'
      }
    },
    Applicant: {
      components: {
        ApplicantActions: {
          cancel: 'Cancelar',
          nextStage: 'Próxima Fase',
          rejectCandidateErrorMessage:
            'Erro ao tentar reprovar candidato. Tente novamente mais tarde',
          rejectCandidateSuccessMessage: 'O candidato foi reprovado',
          rejectionReason: 'Motivo de reprovação',
          reject: 'Reprovar'
        },
        ApplicantCardContacts: {
          callOnWhatsapp: 'Contato via WhatsApp',
          chat: 'Mensagens',
          email: 'E-mail',
          linkedinLabel: 'Perfil de {name}',
          phone: 'Telefone',
          resumeLabel: 'CV de {name}',
          talkTo: 'Fale com',
          title: 'Links e contato'
        },
        ApplicantCardInfos: {
          seeProfile: 'Ver perfil completo',
          experience: 'anos de experiência',
          note: 'Anotações:',
          desiredSalary: 'Pretensão',
          currentSalary: 'Salário Atual'
        },
        ApplicantCardRefused: {
          subtitle: 'Candidato desistiu do processo',
          refusalReasonTitle: 'Motivo de desistência',
          description:
            'No momento o candidato não tem interesse em participar do processo seletivo da sua empresa.',
          toGetInTouch:
            'Caso queira entrar em contato com este candidato no futuro, você pode enviar um novo convite.'
        },
        ApplicantCardRejected: {
          subtitle: 'Motivo de reprovação'
        },
        ApplicantCardPending: {
          subtitle: 'Convite Pendente',
          description:
            'O candidato ainda não respondeu ao seu convite. Você poderá prosseguir após a resposta'
        },
        ApplicantTitle: {
          currentStage: 'Fase atual'
        },
        ApplicantCardTabs: {
          interview: 'Entrevista',
          proposal: 'Proposta',
          comment: 'Comentários'
        }
      }
    },
    ApplicantProposal: {
      components: {
        ApplicantProposalAccepted: {
          proposalDescription:
            'Parabéns! {name} aceitou sua proposta de emprego. Entre em contato com o candidato para informar os próximos passos.'
        },
        ApplicantProposalAwaiting: {
          proposalDescription:
            'Aguardando candidato aceitar sua proposta. Se desejar, fale com ele diretamente pelo chat.'
        },
        ApplicantProposalChoose: {
          proposalHeader:
            'Parabéns! Você encontrou o candidato certo para sua vaga.',
          proposalSubHeader:
            'Envie uma proposta formal ou informe a contração do candidato.',
          optionFormalize:
            'Já contratei o candidato, estou apenas registrando a proposta na Revelo.',
          optionProposal: 'Quero enviar a proposta ao candidato.'
        },
        ApplicantProposalForm: {
          proposalDescription: 'Proposta de {name}',
          benefits: 'Benefícios',
          benefitsAssistiveText:
            'Digite o benefício e pressione enter para inserir',
          proposalMessage: 'Mensagem',
          proposalMessageDescription:
            'Envie uma mensagem para o candidato com a descrição da proposta',
          salary: 'Salário',
          send: 'Enviar Proposta',
          startDate: 'Data de início',
          proposalNotSent: 'A proposta não pôde ser enviada! Tente Novamente',
          proposalSent: 'Proposta enviada com sucesso!'
        },
        ApplicantProposalFormalize: {
          startDate: 'Qual é a data de início do candidato?',
          send: 'Informar Contratação',
          salary: 'Por qual salário o candidato foi contratado?',
          datePlaceholder: '17/12/2007',
          proposalNotSent:
            'A contratação não pôde ser formalizada! Tente Novamente',
          proposalSent: 'Contratação formalizada com sucesso!'
        },
        ApplicantProposalFormalized: {
          proposalDescription: '{name} informou a contratação do candidato.',
          salary: 'Salário',
          startDate: 'Data de início'
        }
      }
    },
    ApplicantRejected: {
      components: {
        ApplicantRejectedFeedback: {
          rejectionFeedbackNotSent: 'Envie um feedback',
          rejectionFeedbackSent: 'Feedback',
          rejectionFeedbackDescription:
            'O feedback é muito importante para os candidatos. Que tal enviar uma mensagem para {name} agora?',
          rejectionFeedbackLabel: '(opcional)',
          rejectionPlaceholder: 'Escreva um feedback',
          feedbackCheckboxLabel: 'Não enviar feedback',
          defaultRejectionFeedback: 'Olá, agradecemos sua dedicação em nosso processo seletivo.\nEmbora tenhamos gostado do seu perfil, o processo foi altamente competitivo e decidimos seguir com outros candidatos para esta posição. Mais uma vez agradecemos por seu interesse na {companyName} e desejamos boa sorte nos próximos passos de sua carreira profissional. \nAtenciosamente, {companyName}'
        },
        ApplicantRejectedReason: {
          reasonNotSent: 'Por que você está reprovando o candidato?',
          reasonSent: 'Motivo de Reprovação',
          rejectionInfo: 'O candidato não verá essa informação',
          rejectionReason: 'Motivo da reprovação'
        }
      },
      ApplicantRejected: {
        sendFeedback: 'Enviar Feedback',
        reject: 'Reprovar',
        cancel: 'Cancelar',
        rejectCandidateErrorMessage:
          'Houve um erro ao reprovar o candidato. Tente novamente',
        rejectCandidateSuccessMessage: 'Candidato reprovado com sucesso'
      }
    },
    ApplicantComments: {
      ApplicantComments: {
        description: 'As mensagens só serão visíveis para os seus colegas.'
      },
      components: {
        ApplicantCommentsForm: {
          placeholder: 'Comentário',
          add: 'Adicionar',
          commentAssistiveText: 'Apenas colegas podem ver os comentários.',
          commentCreatedSuccess: 'Comentário criado com sucesso'
        },
        ApplicantCommentsItem: {
          confirmDeleteLabel: 'Deseja realmente excluir?',
          deleteConfirmed: 'Sim',
          deleteLabel: 'Excluir',
          deleteCanceled: 'Não'
        }
      }
    },
    ApplicantInterview: {
      components: {
        ApplicantInterviewSchedule: {
          text: '{candidateName} tem interesse em conversar com alguém da sua empresa, agende uma entrevista.',
          schedule: 'Agendar entrevista'
        },
        ApplicantInterviewDetails: {
          interview: 'Entrevista agendada por {employerName} com',
          cancel: 'Cancelar entrevista',
          cancelConfirmation: 'tem certeza que deseja cancelar a entrevista?',
          cancelSuccess: 'Entrevista Cancelada com sucesso'
        },
        ApplicantInterviewPast: {
          text: 'A entrevista com {candidateName} foi:',
          schedule: 'Agendar nova entrevista'
        }
      }
    },
    ApplicantEdit: {
      ApplicantEdit: {
        title: 'Editar candidato',
        editSuccess: 'Candidato editado com sucesso',
        editError: 'Houve um erro ao editar o candidato',
        submitButton: 'Editar candidato'
      }
    },
    CandidateAdd: {
      CandidateAdd: {
        title: 'Adicionar Candidato',
        addSuccess: 'Candidato criado com sucesso',
        addError: 'Houve um erro ao criar o candidato',
        submitButton: 'Criar candidato'
      },
      components: {
        CandidateForm: {
          nameField: 'Nome',
          phoneField: 'Telefone',
          emailField: 'E-mail',
          resumeField: 'Currículo',
          linkedinField: 'Linkedin',
          siteField: 'Site/Portfólio',
          sourceField: 'Fonte (Source)',
          resumePlaceholder: 'Upload de arquivos em .doc ou .pdf',
          sourcePlaceholder: 'Onde encontrou o candidato?',
          addPrepend: 'Adicionar',
          recommendation: 'Indicação'
        }
      }
    },
    Interviews: {
      InterviewsNew: {
        components: {
          InterviewsNewSidebar: {
            scheduledWith: 'Agendando com',
            myCalendars: 'Meus calendários',
            connectCalendarsButton: 'Sincronizar calendário',
            disconnectconnectCalendarsButton: 'Desconectar calendário',
            participate: 'Participando do processo',
            disconnectSuccess: 'Calendários desconectados com sucesso',
            disconnectError: 'Erro ao desconectar calendários. Tente novamente'
          },
          InterviewsNewScheduleModal: {
            prevButton: 'Voltar',
            dateLabel: 'Data',
            datePlaceholder: 'Ex: 14 de Agosto de 2019',
            fromLabel: 'De',
            fromPlaceholder: 'Ex: 12:00',
            toLabel: 'Até',
            toPlaceholder: 'Ex: 13:00',
            interviewTypeLabel: 'Tipo de entrevista',
            adressLabel: 'Endereço',
            adressPlaceholder: 'Ex: Av. Paulista, 2537 - Consolação - SP',
            inviteColleaguesLabel: 'Convide colegas',
            inviteColleaguesPlaceholder: 'Ex: email@email.com',
            inviteColleaguesText: `(adicione mais emails separando-os
                                    por vírgula)`,
            scheduleInterview: 'Agendar entrevista',
            rescheduleInterview: 'Reagendar entrevista',
            errorCreateMessage: 'Houve um erro ao agendar a entrevista',
            errorUpdateMessage: 'Houve um erro ao reagendar a entrevista',
            successCreateMessage: 'Entrevista agendada com sucesso',
            mediumOptions: {
              in_person: {
                label: 'Presencial',
                info: {
                  placeholder: 'Ex: Av. Paulista, 2537 - Consolação - SP',
                  label: 'Endereço'
                }
              },
              phone: {
                label: 'Telefone',
                info: {
                  placeholder: '99 9 9999 9999',
                  label: 'Número'
                }
              },
              other: {
                label: 'Online',
                info: {
                  placeholder: 'https://www.videochamada.org',
                  label: 'Link'
                }
              },
              event: {
                label: 'Evento',
                info: {
                  placeholder: 'Ex: Av. Paulista, 2537 - Consolação - SP',
                  label: 'Endereço'
                }
              },
              otherMediums: {
                label: 'Remoto'
              }
            }
          },
          InterviewsNewUnavailableScheduleModal: {
            title: 'Horário indisponível',
            prevButton: 'Cancelar',
            nextButton: 'Agendar mesmo assim',
            instructions: `O horário selecionado foi configurado como
                           indisponível pelo candidato. Recomendamos que você
                           confirme direto com o candidato ou escolha um
                           horário disponível.`
          }
        },
        InterviewsNew: {
          syncCalendars: 'Sincronize sua agenda',
          scheduleInterview: 'Agendar entrevista',
          rescheduleInterview: 'Reagendar entrevista',
          unavailableTimeName: 'Horário indisponível para o candidato',
          unavailableTimeTitle: 'Horário indisponível',
          externalCalendarTitle: 'Minha agenda',
          busyTitle: 'Ocupado',
          interviewsTitlePrefix: 'Entrevista com',
          defaultCalendarName: 'Entrevistas Revelo',
          loaderMessage: 'Carregando Calendario...'
        }
      }
    },
    JobPosting: {
      JobPosting: {
        goBack: 'Voltar para a vaga',
        title: 'Divulgar link da vaga - {positionTitle}',
        subtitle:
          'Você pode divulgar o link nas suas redes sociais e recrutar pessoas de fora da Revelo. Elas poderão se candidatar pela página que será gerada. Veja um exemplo {0}.',
        here: 'aqui',
        placeholder:
          '<p><strong>Sobre a empresa:</strong></p><p><br></p><p><strong>Responsabilidades:</strong></p><p><br></p><p><strong>Requisitos:</strong></p><p><br></p><p><strong>Benefícios:</strong></p><p><br></p><p><strong>Local:</strong></p><p><br></p>',
        description: 'Descrição da vaga:',
        success: 'Link criado com sucesso!',
        errorPublishing: 'Houve um erro ao publicar a vaga. Tente novamente.',
        errorCopying: 'Houve um erro ao copiar o link. Tente novamente.',
        successCopying: 'O link foi copiado para a sua área de transferência.'
      },
      components: {
        JobPostingActions: {
          copy: 'Copiar link',
          create: 'Criar link',
          linkLabel: 'Link para divulgação',
          save: 'Salvar',
          skip: 'Pular',
          acceptingApplicationsShort: 'Aceitando aplicações',
          acceptingApplicationsLong:
            'Candidatos podem aplicar para esta vaga através do link.',
          notAcceptingApplicationsShort: 'Aplicações desativadas',
          notAcceptingApplicationsLong:
            'Candidatos não podem aplicar para esta vaga.',
          googleJobsMessage:
            'Vamos registrar a sua vaga no Google Jobs assim que você criar o link de compartilhamento. Veja {0} de como vai ficar.',
          anExample: 'um exemplo'
        },
        LinkCreatedModal: {
          title: 'Link criado com sucesso!',
          label: 'Link para divulgação:',
          button: 'Copiar link'
        }
      }
    },
    Workflow: {
      Workflow: {
        copyPositionLink: 'Copiar link da vaga',
        publishPosition: 'Divulgar vaga',
        linkCopied: 'Link copiado para a área de transferência.',
        linkCopiedError: 'Houve um erro ao copiar o link. Tente novamente.',
        filters: {
          allCandidates: 'Todos os candidatos',
          myCandidates: 'Meus candidatos',
          candidatesFrom: 'Candidatos de {employerName}'
        },
        positionPublished: 'Vaga divulgada',
        addCandidate: 'Adicionar candidato(a)',
        positionSelect: {
          otherPositions: 'Outras vagas',
          myPositions: 'Minhas vagas'
        }
      }
    },
    WorkflowBoard: {
      components: {
        WorkflowAddToBoardModal: {
          approve: 'Gostei, quero entrevistar',
          currentSalary: 'Salário atual {salary}',
          desiredSalary: 'Pretensão {salary}',
          genericError: 'Tivemos um problema. Tente novamente.',
          reject: 'Não gostei',
          resume: 'CV de {name}',
          successAdded: 'Candidato adicionado ao processo.',
          successRejected: 'Candidato rejeitado.'
        },
        MoveCandidateModal: {
          title: 'Gostaria de automatizar essa ação?',
          firstParagraph:
            'Você moveu esse candidato para a fase de entrevista.',
          secondParagraph:
            'Deseja mover automaticamente todos os candidatos que aceitarem o convite para essa fase?',
          accept: 'Sim',
          refuse: 'Não'
        },
        MoveCandidateAcceptedModal: {
          title: 'Obrigada pelo feedback.',
          body: 'Estamos trabalhando nessa nova funcionalidade.',
          close: 'Fechar'
        },
        WorkflowStageCard: {
          new: 'novo'
        },
        StageCardCurrentState: {
          candidateAccepted: 'Aceitou o convite',
          awaitingSchedule: 'Agendar entrevista',
          nextInterviewCancelled: 'Entrevista cancelada',
          nextInterviewScheduled: 'Entrevista',
          applicantRefused: 'Candidato desistiu do processo',
          awaitingProposal: 'Proposta enviada',
          refusedProposal: 'Candidato recusou a proposta',
          acceptedProposal: 'Candidato aceitou a proposta',
          companyRejected: 'Candidato Reprovado',
          awaitingCandidateResponse: 'Convite enviado'
        },
        WorkflowCreateStage: {
          cancelButton: 'Cancelar',
          nextButton: 'Adicionar fase',
          titleName: 'Título',
          titlePlaceholder: 'Título da fase',
          kinds: {
            screened: 'Triagem',
            interview: 'Entrevista'
          },
          select: {
            assistive:
              'Escolha o tipo que mais se aproxima da atividade. Ex. Entrevista',
            placeholder: 'Selecione o tipo da fase',
            name: 'Tipo'
          }
        },
        WorkflowRecommendationStage: {
          titleMarketplace: 'Sugestões Revelo',
          titleHunters: 'Recomendações de Parceiros',
          body: 'Separamos alguns talentos compatíveis com a sua vaga',
          tryItForFree: 'Experimente grátis',
          seeCandidates: 'Quero ver candidatos'
        },
        WorkflowStage: {
          delete: 'Excluir fase',
          notificationSuccess: 'A fase foi excluída com sucesso',
          notificationProhibited:
            'Não foi possível excluir a fase. Mova todos os candidatos para outras fases primeiro',
          notificationError:
            'Um erro inesperado ocorreu. Não foi possível deletar a fase'
        },
        WorkflowStageTitle: {
          tooltipText:
            'Não é possivel renomear as fases \'Contratação\' e \'Reprovado\''
        },
        WorkflowStageDeleteModal: {
          title: 'Gostaria de excluir esta fase?',
          body: 'Esta ação não poderá ser desfeita no futuro.',
          confirm: 'Excluir fase',
          cancel: 'Cancelar'
        }
      }
    },
    Positions: {
      components: {
        ArchivePositionModal: {
          title: 'Arquivar a vaga {title}?',
          firstParagraph:
            'Você não conseguirá convidar mais candidatos, agendar entrevistas ou enviar propostas para quem está participando deste processo seletivo.',
          secondParagraph:
            'Não se preocupe, você pode desarquivar essa vaga depois. Deseja arquivar a vaga?',
          confirm: 'Arquivar vaga',
          cancel: 'Cancelar'
        },
        PositionCard: {
          remoteLocation: 'Remoto',
          inDraft: {
            status: 'Rascunho',
          },
          copySuccess: 'Link copiado',
          copyFailure: 'Houve um problema ao copiar o link',
          callsToAction: {
            archive: 'Arquivar vaga',
            copyLink: 'Copiar link',
            edit: 'Editar vaga',
            publish: 'Divulgar vaga',
            unarchive: 'Desarquivar vaga',
            seeDetails: 'Ver detalhes'
          },
          interviews: 'entrevistas',
          candidatesInProcess: 'candidatos em processo'
        },
        PositionShortlistCard: {
          remoteLocation: 'Remoto',
          inDraft: {
            status: 'Rascunho',
          },
          copySuccess: 'Link copiado',
          copyFailure: 'Houve um problema ao copiar o link',
          callsToAction: {
            archive: 'Arquivar vaga',
            copyLink: 'Copiar link',
            edit: 'Editar vaga',
            publish: 'Divulgar vaga',
            unarchive: 'Desarquivar vaga',
            seeDetails: 'Ver detalhes'
          },
          candidatesAwaitingReview: 'candidatos esperando revisão',
          candidatesInProcess: 'candidatos em processo'
        },
        PublishedPositionsModal: {
          title: 'Mural de vagas',
          copy: 'Copiar',
          openLink: 'Abrir link',
          editProfile: 'Editar perfil da empresa',
          tooltip:
            'Quando você divulgar uma vaga, ela vair aparecer automaticamente no seu mural de vagas.',
          publishedPositions: 'vagas publicadas',
          nonePublished: 'Nenhuma vaga publicada'
        },
        SortByCriteriaSelect: {
          label: 'Ordenar por',
          labels: {
            title: 'Ordem alfabética',
            applicantUpdatedAt: 'Novos candidatos',
            updatedAt: 'Vagas atualizadas'
          }
        }
      },
      Positions: {
        archivedError: 'Houve um erro ao tentar arquivar a vaga',
        archivedSuccess: 'Vaga arquivada com sucesso',
        newPosition: 'Criar vaga',
        publishedPositions: 'Mural de vagas',
        title: 'Vagas',
        unarchivedError: 'Houve um erro ao tentar desarquivar a vaga',
        unarchivedSuccess: 'Vaga desarquivada com sucesso',
        errorCopying: 'Houve um erro ao copiar o link. Tente novamente.',
        successCopying: 'O link foi copiado com sucesso!'
      },
      MyPositions: {
        title: 'Minhas vagas',
        createNewPositionTitle: 'Criar nova vaga com shortlist',
        createNewPositionButton: 'Criar vaga',
        positionsWithShortlistTitle: 'Minhas Vagas com shortlist',
        emptyState: {
          title: 'Você não tem nenhuma vaga aberta',
          description: 'Crie uma vaga e comece a contratar agora'
        },
        createPosition: {
          callToAction: 'Criar vaga'
        },
        isSorting: 'Atualizando',
        nextPage: 'Ver Mais'
      },
      ActivePositions: {
        positionsTitle: 'Vagas',
        createNewPositionTitle: 'Criar nova vaga com shortlist',
        createNewPositionButton: 'Criar vaga',
        positionsWithShortlistTitle: 'Vagas com shortlist',
        newBadge: 'Novidade',
        filter: {
          allPositions: 'Todas as vagas'
        },
        emptyState: {
          title: 'Nenhuma vaga aberta',
          titleWithEmployerName:
            '{employerName} não tem nenhuma posição ativa no momento',
          description: 'Crie uma vaga e comece a contratar agora'
        },
        createPosition: {
          callToAction: 'Criar vaga'
        },
        nextPage: 'Ver Mais',
        isSorting: 'Atualizando'
      },
      ArchivedPositions: {
        title: 'Vagas arquivadas',
        filter: {
          allPositions: 'Todas as vagas'
        },
        emptyState: {
          title: 'Nenhuma vaga arquivada',
          titleWithEmployerName:
            '{employerName} não tem nenhuma posição arquivada'
        },
        createPosition: {
          callToAction: 'Criar vaga'
        },
        nextPage: 'Veja Mais'
      }
    },
    PositionsCreate: {
      components: {
        PositionsCreateHeader: {
          title: 'Quem você está buscando hoje, {name}?',
          subtitle: 'Quanto melhor fica a sua descrição da vaga, mais perto você está da pessoa ideal!'
        },
        PositionsCreateTitle: {
          label: 'Título da vaga',
          placeholder: 'Nome da vaga'
        },
        PositionsCreateCareer: {
          assistiveText: 'Este nome ficará visível para os candidatos',
          label: 'Categoria',
          selectPlaceholder: 'Selecione uma opção',
          searchPlaceholder: 'O que a pessoa irá fazer?',
          tooltip: 'Usando nossa inteligência artificial, sugerimos para você algumas carreiras encontradas em nossa base.',
          otherCareers: 'Outras',
          unlisted: 'Outras carreiras'
        },
        PositionsCreateExperienceTime: {
          label: 'Experiência',
          years: 'anos',
          optionalFlag: '(opcional)',
          options: {
            noFilter: 'Qualquer experiência',
            upToThreeYears: 'Até 3 anos',
            moreThanThreeYears: '+3 anos',
            moreThanSixYears: '+6 anos'
          }
        },
        PositionsCreateSalary: {
          optionalFlag: '(opcional)',
          label: 'Qual a faixa salarial?',
          min: 'De',
          max: 'Até',
          assistive: {
            min: 'salário mínimo',
            max: 'salário máximo'
          },
          tip: 'Informação vista apenas por sua empresa',
          average: 'A média sugerida desse cargo é R$ {value}',
          companiesMessage: 'baseada em dados de 3.543 empresas'
        },
        PositionsCreateLocale: {
          label: 'Onde irá trabalhar?',
          placeholder: 'Digite uma cidade',
          localeRemote: {
            label: 'Vaga remota'
          }
        },
        PositionsCreateContractType: {
          label: 'Regime de contratação',
          notDefined: 'Não Definido'
        },
        PositionsCreateSkills: {
          label: 'Requisitos da vaga',
          placeholder: 'Habilidades, competências, softwares, etc',
          helper: 'Para essa carreira, empresas costumam pedir: ',
          tip: 'Digite cada requisito e pressione enter para inserir'
        },
        PositionsCreateBenefits: {
          label: 'Benefícios',
          placeholder: 'Vale refeição, vale transporte, plano de saúde, etc',
          helper: 'Algumas empresas adicionaram: ',
          tip: 'Digite cada benefício e pressione enter para inserir'
        },
        PositionsCreateAuthorizations: {
          title: 'Vaga confidencial',
          label: 'Adicione colegas que podem visualizar esta vaga',
          description: 'Somente você e pessoas selecionadas podem ver esta vaga',
          employerName: 'Digite o nome de um colega',
          optionalFlag: '(opcional)'
        },
        PositionsCreateDiversity: {
          diversityLabel: 'Diversidade',
          withDisability: 'Vaga exclusiva para PCD',
          diversityModalToggleButtonTitle: 'Saiba mais',
          diversityModalTitle: 'A Revelo acredita em um mercado mais inclusivo e plural.',
          diversityModalBodyText: 'Ao ativar esta opção, você recebe indicações apenas de pessoas com deficiência. Com o filtro desativado, você recebe indicação de todos os tipos de candidato.',
          diversityModalButtonText: 'Saiba mais sobre a Lei de Inclusão '
        },
        PositionsCreateSubmit: {
          submitCreateButton: 'Criar vaga',
          submitUpdateButton: 'Atualizar vaga',
          errorTakenTitle: 'Título da vaga duplicado',
          errorTakenMessage: 'Já existe uma vaga com esse título. Por favor, crie um título novo.',
          errorGenericTitle: 'Ops,',
          errorGeneric: 'ocorreu um erro'
        }
      }
    },
    SharedShortlist: {
      title: 'Shortlist de Candidatos'
    },
    SharedShortlistCard: {
      avatarUrlAlt: 'Avatar',
      descriptionTitle: 'Resumo',
      skillsTitle: 'Habilidades',
      salaryTitle: 'Pretensão salarial',
      profileCompletedButton: 'Perfil completo',
      scheduleMeeting: 'Marcar reunião?',
      seeMoreButton: 'Ver detalhes'
    },
    SharedShortlistCardResumeModal: {
      avatarUrlAlt: 'Avatar',
      descriptionTitle: 'Resumo'
    },
    Shortlist: {
      previousButtonText: 'Voltar para página de vagas',
      title: 'Shortlist de Candidatos',
      workflowRouteLink: 'Ir para Gestão de Talentos',
      actionSuccess: 'Ação realizada com sucesso',
      shareShortlist: 'Compartilhar shortlist',
      shareLinkSuccess: 'Link copiado com sucesso',
      genericError: {
        title: 'Tivemos um problema',
        message: 'Tente novamente daqui a pouco'
      },
      emptyState: {
        description: 'Todos os candidatos já foram revisados. Clique no botão a baixo para gerir seu processo seletivo.',
        callToAction: 'Acessar o Gestão de Talentos'
      }
    },
    ShortlistCard: {
      addError: 'Erro ao adicionar candidato',
      avatarUrlAlt: 'Avatar',
      descriptionTitle: 'Resumo',
      skillsTitle: 'Habilidades',
      salaryTitle: 'Pretensão salarial',
      profileCompletedButton: 'Perfil completo',
      scheduleMeeting: 'Marcar reunião?',
      seeMoreButton: 'Ver detalhes'
    },
    ShortlistCardRejectModal: {
      title: 'Por que você está reprovando este candidato?',
      reasonLabel: 'Motivo da reprovação',
      commentLabel: 'Comentário',
      feedbackCheckboxLabel: 'Não enviar feedback',
      cancelButton: 'Cancelar',
      submitButton: 'Reprovar',
      optionalFlag: '(Opcional)',
     errorNotificationTitle: 'Algo de errado aconteceu. Tente novamente mais tarde.'
    },
    ShortlistCardApproveModal: {
      title: 'Gostou deste candidato?',
      positionSelectLabel: 'Escolha uma vaga',
      createPositionLinkText: 'Criar uma nova vaga',
      message: 'Mensagem',
      submitButton: 'Entrar em contato'
    },
    ShortlistCardResumeModal: {
      avatarUrlAlt: 'Avatar',
      descriptionTitle: 'Resumo'
    },
    NewHome: {
      components: {
        InternalManagerCard: {
          subtitle: 'Conte com a gente, estamos aqui para ajudar',
          title: 'Gerente da sua conta'
        },
        PositionsListingHeader: {
          myPositions: 'Minhas vagas',
          applicantsTotal: 'No processo'
        },
        Positions: {
          title: 'Vagas',
          allPositions: 'Todas as vagas',
          emptyStateTitle: 'Crie uma vaga',
          emptyStateSubtitle:
            'As vagas que você estiver trabalhando vão aparecer aqui',
          createPosition: 'Criar vaga',
          loading: 'Carregando'
        },
        HowToUseYourSchedule: {
          text: 'Como organizar sua agenda'
        },
        InterviewsCard: {
          title: 'Entrevistas',
          emptyState: {
            subtitle: 'Agende uma entrevista',
            description:
              'Convide candidatos e candidatas e marque uma primeira conversa',
            button: 'Ver candidatos'
          },
          position: 'Vaga',
          upcomingTitle: 'Próximas entrevistas',
          seeMore: 'Ver mais',
          cancellationSucceeded: 'A entrevista foi cancelada com successo',
          cancellationFailed: 'Não foi possível cancelar a entrevista',
          HighlightedInterview: {
            position: 'Vaga',
            rescheduleLabel: 'Reagendar',
            cancelLabel: 'Cancelar'
          },
          InterviewDetails: {
            position: 'Vaga',
            rescheduleLabel: 'Reagendar',
            cancelLabel: 'Cancelar'
          },
          InterviewDateTime: {
            today: 'Hoje',
            tomorrow: 'Amanhã'
          },
          InterviewBadge: {
            getIntoRoom: 'Entrar na sala',
            badges: {
              in_person: 'Presencial',
              phone: 'Por telefone',
            }
          },
          InterviewCancellationModal: {
            title: 'Cancelando entrevista',
            cancellationQuestion:
              'Você tem certeza que deseja cancelar a entrevista agendada com esse candidato?',
            cancellationImplications:
              'Você vai remover esse evento do seu calendário sincronizado e do candidato.',
            positionTitle: 'Vaga: {title}',
            confirm: 'Sim, cancelar entrevista',
            close: 'Voltar'
          }
        },
        NextTasks: {
          title: 'Próximas tarefas',
          loading: 'Carregando',
          NextTasksTabs: {
            unscheduledInterview: 'Entrevista pendente',
            conductedInterview: 'Entrevista realizada',
            sentProposal: 'Proposta enviada'
          },
          UnscheduledInterviewsCard: {
            seeMore: 'Ver mais',
            loading: 'Carregando',
            emptyState: {
              title: 'Convide candidatos',
              paragraph:
                'Envie uma mensagem convidando as pessoas para participarem do processo seletivo da empresa',
              button: 'Ver candidatos'
            }
          },
          UnscheduledInterviewDetails: {
            scheduleInterview: 'Agendar entrevista',
            rejectCandidate: 'Reprovar candidato'
          },
          ConductedInterviewsCard: {
            seeMore: 'Ver mais',
            loading: 'Carregando',
            emptyState: {
              title: 'Agende entrevistas',
              paragraph:
                'As pessoas que você entrevistou aparecerão aqui para que você não perca o {0} do processo',
              timing: 'timing'
            }
          },
          ConductedInterviewDetails: {
            scheduleInterview: 'Agendar nova entrevista',
            formalizeHiring: 'Informar contratação',
            sendProposal: 'Enviar proposta',
            rejectCandidate: 'Reprovar candidato'
          },
          SentProposalsCard: {
            seeMore: 'Ver mais',
            loading: 'Carregando',
            emptyState: {
              title: 'Formalize uma proposta',
              paragraph:
                'Envie uma proposta ou formalize a contratação de uma pessoa pela Revelo'
            }
          },
          SentProposalDetails: {
            sendMessage: 'Enviar mensagem',
            rejectCandidate: 'Reprovar candidato'
          }
        },
        JobPostingsCard: {
          title: 'Mural de vagas',
          copy: 'Copiar link',
          errorCopying: 'Houve um erro ao copiar o link. Tente novamente.',
          successCopying:
            'O link foi copiado para a sua área de transferência.',
          jobsPostedCount: 'vagas divulgadas',
          total: '(total)',
          JobPostingsPage: 'Ver sua página de carreiras',
          editProfile: 'Editar perfil da empresa',
          careerPage: 'Ver sua página de carreiras',
          subtitle: `Todas as suas vagas divulgadas aparecem neste Mural de vagas.
          Você pode usá-lo para divulgar a vaga nas suas redes sociais.
          Os candidatos que aplicarem por lá vão aparecer na tela de triagem da sua vaga, junto aos candidatos Revelo`
        },
        ApplicantModalReject: {
          cancel: 'Cancelar',
          reject: 'Reprovar',
          successMessage: 'Candidato reprovado com sucesso',
          errorMessage: 'Não foi possível reprovar o candidato'
        },
        RecommendationsCard: {
          title: 'Novidades do Feed de talentos',
          recommendedNewCandidatesNumber:
            'Não chegou ninguém | Chegou 1 pessoa | Chegaram {number} pessoas',
          recommendedNewCandidatesAdherent: 'aderentes | aderente | aderentes',
          yourPosition: 'à sua vaga',
          RecommendationsSelectGroups: {
            myPositions: 'Minhas vagas',
            otherPositions: 'Outras vagas'
          },
          RecommendationsEmptyStateDraft: {
            subtitle: 'Precisamos de mais dados para te recomendar profissionais',
            description: 'Complete essa vaga com mais informações para ver aqui {emphasis} à sua vaga',
            adherentEmphasis: 'profissionais 100% aderentes',
            button: 'Completar vaga'
          },
          RecommendationsEmptyStateCandidates: {
            subtitle:
              'Estamos buscando profissionais 100% aderentes para te recomendar',
            description:
              'Enquanto isso, você pode editar essa vaga ou ir ao Feed de talentos e fazer novas combinações de filtros para encontrar mais profissionais',
            button: 'Mostrar Feed de talentos',
            editButton: 'Editar Vaga'
          },
          RecommendationsEmptyStatePositions: {
            subtitle: 'Quer ver apenas profissionais que têm tudo a ver com a sua vaga?',
            description: 'Deixe o trabalho pesado com a gente. Crie sua primeira vaga e veja {emphasis}',
            adherentEmphasis: 'profissionais 100% aderentes que acabaram de chegar',
            button: 'Criar vaga'
          },
          RecommendationsFinishedCandidates: {
            subtitle: 'Ir para o Feed de talentos',
            description:
              'Você já viu todos os novos perfis 100% aderentes. No Feed de talentos, você pode testar outras combinações de filtro!',
            button: 'Mostrar Feed de talentos'
          },
          RecommendationsCardCandidateDetails: {
            minimumSalary: 'Pretensão salarial',
            currentJobTitle: 'Cargo Atual',
            principalSkills: 'Skills principais',
            principalSkillsSeparator: ' e ',
            emptyPrincipalSkills: 'Nenhuma cadastrada',
            experienceTime: {
              zeroYears: 'Quero Começar',
              betweenZeroAndThreeYears: '0 - 3 anos',
              betweenThreeAndSixYears: '3 - 6 anos',
              moreThanSixYears: '+ de 6 anos'
            },
            emptyExperienceTime: '-'
          }
        },
        PlanInfoCard: {
          Light: 'Plano Mensal',
          'Pré-pago': 'Plano Pré-Pago',
          Freedom: 'Plano ilimitado'
        },
        PlanInfo: {
          FreedomPlanInfo: {
            validUntil: 'Válido até'
          },
          LightPlanInfo: {
            lightPlanNotice: '{emphasis} a cada contratação profissional, adicionamos o valor de R$1.999 na sua fatura.',
            lightPlanNoticeEmphasis: 'Importante:',
            lightPlanPrice: 'R$349',
            managePlanButton: 'Gerenciar plano'
          },
          PrePaidPlanInfo: {
            availableCredits: 'Créditos adquiridos',
            creditsValidUntil: 'Validade dos Créditos',
            usedCredits: 'Créditos utilizados no feed',
            validUntil: 'Válido até',
            buyCredits: 'Comprar créditos'
          }
        },
        BannerEndOfPlan: {
          FreedomPlanTitle:
            'Atenção: seu plano expira em {days}. Você perderá acesso à plataforma dia {date}',
          PrePaidPlanTitle:
            'Atenção: seus créditos expiram em {days}. Você perderá acesso à plataforma dia {date}',
          PrePaidPlanCreditsOverTitle:
            'Todos os seus créditos foram utilizados. Você perderá acesso à plataforma dia {date}',
          description:
            'Sem um plano ativo, as contratações de profissionais são cobradas à parte.',
          button: 'Conversar com gerente da conta'
        },
        SignalHiringCard: {
          title: 'Contratou alguém pela Revelo?',
          content: 'Agora, você pode nos informar todas as suas contratações em poucos cliques!',
          link: 'Informar contratação',
          news: 'novidade'
        }
      }
    },
    Signup: {
      components: {
        SignupSwitchToEnglish: {
          redirectButtonText: 'Switch to the English website.'
        }
      },
      SignupCompany: {
        title: 'Que bom que você chegou!',
        description: 'Precisamos de mais algumas informações antes de avançarmos.',
        labels: {
          companyHasCnpj: 'Sua empresa possui CNPJ?',
          employerName: 'Qual é seu nome completo?',
          name: 'Para qual empresa você trabalha?',
          phone: 'Qual é seu telefone? (Comercial, de preferência)',
          hasCnpjTrue: 'Sim',
          hasCnpjFalse: 'Não'
        },
        placeholders: {
          employerName: 'Nome',
          name: 'Nome da empresa',
          phone: 'Telefone'
        },
        nextButton: 'Avançar'
      },
      SignupSdrSchedule: {
        title: 'É hora de conversar com nosso time de especialistas!',
        description: `Quer ter acesso aos melhores talentos para sua empresa?
          Agende um papo com nosso time para conhecer nossos planos e preços.`
      },
      SignupSearchFilter: {
        errors: {
          message: 'Preencha todos os campos para poder prosseguir'
        },
        title: '{name}, vamos encontrar o talento certo para sua empresa',
        lastStepDescription: 'Está acabando. Essa é a última etapa do seu cadastro!',
        labels: {
          career: 'Qual tipo de profissional você busca?',
          englishRequired: 'A pessoa vai precisar se comunicar em inglês?',
          suggestedSkills: 'Quais são as três habilidades mais importantes?',
          englishRequiredTrue: 'Sim',
          englishRequiredFalse: 'Não'
        },
        placeholders: {
          careerSearch: 'Procurar por profissionais com experiência em ...',
          careerSelect: 'Selecione um tipo de profissional',
          skills: 'Coloque aqui as habilidades mais importantes',
        },
        searchCandidates: 'Buscar candidatos'
      },
      SignupSearchSteps: {
        title: {
          performingAnalysis: 'Analisando milhares de currículos...',
          filtering: 'Filtrando candidatos por habilidades...',
          checkingAvailability: 'Checando disponibilidade dos candidatos...',
          results: 'Analisamos <b>13 mil perfis</b>. <br/>Confira quais selecionamos para você!'
        },
        redirectButtonText: 'Mostrar profissionais'
      },
      Fail: {
        components: {
          SignupFailTemplate: {
            backButton: 'Voltar para Login',
            helpButton: 'Preciso de ajuda para acessar'
          }
        },
        AccessRequestSent: {
          title: 'Pedido de <strong>acesso</strong> enviado',
          line1: 'Quando seu acesso for liberado, você receberá um e-mail com instruções para validar sua conta.',
          success: 'Pedido enviado com sucesso!',
        },
        ActiveCompany: {
          title: 'Sua <strong>empresa</strong> já tem cadastro na Revelo',
          line1: 'Você precisa pedir acesso para quem administra esta conta.',
          line2: 'Converse com sua empresa e junte-se ao seu time na busca pelos melhores talentos do mercado de tecnologia.',
          accessButton: 'Quero pedir acesso',
          warning: '<strong>{emailDomain}</strong> já está cadastrada.',
          notificationFail: 'Tente novamente mais tarde.'
        },
        InactiveCompany: {
          title: 'Sua <strong>empresa</strong> já tem cadastro na Revelo',
          line1: 'Vimos que a sua empresa já tem cadastro na Revelo, mas não tem um plano ativo para poder acessar.',
          line2: 'Desenvolvemos novos planos que se adequam às necessidades de contratação da sua empresa.',
          line3: 'Entre em contato com a gente para continuar contratando os melhores talentos do mercado de tecnologia.',
          email: 'empresas@revelo.com',
          phone: '(11) 3571-6651',
          contactButton: 'Conversar com time de vendas',
          warning: '<strong>{emailDomain}</strong> já está cadastrada.'
        },
        InvalidEmail: {
          title: 'Crie sua conta <strong>Revelo</strong>',
          line1: 'Você precisa usar seu <strong>e-mail corporativo</strong> para criar uma conta na Revelo.',
          line2: 'Ex: seunome@suaempresa.com.br',
          accessButton: 'Usar outro e-mail',
          warning: 'Não é possível criar uma conta com esse e-mail.'
        }
      }
    },
    Invites: {
      InviteSent: {
        talentFeedButtonText: 'Continuar buscando candidatos',
      },
      InvitesNew: {
        title: 'Gostou deste candidato?',
        update: 'Atualizar vagas:',
        loading: 'Atualizando...',
        choosePosition: 'Escolha uma vaga',
        newJob: 'Criar nova vaga',
        titleField: 'título',
        salaryTitle: 'Faixa salarial',
        salaryDescription: 'pretensão R$',
        myJobs: 'Minhas Vagas',
        otherJobs: 'Outras Vagas',
        createPositionLabel: 'Título da vaga',
        contractType: 'Regime de contratação',
        contractTypeField: 'regime de contratação',
        salaryEstimation: 'Estimativa salarial',
        estimationField: 'estimativa salarial',
        rangeBelow: 'Pouco abaixo',
        rangeAligned: 'Alinhado',
        rangeAbove: 'Pouco acima',
        positionDescription: 'Mensagem para o candidato',
        defaultTemplateDescription: 'Olá, {{primeiro-nome}},' +
        '\n\nMe chamo {{meu-nome}} e estou à procura de uma pessoa para fazer parte do nosso time na posição de {{titulo-processo}}.' +
        '\n\nEm minhas buscas, encontrei o seu perfil e gostei muito dele! Adoraria bater um papo para te conhecer melhor e mostrar um pouco mais sobre nossos interesses, valores e propósito.' +
        '\n\nUm pouco mais sobre nós:' +
        '\n\n{{sobre-empresa}}' +
        '\n\nTem interesse?' +
        '\n\nAbraços',
        descriptionField: 'mensagem',
        sendInvite: 'Entrar em contato',
        insightsTitle: 'Atratividade do texto',
        clickHere: 'Clique aqui',
        callToInsights: 'e confira a atratividade do seu texto',
        confirmation: {
          redirect: {
            text: 'Parabéns! Seu convite já foi enviado! Você será redirecionado em instantes. Caso não aconteça,',
            callToAction: 'clique aqui'
          }
        }
      },
      InvitesNewModal: {
        title: 'Gostou deste candidato?',
        update: 'Atualizar vagas:',
        loading: 'Atualizando...',
        choosePosition: 'Escolha uma vaga',
        newJob: 'Criar nova vaga',
        titleField: 'título',
        salaryTitle: 'Faixa salarial',
        salaryDescription: 'pretensão R$',
        myJobs: 'Minhas Vagas',
        otherJobs: 'Outras Vagas',
        createPositionLabel: 'Título da vaga',
        contractType: 'Regime de contratação',
        contractTypeField: 'regime de contratação',
        salaryEstimation: 'Estimativa salarial',
        estimationField: 'estimativa salarial',
        rangeBelow: 'Pouco abaixo',
        rangeAligned: 'Alinhado',
        rangeAbove: 'Pouco acima',
        positionDescription: 'Mensagem para o candidato',
        defaultTemplateDescription: 'Olá, {{primeiro-nome}},' +
        '\n\nMe chamo {{meu-nome}} e estou à procura de uma pessoa para fazer parte do nosso time na posição de {{titulo-processo}}.' +
        '\n\nEm minhas buscas, encontrei o seu perfil e gostei muito dele! Adoraria bater um papo para te conhecer melhor e mostrar um pouco mais sobre nossos interesses, valores e propósito.' +
        '\n\nUm pouco mais sobre nós:' +
        '\n\n{{sobre-empresa}}' +
        '\n\nTem interesse?' +
        '\n\nAbraços',
        descriptionField: 'mensagem',
        sendInvite: 'Entrar em contato',
        insightsTitle: 'Atratividade do texto',
        clickHere: 'Clique aqui',
        callToInsights: 'e confira a atratividade do seu texto',
        confirmation: {
          redirect: {
            text: 'Parabéns! Seu convite já foi enviado! Você será redirecionado em instantes. Caso não aconteça,',
            callToAction: 'clique aqui'
          }
        }
      },
      components: {
        InviteSummary: {
          fetchingSummary: 'Buscando informações do convite...',
          invitedCandidateTooltip: 'Ver processo com candidato',
          invite: {
            header: 'Informações do processo',
            sent: 'Seu convite foi enviado!',
            sentTo: 'recebeu seu convite!',
            waitText: 'Agora é só aguardar a resposta.'
          },
          counting: {
            title: 'Foi realizado nessa vaga',
            invites: 'Convites',
            interviews: 'Entrevistas'
          },
          details: {
            career: 'Vaga',
            experienceTime: {
              label: 'Tempo de experiência',
              values: {
                zero_years: 'Quero começar!',
                between_zero_and_three_years: '0 - 3 anos',
                between_three_and_six_years: '3 - 6 anos',
                more_than_six_years: '+ de 6 anos'
              },
            },
            header: 'Informações do processo',
            location: 'Cidade',
            salaryRange: 'Faixa Salarial',
            salaryType: 'Contratação',
            skills: {
              label: 'Habilidades',
              jointChar: 'e'
            }
          }
        },
        SimilarCandidates: {
          SimilarCandidateCard: {
            salaryExpectation: 'Pretensão salarial',
            skillsSeparator : 'e',
            experienceTime: {
              zero_years: 'Quero começar!',
              between_zero_and_three_years: '0 - 3 anos',
              between_three_and_six_years: '3 - 6 anos',
              more_than_six_years: '+ de 6 anos'
            },
            viewProfileText: 'Ver perfil completo'
          },
          title: 'Candidatos similares',
          fetchingSimilarCandidates: 'Estamos buscando candidatos similares a quem você acabou de convidar...',
          description: 'Separamos alguns candidatos parecidos com o perfil que você está procurando.',
          error: {
            description: 'Ocorreu um erro ao buscar os candidatos similares',
            callToAction: 'Buscar novamente'
          },
          highlight: 'Não perca tempo e acelere seu processo de triagem!'
        },
        InvitesDescription: {
          descriptionField: 'descrição',
          positionDescription: 'Descrição',
          placeholder: 'Escreva uma mensagem para o candidato',
          tags: {
            candidateName: 'primeiro-nome',
            employerName: 'meu-nome',
            companyDescription: 'sobre-empresa',
            positionTitle: 'titulo-processo',
            candidateNamePlaceholder: 'Nome do candidato',
            employerNamePlaceholder: 'Meu nome',
            companyDescriptionPlaceholder: 'Descrição da empresa',
            positionTitlePlaceholder: 'Título da vaga'
          },
          tip: 'Digite {trigger} para adicionar campos dinâmicos'
        },
        InvitesInsights: {
          title: 'Insights da mensagem',
          score: {
            title: 'Atratividade do texto:',
            description: {
              empty: 'Cole a descrição da sua vaga ao lado para avaliarmos a atratividade do seu texto.',
              default: 'Veja a dicas abaixo. Quanto mais atrativa, melhor.'
            }
          },
          readingTime: {
            title: 'Tempo de leitura',
            description: {
              empty: 'Medimos o tempo médio de leitura do seu texto.',
              default: 'Seu texto demora {time} para ser lido'
            }
          },
          textSize: {
            title: {
              empty: 'Tamanho do texto',
              default: 'Tamanho do texto: {size} caracteres.'
            },
            description: {
              empty: 'Seu texto deve ser descritivo e objetivo.',
              shortText: 'Muito curto, explore mais conteúdo.',
              okText: 'Seu texto está com um tamanho legal.',
              longText: 'Muito comprido, procure ser mais objetivo.'
            }
          },
          readability: {
            title: 'Facilidade de leitura',
            description: {
              empty: 'Medimos a facilidade de leitura.',
              success: 'Seu texto é fácil de ler!',
              error: 'Seu texto é difícil de ler.'
            }
          },
          sentimentAnalyze: {
            title: 'Análise de sentimento',
            description: {
              empty: 'A análise de sentimento mostra o quanto seu texto tem um tom geral positivo (recomendável) ou negativo.',
              success: 'Seu texto possui um tom positivo.',
              warning: 'Seu texto possui um tom neutro',
              error: 'Seu texto possui um tom negativo'
            }
          }
        },
        InvitesSalaryRange: {
          minPlaceholder: 'Mínima',
          maxPlaceholder: 'Máxima'
        }
      }
    }
  },
  validation: {
    messages: {
      _default: 'O valor do campo \'{0}\' não é válido',
      required: 'O campo é obrigatório',
      money_required: 'O campo \'{0}\' é obrigatório',
      money_min: 'O valor deve ser maior que {1}',
      money_max: 'O campo \'{0}\' deve ser menor que R${1}',
      minValue: 'O valor deve ser maior que {min}',
      maxValue: 'O valor deve ser menor que {max}',
      maxLength: 'O campo deve ter no máximo {max} caracteres',
      location: 'O local não é válido, selecione outro local'
    }
  },
  rejection: {
    reasons: {
      profile: 'Não tem o perfil comportamental adequado',
      technical_knowledge: 'Conhecimento técnico insuficiente',
      seniority: 'Senioridade insuficiente',
      education: 'Formação acadêmica insuficiente',
      desired_salary: 'Pretensão salarial muito alta',
      hired_other: 'A vaga foi preenchida ou cancelada',
      other_reason: 'Outra razão',
      hired_by_other_company: 'Candidato já foi contratado por outra empresa',
      missed_interview: 'Candidato faltou à entrevista',
      candidate_gave_up: 'Candidato desistiu da vaga',
      none: 'Motivo não informado'
    }
  },
  talentDisqualifiedReasons: {
    other: "Outro",
    salaryExpectation: "Expectativa Salarial fora do esperado",
    seniority: "Baixa Senioriedade",
    technicalSkills: "Habilidades Técnicas",
  },
  date: {
    local_time: 'Horário local',
    brazilian_timezone: 'Brasília',
    units: {
      seconds: 'segundo | segundos',
      minutes: 'minuto | minutos',
      hours: 'hora | horas',
      days: 'dia | dias',
      weeks: 'semana | semanas',
      months: 'mês | meses',
      years: 'ano | anos'
    }
  },
  languages: {
    japanese: 'Japonês',
    russian: 'Russo',
    korean: 'Koreano',
    dutch: 'Holandês',
    hebrew: 'Hebraico',
    arabic: 'Arabe',
    hindi: 'Indiano',
    english: 'Inglês',
    spanish: 'Espanhol',
    french: 'Francês',
    german: 'Alemão',
    italian: 'Italiano',
    mandarin_chinese: 'Mandarin'
  }
}
