export default {
  validate (value) {
    const emailRegex =
    /^[^\s:]*(?!\.)@(?!\.)(?:[^_\s,.]+\.)+[^_\s,.>~]{2,}$/i

    return value.split(',')
      .map(email => email.trim())
      .every(email => email.match(emailRegex))
  }
}
