import repository from '@/repository/external-calendars'

export default {
  async getExternalCalendars ({ commit }) {
    commit('SET_IS_FETCHING_EXTERNAL_EVENTS', true)

    const responseCalendars = await repository.getCalendars()
    commit('SET_EXTERNAL_CALENDARS', [])
    if (responseCalendars.length) {
      const responseEvents = await repository.getEvents((new Date()).toISOString())

      commit('SET_EXTERNAL_CALENDARS', responseCalendars)
      commit('SET_EXTERNAL_EVENTS', responseEvents)
    }

    commit('SET_IS_FETCHING_EXTERNAL_EVENTS', false)
  },

  async getExternalEvents ({ commit }, date) {
    commit('SET_IS_FETCHING_EXTERNAL_EVENTS', true)

    const response = await repository.getEvents((date))

    commit('SET_EXTERNAL_EVENTS', response)
    commit('SET_IS_FETCHING_EXTERNAL_EVENTS', false)
  },

  async setSelectedCalendars ({ commit }, { date, calendars }) {
    commit('SET_IS_FETCHING_EXTERNAL_EVENTS', true)

    const response = await repository.selectCalendars(date, calendars)

    commit('SET_SELECTED_CALENDARS', calendars)
    commit('SET_EXTERNAL_EVENTS', response)
    commit('SET_IS_FETCHING_EXTERNAL_EVENTS', false)
  }
}
