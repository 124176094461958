import interviews from '@/repository/interviews'

export default {
  async getInterviews ({ commit }, selectedDate) {
    const response = await interviews.getEmployersInterviews(selectedDate)
    commit('SET_INTERVIEWS', response)
  },
  async cancelInterview ({ commit }, interviewId) {
    await interviews.cancelInterview(interviewId)
    commit('REMOVE_UPCOMING_INTERVIEW', interviewId)
  },
  async updateInterview ({ dispatch }, params) {
    await interviews.updateInterview(params)
    dispatch('getInterviews', params.interview.scheduledStartAt)
  },
  async getEmployersUpcomingInterviews ({ commit }, page = 1) {
    if (page === 1) commit('SET_LOADING_INTERVIEWS', true)
    else commit('SET_LOADING_MORE_INTERVIEWS', true)

    const { data, meta } = await interviews.getEmployersUpcomingInterviews(page)

    if (page === 1) commit('SET_UPCOMING_INTERVIEWS', data)
    else commit('ADD_UPCOMING_INTERVIEWS', data)
    commit('SET_UPCOMING_INTERVIEWS_PAGE', meta.page)
    commit('SET_UPCOMING_INTERVIEWS_TOTAL_PAGES', meta.totalPages)

    commit('SET_LOADING_MORE_INTERVIEWS', false)
    commit('SET_LOADING_INTERVIEWS', false)
  }
}
