import { get, post, del } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import { toCamelCase } from '@/helpers/case-style'
import deepObjectLopper from '@/helpers/deep-object-mapper'
import { companiesUrl } from '@/helpers/constants/external-urls'

const baseUrl = `${companiesUrl}/api/v2/wish_lists`

export default {
  async getWishLists () {
    const response = await get(baseUrl)

    return JSONAPIClient.parse(response.data)
  },

  async create (name) {
    const params = {
      data: {
        attributes: {
          name
        }
      }
    }

    try {
      const response = await post(baseUrl, params)
      return response.data.data
    } catch (error) {
      const errorParsed = deepObjectLopper(error, toCamelCase)
      throw errorParsed
    }
  },

  async addCandidate ({ wishListId, candidateId }) {
    const params = {
      data: {
        attributes: {
          candidate_id: candidateId
        }
      }
    }

    const response = await post(`${baseUrl}/${wishListId}/candidates`, params)
    return response.data.data
  },

  async deleteCandidate ({ wishListId, candidateId }) {
    const response = await del(`${baseUrl}/${wishListId}/candidates/${candidateId}`)
    return (response.status === 200)
  }
}
