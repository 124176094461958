import interviews from './interviews'
import newHome from './new-home'
import outsources from './outsources'
import workflow from './workflow'
import candidates from './candidates'
import positions from './positions'
import invites from './invites'

import Positions from '@/pages/Positions/Positions'
import Shortlist from '@/pages/Shortlist/Shortlist'

export default [
  {
    path: '/',
    component: Positions,
    children: [
      {
        path: 'active',
        name: 'ActivePositions',
        component: () => import('@/pages/Positions/ActivePositions')
      },
      {
        path: 'archived',
        name: 'ArchivedPositions',
        component: () => import('@/pages/Positions/ArchivedPositions')
      },
      {
        path: '',
        name: 'Positions',
        component: () => import('@/pages/Positions/MyPositions')
      }
    ]
  },
  {
    name: 'Shortlist',
    path: 'shortlist/:id',
    props:true,
    component: Shortlist
  },
  {
    path: 'publish/:positionId',
    name: 'JobPosting',
    component: () => import('@/pages/JobPosting/JobPosting')
  },
  {
    path: '/access-verification',
    name: 'AccessVerification',
    component: () => import('@/pages/AccessVerification/AccessDenied')
  },
  {
    path: 'candidates',
    component: () => import('@/pages/Candidates/Candidates'),
    children: candidates
  },

  ...interviews,
  ...invites,
  ...newHome,
  ...outsources,
  ...positions,
  ...workflow,
]
