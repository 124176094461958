export default {
  SET_WISH_LISTS (state, wishLists) {
    state.wishLists = wishLists
  },

  SET_IS_FETCHING_WISH_LISTS (state, isFetchingWishLists) {
    state.isFetchingWishLists = isFetchingWishLists
  },

  SET_IS_CREATING_WISH_LIST (state, isCreatingWishList) {
    state.isCreatingWishList = isCreatingWishList
  },

  ADD_WISH_LIST (state, wishList) {
    state.wishLists.push(wishList)
  },

  ADD_CANDIDATE (state, { wishListId, candidateId }) {
    state
      .wishLists
      .find((wishList) => wishList.id === wishListId)
      .candidates
      .push({ id: candidateId })
  },

  REMOVE_CANDIDATE (state, { wishListId, candidateId }) {
    const wishList = state
      .wishLists
      .find((wishList) => wishList.id === wishListId)

    wishList.candidates = wishList.candidates
      .filter((candidate) => candidate.id !== candidateId)
  }
}
