import { sanitizeHref } from '@/helpers/sanitize-url'

export default function createCandidate (candidate) {
  return {
    ...candidate,

    get hasBasicInfo () {
      return Boolean(this.workSummary && this.name)
    },

    get sanitizedLinkedin () {
      return sanitizeHref(this.linkedin)
    },

    get sanitizedBlog () {
      return sanitizeHref(this.blog)
    },

    get sanitizedGithub () {
      return sanitizeHref(this.github)
    },

    get sanitizedPortifolio () {
      return sanitizeHref(this.portifolio)
    },

    get sanitizedDribbble () {
      return sanitizeHref(this.dribbble)
    },

    get sanitizedBehance () {
      return sanitizeHref(this.behance)
    },

    get sanitizedStackoverflow () {
      return sanitizeHref(this.stackoverflow)
    },

    get sanitizedCv () {
      return sanitizeHref(this.cv)
    }
  }
}
