import repository from '@/repository/companies-app/employers'

export default {
  async getEmployers ({ commit }) {
    commit('SET_IS_FETCHING_EMPLOYERS', true)
    const response = await repository.getEmployers()
    commit('SET_EMPLOYERS', response)
    commit('SET_IS_FETCHING_EMPLOYERS', false)
  }
}
