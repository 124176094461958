<template>
  <div class="multiple-wrapper">
    <div class="multiple">
      <input
        v-money="money"
        class="multiple__input"
        :class="[errorClass]"
        :placeholder="minPlaceholder"
        :value="minSalary"
        id="min-salary"
        @change="handleMinSalary"
        data-cy="invite-min-salary"
      >
      <input
        v-money="money"
        class="multiple__input"
        :class="[errorClass]"
        :placeholder="maxPlaceholder"
        :value="maxSalary"
        id="max-salary"
        @change="handleMaxSalary"
        data-cy="invite-max-salary"
      >
    </div>
    <app-assistive-text
      :assistive-text="error"
      :variant="assistiveVariant"
    />
  </div>
</template>

<script>
import { numberFromSalary } from '@/helpers/salary-helpers'
import { VMoney } from 'v-money'
import { validateField } from '@revelotech/everest'

export default {
  name: 'InvitesSalaryRange',
  directives: {
    money: VMoney
  },
  mixins: [validateField],
  props: {
    maxSalary: {
      type: Number,
      default: 0
    },
    minSalary: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      langPath: __langpath,
      minPlaceholder: this.$t(
        `${__langpath}.minPlaceholder`
      ),
      maxPlaceholder: this.$t(
        `${__langpath}.maxPlaceholder`
      ),
      money: {
        thousands: '.',
        prefix: 'R$ ',
        precision: 0,
        masked: false
      }
    }
  },
  computed: {
    assistiveVariant () {
      if (this.error) {
        return 'error'
      }
      return ''
    },

    errorClass () {
      if (this.error) {
        return 'multiple__input--error'
      }
      return ''
    },

    salaryRange () {
      return {
        minSalary: this.minSalary,
        maxSalary: this.maxSalary
      }
    }
  },
  methods: {
    handleMinSalary (e) {
      const newValue = this.convertMoneyToNumber(e.target.value)
      if (this.minSalary !== newValue) {
        this.$emit('update:minSalary', Number(newValue))
      }
    },
    handleMaxSalary (e) {
      const newValue = this.convertMoneyToNumber(e.target.value)
      if (this.maxSalary !== newValue) {
        this.$emit('update:maxSalary', Number(newValue))
      }
    },
    convertMoneyToNumber (input) {
      return numberFromSalary(input)
    }
  }
}
</script>

<style lang="scss" >
.multiple {
  display: flex;
  justify-content: space-between;

  @include breakpoint(md) {
    flex-direction: column;
  }

  @include breakpoint(lg) {
    flex-direction: row;
  }

  &__input {
    @extend %input-text;

    width: 49%;

    @include breakpoint(md) {
      width: 100%;

      &:nth-child(2) {
        @include margin(top, 2);
      }
    }

    @include breakpoint(lg) {
      width: 49%;

      &:nth-child(2) {
        @include margin(top, 0);
      }
    }

    &--error {
      border: 1px solid var(--b-error);

      &:focus {
        outline: 2px solid var(--b-error);
      }
    }
  }
}
</style>
