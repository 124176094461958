import createCandidate from '@/models/candidate'

export default {
  ADD_CANDIDATE (state, candidate) {
    state.candidates = [
      ...state.candidates.filter(({ id }) => id !== candidate.id),
      createCandidate(candidate)
    ]
  },

  ADD_QUESTION (state, question) {
    state.questions.unshift(question)
  },

  SET_UNANSWARED_QUESTIONS (state, unansweredQuestions) {
    state.unansweredQuestions = unansweredQuestions
  },

  SET_QUESTIONS (state, questions) {
    state.questions = questions
  },

  SET_IS_FETCHING_QUESTIONS (state, isFetchingQuestions) {
    state.isFetchingQuestions = isFetchingQuestions
  },

  SET_CANDIDATE_EXTERNAL_EVALUATIONS (state, evaluations) {
    state.evaluations = evaluations
  },

  SET_TAGS (state, tags) {
    state.tags = tags
  },

  SET_CANDIDATE_UNAVAILABILITIES (state, value) {
    state.unavailabilities = value
  },

  SET_IS_FETCHING_CANDIDATE_UNAVAILABILITIES (state, isFetchingUnavailabilities) {
    state.isFetchingUnavailabilities = isFetchingUnavailabilities
  },

  SET_RECOMMENDED_CANDIDATES (state, recommendedCandidates) {
    state.recommendedCandidates = recommendedCandidates
  },

  SET_FAVORITE_MODAL (state, value) {
    state.favoriteModal = value
  }
}
