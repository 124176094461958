import signupRepo from '@/repository/companies-app/signup'

export default {
  async getSearchUrl ({ commit }, payload) {
    const response = await signupRepo.getSearchUrl(payload)
    commit('SET_SEARCH_URL', response.url)
  },

  setSearchParams ({ commit }, searchParams) {
    commit('SET_SEARCH_PARAMS', searchParams)
  }
}
