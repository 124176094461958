export default [
  {
    name: 'SharedCandidateProfile',
    path: 'candidates/shared/:sharedCandidateToken',
    component: () => import('@/pages/PublicAccess/SharedCandidateProfile.vue')
  },
  {
    name: 'SharedShortlist',
    path: 'shortlists/shared/:sharedShortlistToken',
    component: () => import('@/pages/PublicAccess/SharedShortlist.vue')
  }
]
