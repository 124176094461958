<template>
  <div
    class="full-access"
    data-test="full-access-container"
  >
    <offscreen />
    <the-header />
    <the-overlay />
    <the-notification-card-list />
    <router-view />
  </div>
</template>

<script>
import Offscreen from '@/components/Offscreen'
import TheHeader from '@/components/TheHeader'
import authenticationGuard from '@/mixins/authentication-guard'
import { TheOverlay, TheNotificationCardList } from '@revelotech/everest'

export default {
  name: 'FullAccess',

  components: {
    Offscreen,
    TheHeader,
    TheOverlay,
    TheNotificationCardList
  },

  mixins: [
    authenticationGuard
  ]
}
</script>

<style lang="scss">
.full-access {
  height: 100vh;
  overflow: auto;
}

.app-modal {
  left: initial;
  transform: initial;
}

.the-overlay__backdrop + .the-overlay__slot-area {
  display: flex;
  height: 100vh;
  justify-content: center;
  overflow-y: auto;
  width: 100vw;
}
</style>
