import comments from '@/repository/comments'

export default {
  async createComment ({ commit }, { applicantId, body }) {
    const response = await comments.createComment(applicantId, body)
    commit('ADD_COMMENT', response)
  },
  async getComments ({ commit }, applicantId) {
    const response = await comments.getComments(applicantId)
    commit('SET_COMMENTS', response)
  },
  async deleteComment ({ commit }, { applicantId, commentId }) {
    await comments.deleteComment(applicantId, commentId)
    commit('REMOVE_COMMENT', commentId)
  }
}
