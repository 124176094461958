import jobPostingRepo from '@/repository/job-postings'

export default {
  async getJobPostingsSummary ({ commit }) {
    commit('SET_IS_FETCHING_JOB_POSTINGS_SUMMARY', true)
    const jobPosting = await jobPostingRepo.getJobPostingsSummary()
    commit('SET_JOB_POSTINGS_SUMMARY', jobPosting)
    commit('SET_IS_FETCHING_JOB_POSTINGS_SUMMARY', false)
  }
}
