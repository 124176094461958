import { get } from '@/helpers/request'
import { companiesUrl } from '@/helpers/constants/external-urls'
import JSONAPIClient from '@/helpers/jsonapi-client'

const baseUrl = `${companiesUrl}/api/v2`

export default {
  async getAts () {
    const response = await get(`${baseUrl}/companies/ats`)
    return JSONAPIClient.parse(response.data)
  },

  async updateAts (id) {
    const response = await get(`${baseUrl}/companies/ats/${id}/update_openings`)
    return JSONAPIClient.parse(response.data)
  },

  async updateAtsOpening (atsId, openingId) {
    const response = await get(
      `${baseUrl}/companies/ats/${atsId}/ats_openings/${openingId}/update_data`
    )
    return JSONAPIClient.parse(response.data)
  }
}
